import { ThemePaletteKeys } from "store/Theme";
import styled, { keyframes } from "styled-components";
import React from "react";

export type SpinnerSizes = "Large" | "Default" | "Compact" | "Adapt";

const Sizes = new Map<SpinnerSizes, string>([["Large", "90px"], ["Default", "54px"], ["Compact", "21px"], ["Adapt", "100%"]]);

const gridAnimation = keyframes`
	0%, 70%, 100% { transform: scale3D(1, 1, 1); }
	35% { transform: scale3D(0, 0, 1); }
`;

const Wrapper = styled.div<Pick<IProps, "backgroundColor">>`
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	&.fullScreen {
		position: fixed;
		inset: 0;
	}

	&.showBackground {
		background-color: ${props => props.backgroundColor};
	}
`;
const Container = styled.div<Pick<IProps, "size">>`
	width: ${props => Sizes.get(props.size!)};
	height: ${props => Sizes.get(props.size!)};
`;
const Rect = styled.div<Pick<IProps, "type">>`
	width: 33.334%;
	height: 33.334%;
	margin: 0;
	padding: 0;
	float: left;
	animation: ${gridAnimation} 1.3s infinite ease-in-out;
	background-color: ${props => props.color || props.theme[props.type!]};

	// Cube positions
	// 1 2 3
	// 4 5 6
	// 7 8 9
	&:nth-child(7) {
		animation-delay: 0s;
	}
	&:nth-child(8) {
		animation-delay: 0.1s;
	}
	&:nth-child(9) {
		animation-delay: 0.2s;
	}
	&:nth-child(4) {
		animation-delay: 0.1s;
	}
	&:nth-child(5) {
		animation-delay: 0.2s;
	}
	&:nth-child(6) {
		animation-delay: 0.3s;
	}
	&:nth-child(1) {
		animation-delay: 0.2s;
	}
	&:nth-child(2) {
		animation-delay: 0.3s;
	}
	&:nth-child(3) {
		animation-delay: 0.4s;
	}
`;

export const Spinner = ({
	size = "Adapt",
	type = "Gray3",
	color = undefined,
	fullScreen = false,
	showBackground = false,
	backgroundColor = "rgba(0, 0, 0, 0.3)",
	style
}: IProps) => {
	return (
		<Wrapper
			className={(fullScreen ? "fullScreen" : "") + (showBackground ? " showBackground" : "")}
			backgroundColor={backgroundColor}
			style={style}
		>
			<Container size={size}>
				{Array.from({ length: 9 }).map((_, i) => <Rect key={i} type={type} color={color} />)}
			</Container>
		</Wrapper>
	);
};

interface IProps {
	size?: SpinnerSizes;
	type?: ThemePaletteKeys,
	color?: string,
	fullScreen?: boolean,
	showBackground?: boolean,
	backgroundColor?: string,
	style?: React.CSSProperties
}