import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import { BE_API } from "api/axios";
import useAuth from "hooks/useAuth";
import { AxiosError } from "axios";
import {IUserData, LoginData} from "types";

import { Logo } from "components/logo/logo";
import { LoginCard } from "pages/login/loginCard/loginCard";
import {IResponse} from "../../types/api";
import Footer from "../../components/footer/footer";


const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	height: 100%;
	padding-top: 80px;
	
	overflow-y: auto;
	background-color: ${props => props.theme.Background};

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;
const Row = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 1200px;
`;

const Header = styled.div`
    padding-top: 30px;
	color: ${props => props.theme.Gray5};
	font-weight: bold;
`;


const CredentialSignIn = async (payload: LoginData) => {

	const { data, headers: {authorization} } = await BE_API.post<IResponse<IUserData>>("/User/Authenticate", payload);
	return {respData: data, rememberMe: payload.rememberMe, token: authorization};
};

export const Login = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();

	const { auth, setAuth } = useAuth();

	const SignInMutation = useMutation(CredentialSignIn);


	const getError = () => {
		if (SignInMutation.isError) {
			const error = SignInMutation.error as AxiosError;
			return error.response?.status.toString() || "500";
		}

		return null;
	};

	// On Credential SignIn
	useEffect(() => {
		if (!SignInMutation.isSuccess)
			return;

		if ( setAuth) {
			setAuth({succeeded: true, accessToken: SignInMutation.data.token, rememberMe: SignInMutation.data.rememberMe, userData: SignInMutation.data.respData.Data});
			//navigate(location.state as string || "/products");
		}

	}, [SignInMutation.isSuccess]);

	useEffect(() => {
		if(auth?.succeeded) {
			navigate(location.state as string || "/categories");
		}
	}, [auth]);

	const onLogin = (payload: LoginData) => {
		SignInMutation.mutate(payload);
	};

	return (
		<PageWrapper>
			<Row>
				<Logo style={{ height: "120px", width: "unset" }} />
			</Row>
			<Row>
				<Header className="heading caps">Digital Library</Header>
			</Row>
			<Row style={{ flex: "1 0 auto" }}>
				<LoginCard
					onSignIn={onLogin}
					isLoading={SignInMutation.isLoading}
					error={getError()}
				/>
			</Row>
			<Footer/>
		</PageWrapper>
	);
};