import { useEffect, useState } from "react";

export function useDebounce<T> (value: T, delay?: number): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return debouncedValue;
}

/* export function debounce (func: any, delayMs = 300) {
	let timer: any;

	return (...args: any[]) => {
		clearTimeout(timer);
		timer = setTimeout(() => { func.apply(this, args); }, delayMs);
	};
} */

export const debounce = <T extends (...args: any[]) => any> (
	callback: T,
	waitForMs: number
) => {
	let timeout = 0;
	return (...args: Parameters<T>): ReturnType<T> => {
		let result: any;
		clearTimeout(timeout);
		timeout = window.setTimeout(() => result = callback(...args), waitForMs);

		return result;
	};
};