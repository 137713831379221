
export function uniqByReduce<T>(array: T[]): T[] {
    return array.reduce((acc: T[], cur: T) => {
        if (!acc.includes(cur)) {
            acc.push(cur);
        }
        return acc;
    }, []);
}

export function uniqueBy<T>(array: T[], compareFunc: (a: T, b: T) => boolean) {
    return array.filter((value, index) => array.findIndex( elem => compareFunc(value, elem)) === index);
}