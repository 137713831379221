import React, { ChangeEvent } from "react";
import styled, { css, useTheme } from "styled-components";

import { Icon, IconPathsKeysType } from "../icon/icon";
import { ComponentSize } from "../../types";


const getPadding = (size: ComponentSize): string => {

	switch (size) {
		case "Compact": return "5px 12px";
		case "Default": return "8px 12px";
		case "Large": return "15px 10px";
	}
};

const getInputFont = (size: ComponentSize): string => {

	switch (size) {
		case "Compact": return "14px";
		case "Default": return "16px";
		case "Large": return "18px";
	}
};

const getHeight = (size: ComponentSize): string => {

	switch (size) {
		case "Compact": return "";
		case "Default": return "38px";
		case "Large": return "";
	}
};

const Wrapper = styled.div<{ size: ComponentSize, width: string, backgroundColor: string, borderRadius: string }>`
	width: ${props => props.width};
	display: flex;
	align-items: center;
	border-radius: ${props => props.borderRadius};

	height: ${({ size }) => getHeight(size || "Default")};
	padding: ${({ size }) => getPadding(size || "Default")};
	gap: ${({ size }) => (size === "Compact" ? 5 : 10)}px;
	background-color: ${({ backgroundColor }) => backgroundColor};
`;

const TextInput = styled.input<any>`
	flex: 1 1 auto;
	outline: none;
	border: none;
	background-color: ${({ backgroundColor }) => backgroundColor};
	font-family: "Roboto Flex", sans-serif;
	font-size: ${({ size }) => getInputFont(size || "Default")};
	font-weight: ${({ size }) => (size === "Compact" ? 300 : 400)};
	letter-spacing: 0.05em;
	color: ${props =>
		/*props.disabled ? props.theme[props.color] :*/ props.theme.TextDefault};
	::placeholder {
		color: ${({ theme, color }) => theme[color]};
	}
	transition: all 300ms;
	min-width: 0px;
`;

const TextArea = styled.textarea<any>`
	flex: 1 1 auto;
	outline: none;
	border: none;
	background-color: ${({ backgroundColor }) => backgroundColor};
	font-family: "Roboto Flex", sans-serif;
	font-size: ${({ size }) => getInputFont(size || "Default")};
	font-weight: ${({ size }) => (size === "Compact" ? 300 : 400)};
	letter-spacing: 0.05em;
	color: ${props =>
		/*props.disabled ? props.theme[props.color] :*/ props.theme.TextDefault};
	::placeholder {
		color: ${({ theme, color }) => theme[color]};
	}
	transition: all 300ms;
	min-width: 0px;

	min-height: 100%;
	height: 100%;
	resize: none;
`;

const IconWrapper = styled.div<any>`
	flex: 0 0 auto;
	${props =>
	props.onClick
		? css`cursor: pointer;`
		: null}
`;

export const InputField = ({
							   id,
							   autoFocus = false,
							   value,
							   onChange,
							   leftIcon,
							   onLeftIconClick,
							   rightIcon,
							   onRightIconClick,
							   iconSizeOverride,
							   backgroundColor,
							   disabled = false,
							   type,
							   isInvalid = false,
							   placeholder = "",
							   borderRadius = "20px",
							   multiline = false,
							   style,
							   ...props
						   }: InputFieldProps) => {

	const iconSize = iconSizeOverride || (props.size === "Compact" ? 14 : 18);

	const theme = useTheme();
	const mainColor = (({ isValid, isInvalid, disabled }: any) => {
		if (disabled) return "Gray1";
		if (isValid) return "Positive";
		if (isInvalid) return "Negative";
		return "TextLight";
	})(props);

	return (
		<Wrapper
			size={props.size || "Default"}
			width={props.width || ""}
			borderRadius={borderRadius}
			color={theme[mainColor] || undefined}
			backgroundColor={backgroundColor || ""}
			style={style}
		>
			{leftIcon && (
				<IconWrapper onClick={onLeftIconClick}>
					{leftIcon.endsWith(".svg") ?
						<img src={leftIcon}/> :
						<Icon name={leftIcon as IconPathsKeysType} size={iconSize} type={mainColor}/>
					}
				</IconWrapper>
			)}
			{ !multiline ?
				<TextInput
					id={id}
					key={"search"}
					autoFocus={autoFocus}
					size={props.size || ""}
					type={type}
					value={value || ""}
					onChange={(e: ChangeEvent<HTMLInputElement>) => onChange && onChange(e.target?.value)}
					color={mainColor}
					backgroundColor={backgroundColor || ""}
					disabled={disabled}
					placeholder={placeholder}
					isInvalid={isInvalid}
				/>
				:
				<TextArea
					id={id}
					size={props.size || ""}
					type={type}
					value={value || ""}
					onChange={(e: ChangeEvent<HTMLInputElement>) => onChange && onChange(e.target?.value)}
					color={mainColor}
					backgroundColor={backgroundColor || ""}
					disabled={disabled}
					placeholder={placeholder}
					isInvalid={isInvalid}
				/>}
			{rightIcon && (
				<IconWrapper onClick={onRightIconClick}>
					{rightIcon.endsWith(".svg") ?
						<img src={rightIcon} /> :
						<Icon name={rightIcon as IconPathsKeysType} size={iconSize} type={mainColor} />
					}
				</IconWrapper>
			)}
		</Wrapper>
	);
};

interface InputFieldProps {
	id?: string,
	autoFocus?: boolean,
	size: ComponentSize,
	multiline?: boolean,
	width: string,
	value: string;
	onChange?: (value: string) => void,
	leftIcon?: IconPathsKeysType | string;
	onLeftIconClick?: Function,
	rightIcon?: IconPathsKeysType | string;
	onRightIconClick?: Function,
	iconSizeOverride?: string,
	disabled?: boolean,
	backgroundColor?: string,
	type?: string,
	isInvalid?: boolean,
	placeholder?: string,
	borderRadius?: string,
	style?: React.CSSProperties
}

