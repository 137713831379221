
import React, { useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import {boolean} from "mathjs";

const Wrapper = styled.div<{ marginTop: string }>`
	position: sticky;
	@media screen and (max-width: 704px) {
		position: relative;
	}
	
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 0;
	width: 100%;
	background-color: ${props => props.theme.Background1};
	border-top: 1px solid ${props => props.theme.Gray2};
	margin-top: ${props => props.marginTop};
	padding: 10px 20px;
	transition: all 300ms;
	z-index: var(--z-level-1);
	
	user-select: none;
`;
const CollapsedContent = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	
	gap: 10px;
	flex: 1;
	width: 100%;
	max-width: 1280px;
`;
const FooterEntry = styled.div<{selected: boolean}>`
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	color: ${props => props.theme.TextDefault};
	cursor: pointer;
	//letter-spacing: 10;
	
	font-weight:  ${props => props.selected ? "bold" : "normal"};
	
	&:hover {
		 ${props => props.theme.TextDark};
		 font-weight: 700;
		 //letter-spacing: 0;
	}
	
`;

function Footer ({ marginTop = "40px" }: { marginTop?: string }) {

	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const wrapperRef = useRef<HTMLDivElement>(null);

	function isLang(code: string) : boolean {
		return i18n.language === code;
	}

	return (
		<Wrapper marginTop={marginTop} ref={wrapperRef} className="collapse">
			<CollapsedContent className="text-footer caps" >
				<FooterEntry className="text-footer caps" onClick={() => window.location.href = "https://www.sensitivefabrics.it/"} selected={false}>© EUROJERSEY SPA 2023</FooterEntry>|
				<FooterEntry className="text-footer caps" onClick={() => window.location.href = "https://www.sensitivefabrics.it/"} selected={false}>01879880126</FooterEntry>|
				<FooterEntry className="text-footer caps" onClick={() => window.location.href = "https://www.sensitivefabrics.it/company-information/"} selected={false}>COMPANY INFORMATION AND LEGAL NOTES</FooterEntry>|
				<FooterEntry className="text-footer caps" onClick={() => navigate("/privacyPolicy")} selected={false}>{t("PrivacyPolicy")}</FooterEntry>|
				<FooterEntry className="text-footer caps" onClick={() => navigate("/cookiesPolicy")} selected={false}>{t("CookiePolicy")}</FooterEntry>|
				<FooterEntry className="text-footer caps" onClick={() => window.location.href = "https://sense-immaterialreality.com"} selected={false}>{t("Credits")}</FooterEntry>|
				<FooterEntry className="text-footer caps" onClick={() => i18n.changeLanguage("it")} selected={isLang("it")}>IT</FooterEntry>
				<FooterEntry className="text-footer caps" onClick={() => i18n.changeLanguage("en")} selected={isLang("en")}>EN</FooterEntry>
			</CollapsedContent>
		</Wrapper>
	);
}

export default Footer;
