

export async function getBase64(file: File) : Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			resolve((reader.result as string));
		}
		reader.onerror = reject
	})
}

export default getBase64;