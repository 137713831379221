import { useEffect } from "react";
import type { AxiosRequestConfig } from "axios";

import { BE_API } from "../api/axios";
import useAuth from "./useAuth";


/*let currentPromise: Promise<string> | null = null;

const getValidToken = (auth: IAuthData, refreshToken: () => Promise<string>) => {

	if (auth.authType !== "credential" || !auth.tokenExpiration)
		return Promise.reject();

	const missingTime = ((auth?.tokenExpiration?.getTime() - Date.now()) / 1000);
	const isExpiring = missingTime < 1.0;

	if (!isExpiring && auth.accessToken != null)
		return Promise.resolve(auth.accessToken);

	if (currentPromise === null) {
		currentPromise = refreshToken();
		currentPromise.finally(() => currentPromise = null);
	}

	return currentPromise;
};*/

const useAxiosPrivate = (axiosInstance = BE_API) => {
	const { auth } = useAuth();
	//const refreshToken = useRefreshToken();

	useEffect(() => {

		if (!auth?.succeeded)
			return;

		const requestIntercept = axiosInstance.interceptors.request.use(

			async (config: AxiosRequestConfig) => {
				if (!config)
					config = {} as AxiosRequestConfig;

				if (!config.headers)
					config.headers = {};

				//const validToken = await getValidToken(auth, refreshToken);
				config.headers["Authorization"] = `Bearer ${auth.accessToken}`;

				return config;

			}, (error: any) => Promise.reject(error)
		);

		return () => axiosInstance.interceptors.request.eject(requestIntercept);

	}, [auth]);

	return axiosInstance;
};

export default useAxiosPrivate;