import { useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";

import { Logo } from "components/logo/logo";
import Footer from "../../components/footer/footer";
import { InputField } from "../../components/inputField/inputField";
import { Button } from "../../components/button/button";
import { emailValidation } from "../../utils/stringUtils";
import { IResponse } from "types/api";
import { BE_API } from "api";


const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	height: 100%;
	padding-top: 80px;
	
	overflow-y: auto;
	background-color: ${props => props.theme.Background};

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;
const RowParent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 1200px;
`;

const Message = styled.div`
    padding-top: 0px;
    text-align: center;
	color: ${props => props.theme.TextDefault};
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 70px 30px 30px 30px;
	gap: 15px;
    
    width: 700px;
	@media screen and (max-width: 704px) {
		width: 100%;
	}
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
    
    flex-direction: row;
    gap: 20px;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 320px;
	max-width: 320px;
	gap: 5px;
`;

const ErrorMessageContextual = styled(Row)`
	justify-content: left;
	color: ${props => props.theme.Negative};
	height: 14px;
	padding-left: 22px;
    padding-bottom: 0px;
`;

const Label = styled.label`
	display: flex;
	flex-direction: row;
	padding-left: 22px;
	color: ${props => props.theme.TextDefault};
`;

const RequestAccessLabel = styled.label`
	color: ${props => props.theme.TextLight};
	cursor: pointer;
	text-decoration: underline;
	
	transition: color easeout 300ms;
	&:hover {
		color: ${props => props.theme.TextDark};
	}
`;

export default function ForgotPassword() : JSX.Element
{
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>("");

    const [bSubmitTry, setSubmitTry] = useState<boolean>(false);

    const [showQueryErrorMessage, setShowQueryErrorMessage] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

    const { mutate, isLoading, error } = useMutation<AxiosResponse<IResponse<void>>, AxiosError<void>, void>(
        _ => BE_API.get<IResponse<void>>(`/User/ResetPassword/${email}`),
        {
            onSuccess(data: AxiosResponse<IResponse<void>>)
            {
                setShowQueryErrorMessage(false);
                setShowSuccessMessage(true);
            },
            onError(err: AxiosError<void>)
            {
                setShowQueryErrorMessage(true);
            },
            // After success or failure,
            onSettled(data: AxiosResponse<IResponse<void>> | undefined, error: AxiosError<void> | null)
            {
                
            }
        }
    );

    const handleChangeRequest = () =>
    {
        setSubmitTry(true);
        if (canSubmit())
        {
            mutate();
        }
    };

    const canSubmit = () => email?.length && emailValidation(email);

    const handleOnChange = (field: "email" , value: string) =>
    {
        field === "email" && setEmail(value);
        setShowQueryErrorMessage(false);
        setSubmitTry(false);
    };

    return (
        <PageWrapper>
            <RowParent>
                <Logo style={{ height: "120px", width: "unset", cursor: "pointer" }} onClick={() => navigate("/")} />
            </RowParent>
            <RowParent style={{ flex: "1 0 auto" }}>
            {
                showSuccessMessage ?
                <Wrapper>
                        <Message className="heading-s">{t("ForgotEmailRequestSuccess")}</Message>
                        <Row style={{ marginTop: "70px", width: "200px" }}>
                            <Button
                                label={t("BackToHome")}
                                backgroundColor={"TextDark"}
                                color={"Gray0"}
                                hoverDark={false}
                                onClick={_ => navigate('/login')}
                                //isLoading={}
                                isDisabled={false}
                                shouldFill
                                type="submit"
                            />
                        </Row>
                    </Wrapper>
                :
                <Wrapper>
                    <Message className="heading-s">{t("ForgotEmailLabel")}</Message>
                    <Column style={{paddingTop:'25px'}}>
                        <Label className="heading-s">{t("Email")}</Label>
                        <InputField id="username"
                            size="Default"
                            width="100%"
                            backgroundColor={theme.Gray1}
                            value={email}
                            onChange={value => handleOnChange("email", value)}
                        />
                        <ErrorMessageContextual
                            className="ptext">{bSubmitTry && !emailValidation(email) && t("MailError")}
                        </ErrorMessageContextual>
                    </Column>

                    {/* REQUEST ERROR SLOT */}
                    <ErrorMessageContextual className="ptext">{bSubmitTry && showQueryErrorMessage && t("ServerError")}</ErrorMessageContextual>
                    
                    <Row style={{ marginTop: "0px", width: "200px" }}>
                        <Button
                            label={t("ConfirmLabel")}
                            backgroundColor={"TextDark"}
                            color={"Gray0"}
                            hoverDark={false}
                            onClick={handleChangeRequest}
                            //isLoading={}
                            isDisabled={false}
                            shouldFill
                            type="submit"
                        />
                    </Row>
                    <RequestAccessLabel onClick={() => navigate("/")}>{t("Cancel")}</RequestAccessLabel>
                </Wrapper>
            }
            </RowParent>
            <Footer />
        </PageWrapper>
    );
};