import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";


const useLogout = () => {

	const { auth, setAuth } = useAuth();
	const navigate = useNavigate();

	const logout = /*async*/ (): /*Promise<*/boolean/*>*/ => {
		try {
			//TODO investigate if there's a revoke token call
			/*if (auth?.authType === "credential") {
				await IAM_API.post("/tokens/revoke", null, {
					withCredentials: true
				});
			}*/

			localStorage.setItem("authData", "{}");
			setAuth?.({ succeeded: false, rememberMe: false });
			navigate("/");

			return true;
		}
		catch (e) { return false; }
	};

	return logout;
};

export default useLogout;