import React from "react";
import styled, { keyframes, useTheme } from "styled-components";

const Wrapper = styled.div`
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	padding: 20px;
	gap: 10px;
	transition: all 0.33s cubic-bezier(0.165, 0.84, 0.44, 1);

	min-height: 256px;
	height: 256px;
	max-height: 256px;

	min-width: 315.5px;
	width: 315.5px;
	max-width: 315.5px;
`;

const loading = keyframes`
	0%{
        background-position: 1000px 0
    }
    100%{
        background-position: -1000px 0
    }
`;

const AnimatedBg = styled.div`
	animation-duration: 2.25s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: ${loading};
	animation-timing-function: linear;
	background: darkgray;
	background: linear-gradient(
		70deg,
		rgba(255, 255, 255, 0) 20%,
		rgba(255, 255, 255, 0.5) 36%,
		rgba(255, 255, 255, 0) 52%
	);
	background-size: 1000px;
	height: 100%;
	width: 100%;
	position: relative;
`;

const ProductImage = styled.div`
	position: relative;
	min-height: 200px;
	height: 200px;
	max-height: 200px;
	background-color: ${props => props.theme.Gray1};
`;

const ProductInformation = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const NameLabel = styled.div`
	height: 18px;
	width: 70%;
	background-color: ${props => props.theme.Gray1};
`;

export const ProductCardPlaceholder = () => {

	return (
		<Wrapper>
			<ProductImage>
				<AnimatedBg />
			</ProductImage>
			<ProductInformation>
				<NameLabel>
					<AnimatedBg />
				</NameLabel>
			</ProductInformation>
		</Wrapper>
	);
};
