
import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";

import { useTranslation } from "react-i18next";
import { Picture } from "../../../components/picture/picture";
import chroma from "chroma-js";
import {TypeUrl, ICategory} from "../../../types/categories";
import {removeOrderPrefix} from "../../../utils/stringUtils";

const Wrapper = styled.div`
	position: relative;
	//padding: 4px;

	min-height: 276px;
	height: 276px;
	max-height: 276px;

	min-width: 420px;
	width: 420px;
	max-width: 420px;

	@media screen and (max-width: 460px) {
		min-width: 95vw;
		width: 95vw;
		max-width: 95vw;
	}
	
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	gap: 10px;
	transition: all 0.33s cubic-bezier(0.165, 0.84, 0.44, 1);
	
	//border-radius: 8px;
	
	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius:4px;
		box-shadow: rgba(50, 50, 50, 0.2) 0px 6px 16px -2px,
			rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
		opacity: 0;
		transition: all 0.33s cubic-bezier(0.165, 0.84, 0.44, 1);
	}

	&:hover  {
		transform: translate(0, -4px);
	}

	&:hover::after {
		opacity: 1;
	}

	cursor: pointer;
`;

const ImageWrapper = styled.div`
	min-height: 276px;
	height: 276px;
	max-height: 276px;
	
	cursor: pointer;
`;

const ProductDesc = styled.div`
	position: absolute;
	bottom: 10px;
	left: 10px;
	
	text-transform: uppercase;
`;

const CategoryName = styled.p`
	color: ${props => props.theme.Gray0};

	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.60);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
`;


export const CategoryCard = ({category, onCardClick, onDownloadClick }: IProps) => {
	const { t } = useTranslation();

	useEffect(() => {
		if (category.ImageUrl) {
			const img = new Image();

			img.src = category.ImageUrl;
		}
	}, [category.ImageUrl]);

	const HandleCardClick = (_e: React.MouseEvent<HTMLDivElement>) => {
		onCardClick && onCardClick();
	};

	return (
		<Wrapper id={category.IdReference} onClick={HandleCardClick}>
			<ImageWrapper >
			<Picture src={category.ImageSmallUrl ?? category.ImageUrl} />
			</ImageWrapper>
			<ProductDesc>
				<CategoryName>{removeOrderPrefix(category.Name)}</CategoryName>
			</ProductDesc>
		</Wrapper>
	);
};

interface IProps {
	category: ICategory;
	onCardClick?: () => void,
	onDownloadClick?: (productId: string) => void,
};
