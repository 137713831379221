import React from "react";
import Picture from "../picture/picture";

const Logo_IMAGE_URL = process.env.PUBLIC_URL + "/Logo_BW_512.png";

export const Logo = ({ style, onClick }: IProps) => {

	return (
		<Picture src={Logo_IMAGE_URL} useSpinner={false} usePlaceholder={false} style={style} onClick={onClick}/>
	);
};

interface IProps {
	style?: React.CSSProperties
	onClick?: () => void;
}
