import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";

import { IPasswordChangeBody } from "types";
import useAxiosPrivate from "hooks/use_BE_API_Private";

import { Logo } from "components/logo/logo";
import Footer from "../../components/footer/footer";
import { InputField } from "../../components/inputField/inputField";
import { Button } from "../../components/button/button";
import { passwordValidation } from "../../utils/stringUtils";
import { IResponse } from "types/api";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";


const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	height: 100%;
	padding-top: 80px;
	
	overflow-y: auto;
	background-color: ${props => props.theme.Background};

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;
const RowParent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 1200px;
`;

const WrapperMessage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 60px 30px 30px 30px;
	gap:0px;
`;

const WrapperMessageContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: left;
	padding: 40px 30px 30px 30px;
	gap:0px;
`;

const Message = styled.div`
    padding-top: 0px;
    white-space: pre-line;
    text-align: center;
	color: ${props => props.theme.TextDefault};
`;

const MessageLeft = styled.div`
    padding-top: 0px;
    text-align: left;
    width: 100%;
    max-width: 100%;
	color: ${props => props.theme.TextDefault};
`;

const MessageRow = styled.div`
    display: flex;
	flex-direction: row;
`;

const CustomAnchor = styled.span`
    color : #678C2B;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 30px 30px 30px;
	gap: 15px;
    
    width: 700px;
	@media screen and (max-width: 704px) {
		width: 100%;
	}
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
    
    flex-direction: row;
    gap: 20px;
`;


const CenterRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
    height: 21px;
    
    gap: 20px;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 320px;
	max-width: 320px;
	gap: 5px;
`;

const ErrorMessageContextual = styled(Row)`
	justify-content: left;
	color: ${props => props.theme.Negative};
	height: 14px;
	padding-left: 22px;
`;

const Label = styled.label`
	display: flex;
	flex-direction: row;
	padding-left: 22px;
	padding-top: 10px;
	color: ${props => props.theme.TextDefault};
`;

const RequestAccessLabel = styled.label`
	color: ${props => props.theme.TextLight};
	cursor: pointer;
	text-decoration: underline;
	
	transition: color easeout 300ms;
	&:hover {
		color: ${props => props.theme.TextDark};
	}
`;

export default function ChangePassword() : JSX.Element
{
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const Private_BE_API = useAxiosPrivate();

    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");

    const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [bSubmitTry, setSubmitTry] = useState<boolean>(false);

    const [showQueryErrorMessage, setShowQueryErrorMessage] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);


    const { mutate, isLoading, error } = useMutation<AxiosResponse<IResponse<void>>, AxiosError<void>, IPasswordChangeBody>(
        payload => Private_BE_API.post<IResponse<void>>("/User/ChangePassword", payload),
        {
            onSuccess(data: AxiosResponse<IResponse<void>>, variables: IPasswordChangeBody)
            {
                setShowQueryErrorMessage(false);
                setShowSuccessMessage(true);
            },
            onError(err: AxiosError<void>, variables: IPasswordChangeBody)
            {
                setShowQueryErrorMessage(true);
            },
            // After success or failure,
            onSettled(data: AxiosResponse<IResponse<void>> | undefined, error: AxiosError<void> | null, variables: IPasswordChangeBody)
            {
                
            }
        }
    );

    const handleChangeRequest = () => {
        setSubmitTry(true);
        if (canSubmit()) {
            const payload: IPasswordChangeBody = {
                OldPassword: oldPassword,
                NewPassword: newPassword
            };
            mutate(payload);
        }
    };

    const canSubmit = () => oldPassword?.length && newPassword?.length && newPasswordConfirm?.length && oldPassword !== newPassword && passwordValidation(newPassword) && newPassword === newPasswordConfirm;

    const handleOnChange = (field: "oldPsw" | "newPsw" | "newPpswCfrm" , value: string) => {
        field === "oldPsw" && setOldPassword(value);
        field === "newPsw" && setNewPassword(value);
        field === "newPpswCfrm" && setNewPasswordConfirm(value);
        setShowQueryErrorMessage(false);
        setSubmitTry(false);
    };

    return (
        <PageWrapper>
            <RowParent>
                <Logo style={{ height: "120px", width: "unset", cursor: "pointer" }} onClick={() => navigate("/")} />
            </RowParent>
            <RowParent style={{ flex: "1 0 auto" }}>
                {
                    showSuccessMessage &&
                    <WrapperMessage>
                        <Message className="heading-s">{t("PasswordChangeRequestSuccess")}</Message>
                        <Row style={{ marginTop: "70px", width: "200px" }}>
                            <Button
                                label={t("BackToHome")}
                                backgroundColor={"TextDark"}
                                color={"Gray0"}
                                hoverDark={false}
                                onClick={_ => navigate('/login')}
                                //isLoading={}
                                isDisabled={false}
                                shouldFill
                                type="submit"
                            />
                        </Row>
                    </WrapperMessage>
                }
                {
                    !showSuccessMessage &&
                    <Wrapper>
                        <Message className="heading-s">{t("ChangePasswordLabel")}</Message>
                        <Column style={{paddingTop:'25px'}}>

                            {/* OLD PASSWORD */}
                            <Label className="heading-s">{t("OldPasswordLabel")}</Label>
                            <InputField
                                type={showOldPassword ? "text" : "password"}
                                size="Default"
                                width="100%"
                                backgroundColor={theme.Gray1}
                                value={oldPassword}
                                rightIcon={!showOldPassword ? "/assets/view.svg" : "/assets/view-off.svg"}
                                iconSizeOverride={"32px"}
                                onRightIconClick={() => setShowOldPassword(!showOldPassword)}
                                onChange={value => handleOnChange("oldPsw", value)}
                                isInvalid={!!oldPassword.length && !!showQueryErrorMessage}
                            />
                            <ErrorMessageContextual className="ptext">
                                {bSubmitTry && oldPassword.length === 0 && t('PasswordChange_OldPasswordLength')}
                            </ErrorMessageContextual>

                            {/* NEW PASSWORD */}
                            <Label className="heading-s" style={{paddingTop:'30px'}}>{t("NewPasswordLabel")}</Label>
                            <InputField
                                type={showNewPassword ? "text" : "password"}
                                size="Default"
                                width="100%"
                                backgroundColor={theme.Gray1}
                                value={newPassword}
                                rightIcon={!showNewPassword ? "/assets/view.svg" : "/assets/view-off.svg"}
                                iconSizeOverride={"32px"}
                                onRightIconClick={() => setShowNewPassword(!showNewPassword)}
                                onChange={value => handleOnChange("newPsw", value)}
                                isInvalid={!!newPassword.length && !!showQueryErrorMessage}
                            />
                            <ErrorMessageContextual className="ptext">
                                {bSubmitTry && (oldPassword?.length > 0 ?? false) && oldPassword === newPassword && t("PasswordChange_NotEqualityError")}
                                {bSubmitTry && (newPassword.length === 0 || (oldPassword !== newPassword && !passwordValidation(newPassword))) && t("PasswordError")}
                            </ErrorMessageContextual>

                            {/* NEW PASSWORD CONFIRM */}
                            <Label className="heading-s">{t("NewPasswordConfirmLabel")}</Label>
                            <InputField
                                type={showNewPassword ? "text" : "password"}
                                size="Default"
                                width="100%"
                                backgroundColor={theme.Gray1}
                                value={newPasswordConfirm}
                                rightIcon={!showNewPassword ? "/assets/view.svg" : "/assets/view-off.svg"}
                                iconSizeOverride={"32px"}
                                onRightIconClick={() => setShowNewPassword(!showNewPassword)}
                                onChange={value => handleOnChange("newPpswCfrm", value)}
                                isInvalid={!!newPasswordConfirm.length && !!showQueryErrorMessage}
                            />
                            <ErrorMessageContextual className="ptext">
                                {bSubmitTry && (!newPassword.length || (newPassword !== newPasswordConfirm)) && t("PasswordChange_EqualityError")}
                            </ErrorMessageContextual>
                            
                            {/* REQUEST ERROR SLOT */}
                            <ErrorMessageContextual className="ptext">
                                {bSubmitTry && showQueryErrorMessage && t("ServerError")}
                            </ErrorMessageContextual>
                        </Column>
                        
                        {/* EXIT BUTTON */}
                        <Row style={{ marginTop: "0px", width: "200px" }}>
                            <Button
                                label={t("ConfirmLabel")}
                                backgroundColor={"TextDark"}
                                color={"Gray0"}
                                hoverDark={false}
                                onClick={handleChangeRequest}
                                //isLoading={}
                                isDisabled={false}
                                shouldFill
                                type="submit"
                            />
                        </Row>
                        <RequestAccessLabel onClick={() => navigate("/")}>{t("Cancel")}</RequestAccessLabel>
                    </Wrapper>
                }
            </RowParent>
            <Footer />
        </PageWrapper>
    );
};