import React from "react";
import PropTypes from "prop-types";
import { DefaultTheme, useTheme } from "styled-components";
import { ThemePaletteArrayKey, ThemePaletteKeys } from "../../store/Theme";

const IconPaths = {
	home: [
		"M2.826 9.167v4.616c0 .336.273.608.609.608h2.739a.304.304 0 0 0 .304-.304v-2.435a1.522 1.522 0 1 1 3.044 0v2.435c0 .168.136.304.304.304h2.74a.609.609 0 0 0 .608-.608V9.167M1 8l7-7 7 7",
	],
	checkmark: ["m2 7.857 3.857 4.714L14 4"],
	cross: ["m2 2 12 12M2 14 14 2"],
	lens: [
		"M9.962 9.962a5.25 5.25 0 1 0-7.424-7.424 5.25 5.25 0 0 0 7.424 7.424Z",
		"m10 10 5 5",
	],
	menu: ["M15 4H1M15 7.889H1M15 11.778H1"],
	like: [
		"M8.002 14.249 2.037 8.025a3.527 3.527 0 0 1-.665-4.074v0a3.53 3.53 0 0 1 5.653-.917L8 4.007l.975-.973a3.53 3.53 0 0 1 5.653.917v0c.68 1.358.414 3-.66 4.074L8.001 14.25Z",
	],
	more: [
		"M2.522 10.043a1.522 1.522 0 1 0 0-3.043 1.522 1.522 0 0 0 0 3.043ZM8 10.043A1.522 1.522 0 1 0 8 7a1.522 1.522 0 0 0 0 3.043ZM13.478 10.043a1.522 1.522 0 1 0 0-3.043 1.522 1.522 0 0 0 0 3.043Z",
	],
	back: [
		"M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Z",
		"M11.818 8H3.545M6.409 10.864 3.545 8 6.41 5.136",
	],
	forward: [
		"M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1Z",
		"M4.182 8h8.273M9.59 5.136 12.456 8 9.59 10.864",
	],
	upArrow: [
		"M2.742 8.435h2.79V15h4.924V8.435h2.805a.482.482 0 0 0 .374-.786l-5.26-6.476a.492.492 0 0 0-.75 0L2.365 7.65a.483.483 0 0 0 .377.786v0Z",
	],
	downArrow: [
		"M13.258 7.565h-2.79V1H5.544v6.565H2.74a.482.482 0 0 0-.375.786l5.26 6.476a.493.493 0 0 0 .75 0l5.26-6.476a.483.483 0 0 0-.377-.786v0Z",
	],
	loading: [
		"M12.723 7.114a1.773 1.773 0 1 0 0-3.546 1.773 1.773 0 0 0 0 3.546ZM8.587 4.455a1.477 1.477 0 1 0 0-2.955 1.477 1.477 0 0 0 0 2.955ZM4.45 5.636a1.477 1.477 0 1 0 0-2.954 1.477 1.477 0 0 0 0 2.954ZM2.382 8.886a1.182 1.182 0 1 0 0-2.363 1.182 1.182 0 0 0 0 2.363ZM3.268 12.432a1.182 1.182 0 1 0 0-2.364 1.182 1.182 0 0 0 0 2.364ZM6.223 14.5a.886.886 0 1 0 0-1.773.886.886 0 0 0 0 1.773ZM9.399 14.29a.59.59 0 1 0 0-1.18.59.59 0 0 0 0 1.18Z",
	],
	exit: [
		"M15 8H7.696M12.565 10.435 15 8l-2.435-2.435M6.479 2.217h4.565c.168 0 .304.137.304.305v1.521M11.348 11.957v1.521a.304.304 0 0 1-.304.305H6.479",
		"M1 13.614c0 .143.1.266.239.297l4.87 1.082a.305.305 0 0 0 .37-.297V1.304a.304.304 0 0 0-.37-.297L1.238 2.09A.304.304 0 0 0 1 2.387v11.227Z",
		"M4.348 8.913a.913.913 0 1 0 0-1.826.913.913 0 0 0 0 1.826Z",
	],
	leftArrow: ["M10.4 15 5 8l5.4-7"],
	rightArrow: ["m5 1 5.4 7L5 15"],
	fullscreen: ["M4.65234 5.26087C4.65234 5.09944 4.71647 4.94461 4.83063 4.83046C4.94478 4.71631 5.0996 4.65218 5.26104 4.65218H10.7393C10.9007 4.65218 11.0556 4.71631 11.1697 4.83046C11.2839 4.94461 11.348 5.09944 11.348 5.26087V10.7391C11.348 10.9006 11.2839 11.0554 11.1697 11.1695C11.0556 11.2837 10.9007 11.3478 10.7393 11.3478H5.26104C5.0996 11.3478 4.94478 11.2837 4.83063 11.1695C4.71647 11.0554 4.65234 10.9006 4.65234 10.7391V5.26087Z",
		"M12.5652 3.43478L15 1M1 15L3.43478 12.5652M4.04348 15H1V11.9565M15 4.04348V1H11.9565M12.5652 12.564L15 15M1 1L3.43478 3.43478M1 4.04348V1H4.04348M11.9565 15H15V11.9565"],
	focus: ["M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Z", "M12.8 8S11 5.3 8 5.3C5 5.3 3.2 8 3.2 8S5 10.7 8 10.7c3 0 4.8-2.7 4.8-2.7Z", "M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z", "M8 7.8a.2.2 0 1 0 0 .4.2.2 0 0 0 0-.4v0"],
	followCam: ["M4 3c0-.8.6-1.5 1.4-1.5h3.3c.8 0 1.5.7 1.5 1.5v2.3c0 .8-.7 1.5-1.5 1.5H5.4C4.6 6.8 4 6 4 5.3V3Z", "M14.5 6a.5.5 0 0 1-.7.4l-1.9-1a.5.5 0 0 1-.3-.4V3.2c0-.2.1-.4.3-.5l2-1a.5.5 0 0 1 .6.5V6ZM1.5 12.1c0-.5.4-1 1-1h2.3c.6 0 1 .5 1 1V14c0 .6-.4 1-1 1H2.5a1 1 0 0 1-1-1v-1.9ZM4.9 10.4a1.2 1.2 0 0 0-2.4 0v.7h2.4v-.7Z", "m11.9 9.3-.6-.6-.6.6", "M11.3 8.7V9c0 2.3-1.8 4.2-4 4.3", "M3.7 12.5v1"],
	guideCam: ["M3.5 9.2h3.2c.9 0 1.5.7 1.5 1.5V13c0 .8-.6 1.5-1.5 1.5H3.5c-.8 0-1.5-.7-1.5-1.5v-2.3c0-.8.7-1.5 1.5-1.5Z", "M12.6 13.7a.5.5 0 0 1-.7.4l-2-1a.5.5 0 0 1-.2-.4V11c0-.2.1-.4.3-.4l1.9-1a.5.5 0 0 1 .7.4v3.8ZM10.2 4c0-.6.4-1 1-1h2.3c.6 0 1 .4 1 1v1.8c0 .5-.4 1-1 1h-2.3a1 1 0 0 1-1-1V3.9ZM13.6 2.2a1.2 1.2 0 0 0-2.4 0V3h2.4v-.7Z", "m4 7.4.5.6.6-.6", "M4.5 8v-.3c0-2.3 1.8-4.1 4-4.3", "M12.4 4.5v1"],
	freeCam: ["M4.3 6H12c.6 0 1 .5 1 1v3.6M3.8 10v3.5c0 .5.4 1 1 1h7M1 6l14 8.5", "M11.1 4.4c0-1.6-1.2-2.9-2.6-2.9-1.5 0-2.7 1.3-2.7 2.9V6h5.3V4.4Z"],
	alignToOther: ["M.5 10.3c0-.9.7-1.5 1.5-1.5h3.8c.8 0 1.5.6 1.5 1.5V13c0 .8-.7 1.5-1.5 1.5H2c-.8 0-1.5-.7-1.5-1.5v-2.7Z", "M12 13.6a.5.5 0 0 1-.8.4L9 13a.5.5 0 0 1-.3-.5v-2l.3-.4 2-1a.5.5 0 0 1 .8.5v4Z", "M8.8 7.8v-.6c0-1 .7-1.7 1.5-1.9-.5-.4-.9-1-.9-1.8C9.4 2 10.6 1 12 1c1.4 0 2.5 1.1 2.5 2.5 0 .7-.3 1.4-.9 1.8.9.2 1.5 1 1.5 1.9v3.7", "M2.6 7.2c0-3 3.4-3.6 5-3.3", "m5.7 2.6 2 1.3-2 1.2"],
	alignOtherToMe: ["M.5 10.3c0-.9.7-1.5 1.5-1.5h3.8c.8 0 1.5.6 1.5 1.5V13c0 .8-.7 1.5-1.5 1.5H2c-.8 0-1.5-.7-1.5-1.5v-2.7Z", "M12 13.6a.5.5 0 0 1-.8.4L9 13a.5.5 0 0 1-.3-.5v-2l.3-.4 2-1a.5.5 0 0 1 .8.5v4Z", "M8.8 7.8v-.6c0-1 .7-1.7 1.5-1.9-.5-.4-.9-1-.9-1.8C9.4 2 10.6 1 12 1c1.4 0 2.5 1.1 2.5 2.5 0 .7-.3 1.4-.9 1.8.9.2 1.5 1 1.5 1.9v3.7", "M2.9 6.2c0-3.6 3-3.6 4.6-3.3", "M4.4 5 2.6 6.7l-.8-2.3"],
	other1: ["M11.3 15a3.7 3.7 0 1 0 0-7.3 3.7 3.7 0 0 0 0 7.3Z", "M11.3 9.5v3.7M11.3 13.2 10 11.8M11.3 13.2l1.4-1.4M5.9 11.3H1a4.3 4.3 0 0 1 6.6-3.5M3 2.3a4.3 4.3 0 0 0 4.8 1", "M5.3 6.2a2.6 2.6 0 1 0 0-5.2 2.6 2.6 0 0 0 0 5.2Z"],
	other2: ["M11.3 15a3.7 3.7 0 1 0 0-7.3 3.7 3.7 0 0 0 0 7.3Z", "M11.3 13.2V9.5M11.3 9.5 10 11M11.3 9.5l1.4 1.4M5.9 11.3H1a4.3 4.3 0 0 1 6.6-3.5M3 2.3a4.3 4.3 0 0 0 4.8 1", "M5.3 6.2a2.6 2.6 0 1 0 0-5.2 2.6 2.6 0 0 0 0 5.2Z"],
	copy: ["M11.2308 3.84615V2.92308C11.2308 2.41328 10.8175 2 10.3077 2H2.92308C2.41328 2 2 2.41328 2 2.92308V10.3077C2 10.8175 2.41328 11.2308 2.92308 11.2308H3.84615M5.69231 14H13.0769C13.5867 14 14 13.5867 14 13.0769V5.69231C14 5.18251 13.5867 4.76923 13.0769 4.76923H5.69231C5.18251 4.76923 4.76923 5.18251 4.76923 5.69231V13.0769C4.76923 13.5867 5.18251 14 5.69231 14Z"],
	camera: ["M8 8.5V14M4.9 13.9 8 11M11.1 13.9 8 11M6.4 8.2h3.2", "M11.1 6.7c0 .2-.2.5-.5.5H5.4a.5.5 0 0 1-.5-.5V3.6c0-.3.2-.6.5-.6h2c.2-.3.3-.5.7-.5h1.1c.4 0 .5.2.6.5h.8c.3 0 .5.3.5.6v3Z", "M8.5 6.1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z", "M5.7 2h.5"],
	qr: ["M.8 12.8v1.5c0 .4.3.7.7.7h1.4M3 1H1.4c-.4 0-.8.3-.8.7v1.5M15.3 3.2V1.7c0-.4-.4-.7-.8-.7h-1.4M13 15h1.5c.4 0 .8-.3.8-.7v-1.5", "M3.2 3.2h2.2v2.2H3.2V3.2ZM10.6 3.2h2.2v2.2h-2.2V3.2ZM3.2 10.6h2.2v2.2H3.2v-2.2Z", "M10.6 10.6v2.2h2.2v-2.2h-.7M7 5V3.3H9M9.1 4.7v2.6M3.2 9.1H7V7.3M7 10.6v2.2H9M8.4 10.6H9M3.2 6.9h2.2M12.8 6.9h-2.2v.7M8.4 9.1h4.4"],
	"3dFiles": ["M14.4794 7.26087V5.15861C14.4799 4.05587 14.0422 2.99811 13.2627 2.21815C12.4831 1.4382 11.4256 1 10.3228 1H1.95762C1.57342 1 1.26196 1.31145 1.26196 1.69565V16.3043C1.26196 16.6885 1.57342 17 1.95762 17H13.7837C14.1679 17 14.4794 16.6885 14.4794 16.3043V15.6087", "M11.0012 1.05841V3.78258C11.0012 4.16678 11.3126 4.47824 11.6968 4.47824H14.4238", "M2.06 0.314458C2.62667 0.314458 3.06 0.451124 3.36 0.724458C3.66 0.997791 3.81 1.38112 3.81 1.87446V1.97446C3.81 2.28112 3.74333 2.55112 3.61 2.78446C3.48333 3.01112 3.29333 3.18446 3.04 3.30446C3.31333 3.41779 3.53 3.60112 3.69 3.85446C3.85667 4.10779 3.94 4.39446 3.94 4.71446V4.81446C3.94 5.34112 3.78 5.76446 3.46 6.08446C3.14667 6.40446 2.69 6.56446 2.09 6.56446C1.77667 6.56446 1.34667 6.52112 0.8 6.43446V5.72446C1.30667 5.81112 1.73667 5.85446 2.09 5.85446C2.41 5.85446 2.65667 5.76779 2.83 5.59446C3.00333 5.41446 3.09 5.17112 3.09 4.86446V4.70446C3.09 4.02446 2.74 3.68446 2.04 3.68446H1.15V3.00446H2.04C2.35333 3.00446 2.58333 2.92779 2.73 2.77446C2.88333 2.61446 2.96 2.36112 2.96 2.01446V1.82446C2.96 1.28446 2.66 1.01446 2.06 1.01446C1.76667 1.01446 1.34667 1.06112 0.8 1.15446V0.444458C1.34667 0.357791 1.76667 0.314458 2.06 0.314458ZM6.70872 0.441458C7.42272 0.441458 7.97172 0.645458 8.35572 1.05346C8.74572 1.45546 8.94072 2.03746 8.94072 2.79946V4.27546C8.94072 5.00746 8.74572 5.57146 8.35572 5.96746C7.97172 6.35746 7.40772 6.55246 6.66372 6.55246C6.15372 6.55246 5.61972 6.53446 5.06172 6.49846V0.477458C5.60172 0.453458 6.15072 0.441458 6.70872 0.441458ZM6.66372 1.08946C6.47772 1.08946 6.19872 1.09546 5.82672 1.10746V5.84146C6.18072 5.87746 6.45972 5.89546 6.66372 5.89546C7.12572 5.89546 7.49172 5.76346 7.76172 5.49946C8.03772 5.22946 8.17572 4.84246 8.17572 4.33846V2.74546C8.17572 2.19946 8.04372 1.78846 7.77972 1.51246C7.51572 1.23046 7.14372 1.08946 6.66372 1.08946Z"],
};


export const IconPathsKeyArray = Object.keys(IconPaths);
export type IconPathsKeysType = keyof typeof IconPaths;

const IconPropsTypes = {
	name: PropTypes.oneOf(Object.keys(IconPaths)),
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	strokeWidth: PropTypes.number,
	colorOverride: PropTypes.string,
	type: PropTypes.oneOf(ThemePaletteArrayKey),
	fill: PropTypes.string,
	style: PropTypes.object,
	outerStyle: PropTypes.object,
	onClick: PropTypes.func,
};

interface IconProps extends PropTypes.InferProps<typeof IconPropsTypes> {
	name: IconPathsKeysType;
	type?: ThemePaletteKeys;

	[key: string]: any;
}

export const Icon = ({
	name,
	size,
	strokeWidth,
	type = "Primary",
	colorOverride,
	fill,
	outerStyle,
	style,
	onClick,
	enabled = true
}: IconProps) => {
	const theme: DefaultTheme = useTheme();

	return (
		<svg
			width={size || undefined}
			height={size || undefined}
			viewBox="0 0 16 16"
			fill={fill || undefined}
			role="icon"
			onClick={() => onClick?.()}
			style={{ cursor: onClick ? "pointer" : undefined, ...outerStyle }}
			className={enabled ? "" : "disabled"}
		>
			{IconPaths[name].map((d, i) => (
				<path
					key={name + i}
					d={d}
					preserveAspectRatio="xMidYMid meet"
					vectorEffect="non-scaling-stroke"
					stroke={colorOverride || theme[type]}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={strokeWidth || undefined}
					style={style || undefined}
				/>
			))}
		</svg>
	);
};

Icon.propTypes = IconPropsTypes;

Icon.defaultProps = {
	name: "home",
	size: 16,
	strokeWidth: 1,
	colorOverride: null,
	type: "TextLight",
	fill: "none",
	outerStyle: undefined,
	style: undefined,
	onClick: undefined,
	enabled: true
};
