import React, {
	useEffect,
	useMemo,
	useState,
} from "react";
import styled from "styled-components";
import { useQuery} from "react-query";
import { useTranslation } from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

import { useDebounce } from "utils/debounce";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/use_BE_API_Private";

import { Header } from "components/header/header";

import Footer from "components/footer/footer";

import {IResponse} from "../../types/api";
import {ICategory, IGetCategoriesRequest} from "../../types/categories";
import FullscreenDownload from "../../components/fullscreenDownload/fullscreenDownload";
import useLogout from "../../hooks/useLogout";
import {CategoryCard} from "./categoryCard/categoryCard";
import {CategoryCardPlaceholder} from "./categoryCard/categoryCardPlaceholder";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	height: 100%;
	overflow-y: auto;

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;

const ProductList = styled.div<{numChild: number}>`
	flex: 1 0 auto;
	display: grid;
	gap: 22px;
	//width: 100%;
	//min-width: 320px;
	max-width: 1379px;
	padding: 30px 0px;

	grid-template-columns: repeat(3, minmax(307.5px, 1fr));
	max-height: ${props => {
		const rows = Math.ceil(props.numChild / 3);
		return (rows * 276) + (rows - 1) * 22 + 60;
	}}px;

	@media screen and (max-width: 1379px) {
		max-height: ${props => {
			const rows = Math.ceil(props.numChild / 2);
			return (rows * 276) + (rows - 1) * 22 + 60;
		}}px;
		grid-template-columns: repeat(2, minmax(420px, 1fr));
	}
	
	@media screen and (max-width: 990px) {
		max-height: ${props => {
			const rows = Math.ceil(props.numChild / 1);
			return (rows * 276) + (rows - 1) * 22 + 60;
		}}px;
		grid-template-columns: repeat(1, minmax(420px, 1fr));
	}

	@media screen and (max-width: 460px) {
		grid-template-columns: repeat(1, minmax(95vw, 1fr));
	}

	overflow: hidden;
	transition: all 300ms ease-in;

	&.closed {
		padding: 0px;
		max-height: 0px;
	}
`;

const Message = styled.div`
    padding-top: 100px;
    text-align: center;
	color: ${props => props.theme.TextDefault};
`;

const Arrow = styled.div<{ iconUrl: string, className?: string }>`
	//position: absolute;
	position: sticky;

	align-self: flex-end;
	//right: 10px;

	bottom: 40px;
	@media screen and (max-width: 1041.5px) {
		bottom: 60px;
	}

	@media screen and (max-width: 704px) {
		bottom: 15px;
		margin-bottom: 20px;
	}

	cursor: pointer;
	background-color: transparent;

	background-image: url("${props => props.iconUrl}");
	background-repeat: no-repeat;
	background-size: 20px;
	background-position-x: center;
	background-position-y: center;

	min-width: 50px;
	width: 50px;
	max-width: 50px;

	min-height: 50px;
	height: 50px;
	max-height: 50px;

	border-radius: 30px;
	border-inline: 3px solid ${props => props.theme.Gray3};
	border-block: 3px solid ${props => props.theme.Gray3};
	opacity: 0.6;

	transition: all 250ms ease-out;

	&:hover {
		opacity: 1;
	}

	&.disabled {
		opacity: 0.3;

	}
`;

/*const SortModeArray = [
	"sortByNameAsc",
	"sortByNameDesc",
	"sortByRecent",
] as const;
export type SortModeType = typeof SortModeArray[number];

const sortModes: { [key in SortModeType]: any } = {
	"sortByRecent": { sortBy: "createdAt", sortDir: "asc" },
	"sortByNameAsc": { sortBy: "name", sortDir: "asc" },
	"sortByNameDesc": { sortBy: "name", sortDir: "desc" },
};*/



//const productPerPage = 30;

export const Categories = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const { externalCategoryId } = useParams();

	const { auth } = useAuth();
	const Private_BE_API = useAxiosPrivate();
	const logout = useLogout();

	const [searchParams, useSearchParam] = useSearchParams();
	/*const sortOptionQueryValue = searchParams.get("sortOption");
	const validateSortOption = (value?: string): SortModeType => {
		return SortModeArray.find(curr => curr === value) ? value as SortModeType : SortModeArray[0];
	};
	const [sortOption, setSortOption] = useState<SortModeType>(sortOptionQueryValue ? validateSortOption(sortOptionQueryValue) : SortModeArray[0]);

	useEffect(() => {
		searchParams.set("sortOption", sortOption);
		useSearchParam(searchParams, { replace: true });
	}, [sortOption]);*/

	const searchValueQueryValue = searchParams.get("searchV");
	const validateSearch = (value?: string): string => {
		return value || "";
	};
	const [searchValue, setSearchValue] = useState<string>(validateSearch(searchValueQueryValue!));
	useEffect(() => {
		searchParams.set("searchV", searchValue);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useSearchParam(searchParams, { replace: true });
	}, [searchValue]);

	useEffect(() => {
		const newSearchParam = validateSearch(searchParams.get("searchV")!);
		if (newSearchParam !== searchValue)
			setSearchValue(newSearchParam);

		/*const newSortOption = validateSortOption(searchParams.get("sortOption")!);
		if (newSortOption !== sortOption)
			setSortOption(newSortOption);*/

		const newPage = validatePageLight(Number(searchParams.get("page")));
		if (newPage !== page)
			setPage(newPage);

	}, [searchParams]);

	const pageQueryValue = searchParams.get("page");
	const validatePageLight = (page: number): number => {
		return !Number.isNaN(page) && page > 0
			? page
			: 1;
	};
	const [page, setPage] = useState<number>(pageQueryValue ? validatePageLight(Number(pageQueryValue)) : 1);
	useEffect(() => {
		searchParams.set("page", page.toString());
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useSearchParam(searchParams, { replace: true });
	}, [page]);

	const debouncedSearch = useDebounce(searchValue, 500);

	useEffect( () => {
		if(debouncedSearch.length > 0) {
			navigate(`search?searchV=${debouncedSearch}`);
		}
	}, [debouncedSearch])

	const CategoriesQuery = async () : Promise<ICategory[]> => {
		try {
			let categoryId = null;
			if(externalCategoryId && externalCategoryId !== "")
			{
				const {data, status} = await Private_BE_API.get<IResponse<ICategory>>(`/Fabric/GetCategoryByIdReference/${externalCategoryId}`);
				console.log("categoryByRef:" + JSON.stringify(data, null, 4));

				categoryId = data.Data.IdCategory ?? null;
			}

			const payloadRoot: IGetCategoriesRequest = {
				IdCategoryParent: categoryId,
				Name: debouncedSearch,
				State: "Approved",
				PageFilter: {
					Page: 0,
					Length: 999
				},
				OnlyLeaf: false,
			};

			const {data} = await Private_BE_API.post<IResponse<ICategory[]>>("/Fabric/GetCategories", payloadRoot);

			/*const categories : CategoryData[] = [];

			for(const rootCategory of data.Data)
			{
				rootCategory.Name = removeOrderPrefix(rootCategory.Name);
				rootCategory.children = [];

				if(rootCategory.HasChildren)
				{
					const payloadChild: IGetCategoriesRequest = {
						IdCategoryParent: rootCategory.IdCategory,
						State: "Approved",
						Name: debouncedSearch,
						PageFilter: {
							Page: 0,
							Length: 999
						}
					};

					//const { data: childrenData } = await Private_BE_API.post<IResponse<CategoryData[]>>("/Fabric/GetCategories", payloadChild);
					//rootCategory.children = childrenData.Data;
					//for(const childCategory of rootCategory.children)
					//{
					//	childCategory.Name = removeOrderPrefix(childCategory.Name);
					//}

					const childrenResponse = Private_BE_API.post<IResponse<CategoryData[]>>("/Fabric/GetCategories", payloadChild);
					rootCategory.childrenResponse = childrenResponse;
				}

				categories.push(rootCategory);
			}

			for(const rootCategory of categories) {
				if(rootCategory.childrenResponse)
				{
					rootCategory.children = (await (rootCategory.childrenResponse)).data.Data;
				}

				for(const childCategory of rootCategory.children!)
				{
					childCategory.Name = removeOrderPrefix(childCategory.Name);
				}
			}


			//console.log("Categories:" + JSON.stringify(categories, null, 4));

			return categories;*/
			return data.Data;
		}
		catch (e : any) {
			console.warn("/Fabric/GetCategories returned error:", e);
			if(e.response.status === 401)
			{
				console.error("Token expired: logout");
				logout();
			}

			return [];
		}
	};

	const {
		isLoading,
		data: categories,
		error,
		isFetching,
	} = useQuery(
		["GetCategories", auth!.succeeded, page, /*sortModes[sortOption],*/ externalCategoryId, debouncedSearch],
		CategoriesQuery,
		{ enabled: auth?.succeeded, keepPreviousData: true, staleTime: /*10000*/10 }
	);

	const CategoriesGrid = useMemo(() => {
		return (
			< >
				{isLoading &&
					<ProductList numChild={8}>
						{Array.from({ length: 8 }).map((_, i) => <CategoryCardPlaceholder key={"pp" + i} />)}
					</ProductList>
				}
				{
					!isLoading && (categories === undefined || categories.length === 0) &&
					<Message className="heading-s">{t("EmptyStateCategories")}</Message>
				}

				{ categories &&
					<>
						<ProductList numChild={categories.length}>
							{categories?.map(category => (
								<CategoryCard
									key={"ProductCat_" + category.IdCategory}
									category={category}
									onCardClick={() => {
										searchParams.set("scrollTo", category.IdReference);

										// eslint-disable-next-line react-hooks/rules-of-hooks
										useSearchParam(searchParams, {replace: true});
										if(category.HasGrandChildren ) //navigate in sub catalog
										{
											navigate(`${category.IdReference}`);
										}
										else {
											navigate(`/products/${category.IdReference}`);
										}
									}}
									onDownloadClick={(webLink) => {}}
								/>
							))}
						</ProductList>
					</>
				}

			</>
		);}, [i18n.language, isLoading, categories]);

	const scrollToQueryValue = searchParams.get("scrollTo");
	useEffect(() => {
		if (categories && scrollToQueryValue && scrollToQueryValue !== "") {
			const elementToFocus = document.getElementById(scrollToQueryValue);
			if (elementToFocus) {
				//elementToFocus.scrollIntoView({ block: "center", behavior: "smooth" });
				elementToFocus.scrollIntoView({ block: "center", behavior: "auto" });
			}
		}
	}, [isLoading, categories]);

	return (
		<Wrapper>
			<Header onSearchValueChange={setSearchValue} /*crumbs={crumbs}*/ searchValue={searchValue} showSearch />
			{/*<ControlBar isSticky={isSticky} leftSlot={ProductsCount} rightSlot={[FiltersControls, SortControls]} />*/}
			<div id={"scrollTop"} style={{height:"0px"}}/>

			{CategoriesGrid}

			<div style={{flex: "1 0 auto"}}></div>
			{/*<Pagination
				currentPage={page}
				totalPages={totalProducts ? Math.ceil(totalProducts / productPerPage) : 1}
				siblingCount={2}
				onChange={setPage}
			/>*/}

			<Arrow /*className={windowsIndex <= 0 ? "disabled" : ""}*/ iconUrl={"/assets/UpArrow2.svg"}
			   onClick={() => {
				   const elementToFocus = document.getElementById("scrollTop");
				   if (elementToFocus) {
					   elementToFocus.scrollIntoView({ block: "center", behavior: "smooth" });
				   }
			   }}></Arrow>
			<Footer marginTop={"0px"}/>
		</Wrapper>
	);
};
