
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {ICategoryWebBundle} from "../../../types/categories";
import {ProductData} from "../../../types";

const ThumbnailsList = styled.div`
 	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	
	gap: 5px;
	padding: 5px;

	border-radius: 90px;
	background-color: rgba(255, 255, 255, 0);
`;

const ThumbsContainer = styled.div<{ imageUrl: string }>`
	position: relative;
	
	cursor: pointer;
	background-image: url("${props => props.imageUrl}");
	background-repeat: no-repeat;
	background-size: 60px;
	background-position-x: center;
	background-position-y: center;
	
	min-width: 70px;
	width: 70px;
	max-width: 70px;
	
	min-height: 70px;
	height: 70px;
	max-height: 70px;
	
	border-radius: 40px;

	border: 5px solid ${props => props.theme.Gray0};

	transition: all 250ms ease-out;
	
	&:hover {
		//opacity: 0.6;
		border: 5px solid ${props => props.theme.Gray3};
	}
`;

const Selected = styled.div`
	display: absolute;
	top: 2px;
	left: 2px;
	
	min-width: 60px;
	width: 60px;
	max-width: 60px;
	
	min-height: 60px;
	height: 60px;
	max-height: 60px;
	
	border-radius: 40px;

	border: 2px solid ${props => props.theme.Gray5};
`;

const ModelsBlock = ({ models, selectedModel, onChange }: IProps) => {

	return (
		<ThumbnailsList>
			{models?.map((model, i) => (
				<ThumbsContainer key={`pa_${i}`} id={`pa_${i}`} imageUrl={model.Image}
								 onClick={() => onChange(model)}>
					{model.IdExternal === selectedModel?.IdExternal && model.Name === selectedModel?.Name &&
						<Selected></Selected>
					}
				</ThumbsContainer>
			))}
		</ThumbnailsList>
	);
};

interface IProps {
	models: ICategoryWebBundle[],
	selectedModel: ICategoryWebBundle | null,
	onChange: (newSelectedVariant: ICategoryWebBundle) => void,
}

export default ModelsBlock;