import { useContext, useDebugValue } from "react";
import AuthContext from "../context/AuthProvider";

const useAuth = () => {

	const { auth } = useContext(AuthContext);
	useDebugValue(auth, auth => `Login status : ${auth?.succeeded}`);

	return useContext(AuthContext);
};

export default useAuth;