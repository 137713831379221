import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

import { Logo } from "components/logo/logo";
import {Button} from "../components/button/button";
import Footer from "../components/footer/footer";


const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	height: 100%;
	padding-top: 80px;
	
	overflow-y: auto;
	background-color: ${props => props.theme.Background};

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;
const RowParent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 1200px;
`;

const Header = styled.div`
    padding-top: 30px;
	color: ${props => props.theme.Gray5};
	font-weight: bold;
`;

const WrapperMessage = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	align-items: center;
	padding: 40px 30px 30px 30px;
	gap:0px;
`;

const Message = styled.div`
    padding-top: 0px;
    text-align: center;
	color: ${props => props.theme.TextDefault};
`;


const Row = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
    
    gap: 20px;
`;

export const Page404 = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<PageWrapper>
			<RowParent>
				<Logo style={{ height: "120px", width: "unset", cursor: "pointer" }} onClick={() => navigate("/")} />
			</RowParent>
			<RowParent>
				<Header className="heading caps">Digital Library</Header>
			</RowParent>
			<RowParent style={{ flex: "1 0 auto" }}>
				{
					<WrapperMessage>
						<Message className="heading-s">{t("404Label")}</Message>
						<Row style={{marginTop: "70px", width: "200px"}}>
							<Button
								label={t("BackToHome")}
								backgroundColor={"TextDark"}
								color={"Gray0"}
								hoverDark={false}
								onClick={() => navigate("/")}
								isLoading={false}
								isDisabled={false}
								shouldFill
								type="button"
							/>
						</Row>
					</WrapperMessage>
				}
			</RowParent>
			<div style={{flex: "1 0 auto"}}></div>
			<Footer/>
		</PageWrapper>
	);
};