import React from "react";
import { Outlet } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import calendar from "dayjs/plugin/calendar";

import ThemeProviderSelector from "./ThemeSelector";

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(localizedFormat);

export const App = () => {
	return (
		<ThemeProviderSelector>
			<Outlet />
		</ThemeProviderSelector>
	);
};
