import React, { useEffect, useState } from "react";
import styled, {useTheme} from "styled-components";

import { useTranslation } from "react-i18next";
import {Button} from "../button/button";
import { InputField } from "../inputField/inputField";
import {ECommercialArea, ICustomer} from "../../types/orders";
import {ComboBox} from "../comboBox/comboBox";
import Settings from "../../configs/settings";
import {Toggle} from "../toggle/toggle";
import {ProductData} from "../../types";
import {SearchComboBox} from "../comboBox/searchComboBox";

import CountriesJson from "../../utils/countriesJson";
import {
	CountriesComboDisplayNamesList,
	CountriesComboKeyList,
	CountryCodeToLocalizedName
} from "../../utils/countriesUtils";

const FullScreenBackground = styled.div`
  position: absolute;
	inset: 0;
	z-index: var(--z-level-5);
	
	width: 100%;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//padding: 160px 400px 50px 50px;
	gap: 0px;
	
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
`;

const MainWrapper = styled.div`
	position: relative;
	
	display: flex;
	flex-direction: column;
	
	justify-content: flex-start;
	//align-items: center;
	gap: 10px;
	padding: 20px;

	//min-height: 500px;
	//max-height: 800px;
	min-width: 500px;
	max-width: 500px;
	@media screen and (max-width: 704px) {
		min-width: calc(100% - 10px);
		max-width: calc(100% - 10px);
	}

	background-color: ${props => props.theme.Background};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
`;

const Title = styled.p`
	color: ${props => props.theme.TextDefault};
`;

const InputRow  = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	gap: 10px;
	min-width: 100%;
	width: 100%;
`;

const InputGroup = styled.div<{width?: string}>`
	display: flex;
	flex-direction: column;
	width: ${props => props?.width ?? '100%'};
	//height: 43px;
`;

const Label = styled.p`
	color: ${props => props.theme.TextDefault};
	
	padding-bottom: 5px;
`;

const ErrorMessageContextual = styled.div<{visible?: boolean}>`
	//display: flex;
	display: ${({ visible }) => visible === false ? 'none' : 'flex'};
	align-items: start;
	width: 100%;

	flex-direction: row;
	gap: 20px;//${({visible}) => visible === false ? '0px' : '20px'};
	justify-content: left;
	color: ${props => props.theme.Negative};
	height: 14px; //${({visible}) => visible === false ? '0px' : '14px'};
	padding-left: 2px;
	padding-bottom: 0px;
`;

const ButtonRow  = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	padding-top: 10px;
	gap: 20px;
	min-width: 100%;
	width: 100%;
`;

enum EFieldsTypes {
	BUSINESS_NAME, COUNTRY, COUNT
}

const newAddress = "(NEW ADDRESS)"

const DialogEditCustomer = ({ customer, onCancelEdit, onNewValue}: IProps) => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();

	const [businessName, setBusinessName] = useState<string>(customer?.BusinessName || "");
	const [streetName, setStreetName] = useState<string>(customer?.Address || "");
	const [streetNumber, setStreetNumber] = useState<string>(customer?.StreetNumber || "");
	const [city, setCity] = useState<string>(customer?.City || "");
	const [postalCode, setPostalCode] = useState<string>(customer?.PostalCode || "");
	const [country, setCountry] = useState<string>(customer?.Country || "");
	const [countryFilter, setCountryFilter] = useState<string>("");
	//const [sector, setSector] = useState<ECommercialArea>(customer?.CommercialArea || ECommercialArea.Vari);

	const [sectorsString, setSectorsString] = useState<string>(customer?.CommercialArea ? customer?.CommercialArea+"," : "");
	const changeSectorSelection = (sector: ECommercialArea, newSelectedState: boolean) => {
		const currentSelected : boolean = sectorsString.includes(sector);
		if(newSelectedState && !currentSelected ) {
			setSectorsString(sectorsString + sector + ",");
		}
		if(!newSelectedState && currentSelected) {
			setSectorsString(sectorsString.replace(sector + ",", ""));
		}
	}

	const kMandatorySign: string = '*';
	const fieldsErrorStates = Array.from({length: EFieldsTypes.COUNT}, _ => useState<boolean>(false));

	const ClearValidationFeedbacks = (): void => {
		fieldsErrorStates.forEach(([_/*getter*/, setter]) => setter(false));
	}

	const ValidateFields = (): boolean => {
		let outValue: boolean = true;
		{
			const bValid = typeof businessName === 'string' && businessName.length > 0;
			fieldsErrorStates[EFieldsTypes.BUSINESS_NAME][1](!bValid);
			outValue = outValue && bValid;	
		}
		{
			const bValid = typeof country === 'string' && country.length > 0;
			fieldsErrorStates[EFieldsTypes.COUNTRY][1](!bValid);
			outValue = outValue && bValid;	
		}
		return outValue;
	};

	const onConfirmEdit = () => {
		//Mandatory fields are only businessName and country
		if (ValidateFields()) {
			let sectors = sectorsString;
			if(sectors.endsWith(","))
			{
				sectors = sectors.slice(0, sectors.length - 2);
			}

			let overrideAddress = undefined;
			if(customer?.IdCustomer &&
				(customer.Address !== streetName
					|| customer.StreetNumber !== streetNumber || customer.City !== city
					|| customer.PostalCode !== postalCode || customer.Country !== country)
				){
				overrideAddress = streetName.startsWith(newAddress) ? streetName : `${newAddress} ${streetName}`
			}

			const newCustomer : ICustomer =
			{
				IdCustomer: customer?.IdCustomer,
				BusinessName: businessName,
				Address: overrideAddress ?? streetName,
				StreetNumber: streetNumber,
				City: city,
				PostalCode: postalCode,
				Country: country,
				CommercialArea: sectors,
				Operator: Settings.defaultOperatorId
			};
			console.log("Customer value: ", newCustomer);
			onNewValue(newCustomer);
		}
	}


	// @ts-ignore
	return (
		<FullScreenBackground>
			<MainWrapper>
				<InputRow>
					<InputGroup>
						<Title className={"heading"}>{customer ? t("AddCustomer") : t("EditCustomer") }</Title>

					</InputGroup>
				</InputRow>

				<InputRow>
					<InputGroup>
						<InputField
							size="Compact"
							width="100%"
							backgroundColor={theme.Gray1}
							value={businessName}
							onChange={value => setBusinessName(value)}
							placeholder={`${kMandatorySign} ${t("BusinessName")}`}
							borderRadius={"4px"}
							isInvalid={!fieldsErrorStates[EFieldsTypes.BUSINESS_NAME][0]}
						/>
						<ErrorMessageContextual visible={fieldsErrorStates[EFieldsTypes.BUSINESS_NAME][0]} className="ptext">
							{t("BusinessError")}
						</ErrorMessageContextual>
					</InputGroup>
				</InputRow>

				<InputRow style={{alignItems:'flex-start'}}>
					<InputGroup width="85%">
						<InputField
							size="Compact"
							width="100%"
							backgroundColor={theme.Gray1}
							value={streetName}
							onChange={value => setStreetName(value)}
							placeholder={t("StreetName")}
							borderRadius={"4px"}
						/>
						{/*<ErrorMessageContextual visible={fieldsErrorStates[EFieldsTypes.COMPANY][0]} className="ptext">
							{t("CompanyError")}
						</ErrorMessageContextual>*/}
					</InputGroup>
					<InputGroup width="15%">
						<InputField
							size="Compact"
							width="100%"
							backgroundColor={theme.Gray1}
							value={streetNumber}
							onChange={value => setStreetNumber(value)}
							placeholder={t("Number")}
							borderRadius={"4px"}
						/>
						{/*<ErrorMessageContextual visible={fieldsErrorStates[EFieldsTypes.ROLE][0]} className="ptext">
							{t("RoleError")}
						</ErrorMessageContextual>*/}
					</InputGroup>
				</InputRow>

				<InputRow style={{alignItems:'flex-start'}}>
					<InputGroup width="70%">
						<InputField
							size="Compact"
							width="100%"
							backgroundColor={theme.Gray1}
							value={city}
							onChange={value => setCity(value)}
							placeholder={t("City")}
							borderRadius={"4px"}
						/>
						{/*<ErrorMessageContextual visible={fieldsErrorStates[EFieldsTypes.COMPANY][0]} className="ptext">
							{t("CompanyError")}
						</ErrorMessageContextual>*/}
					</InputGroup>
					<InputGroup width="30%">
						<InputField
							size="Compact"
							width="100%"
							backgroundColor={theme.Gray1}
							value={postalCode}
							onChange={value => setPostalCode(value)}
							placeholder={t("PostalCode")}
							borderRadius={"4px"}
						/>
						{/*<ErrorMessageContextual visible={fieldsErrorStates[EFieldsTypes.ROLE][0]} className="ptext">
							{t("RoleError")}
						</ErrorMessageContextual>*/}
					</InputGroup>
				</InputRow>

				<InputRow>
					<InputGroup width="100%">
						{/*<InputField
							size="Compact"
							width="100%"
							backgroundColor={theme.Gray1}
							value={country}
							onChange={value => setCountry(value)}
							placeholder={`${kMandatorySign} ${t("Country")}`}
							borderRadius={"4px"}
							isInvalid={!fieldsErrorStates[EFieldsTypes.COUNTRY][0]}
						/>*/}
						<Label className={"heading-s"} >{t("Country")}: {CountryCodeToLocalizedName(country, i18n.language)}</Label>
						<SearchComboBox
							keyValue={country}
							onSelectionChange={(value: string) => {
								setCountry(value);
							}}
							onFilterValueChange={(value: string) => {
								setCountryFilter(value);
							}}
							minSearchCharacter={0}
							keyOptions={CountriesComboKeyList(countryFilter, i18n.language)}
							displayOptions={CountriesComboDisplayNamesList(countryFilter, i18n.language)}
							style={{width: "100%"}}
							enabled={true}
						/>
						<ErrorMessageContextual visible={fieldsErrorStates[EFieldsTypes.COUNTRY][0]} className="ptext">
							{t("CountryError")}
						</ErrorMessageContextual>
					</InputGroup>
				</InputRow>

				{/*<InputRow>
					<ComboBox
						keyValue={sector}
						onChange={(keyValue) => { setSector(keyValue as ECommercialArea) }}
						keyOptions={Object.values(ECommercialArea)}
						displayOptions={Object.values(ECommercialArea)}
						style={{width: "100%"}}
					/>
					<ComboBox
						keyValue={userType}
						onChange={(keyValue) => { setUserType(keyValue) }}
						keyOptions={["0", "3", "4", "1"]}
						displayOptions={[t("Admin"), t("Seller"), t("Operator"), t("BaseUser")]}
						style={{width: "100%"}}
					/>
				</InputRow>*/}

				<p style={{color: theme.TextDefault}} className={"ptext-xl"}>{t("Sectors")}</p>
				{
					Object.values(ECommercialArea).map(sector =>
						<InputRow key={"sector_"+sector}>
							<Toggle checked={sectorsString.includes(sector)} onChange={(newSelectedState) => {changeSectorSelection(sector, newSelectedState)}}/>
							<span style={{color: theme.TextDefault, width: "40%", textAlign: "left"}} className={"ptext-l"}>{sector}</span>
						</InputRow>
					)
				}

				{/*
				<p style={{color: theme.TextDefault}} className={"ptext-xl"}>{t("ReferencePeople")}</p>
				<InputRow>
					<InputGroup>
						<InputField
							size="Compact"
							width="100%"
							backgroundColor={theme.Gray1}
							value={name}
							onChange={value => setName(value)}
							placeholder={t("Name")}
							borderRadius={"4px"}
						/>
						<ErrorMessageContextual visible={fieldsErrorStates[EFieldsTypes.NAME][0]} className="ptext">
							{t("NameError")}
						</ErrorMessageContextual>
					</InputGroup>
					<InputGroup>
						<InputField
							size="Compact"
							width="100%"
							backgroundColor={theme.Gray1}
							value={surname}
							onChange={value => setSurname(value)}
							placeholder={t("Surname")}
							borderRadius={"4px"}
						/>
						<ErrorMessageContextual visible={fieldsErrorStates[EFieldsTypes.SURNAME][0]} className="ptext">
							{t("SurnameError")}
						</ErrorMessageContextual>
					</InputGroup>
				</InputRow>
				<InputRow>
					<InputGroup width="100%">
						<InputField
							size="Compact"
							width="100%"
							backgroundColor={theme.Gray1}
							value={email}
							onChange={value => setEmail(value)}
							placeholder={t("email")}
							borderRadius={"4px"}
						/>
						<ErrorMessageContextual visible={fieldsErrorStates[EFieldsTypes.EMAIL][0]} className="ptext">
							{t("MailError")}
						</ErrorMessageContextual>
					</InputGroup>
				</InputRow> */}

				<ButtonRow>
					<Button
						label={t("Cancel")}
						backgroundColor={"TextDefault"}
						size={"Compact"}
						overrideWidth={"150px"}
						caps={false}
						color={"Gray0"}
						hoverDark={false}
						onClick={onCancelEdit}
						isLoading={false}
						isDisabled={false}
					/>
					<Button
						label={t("ConfirmLabel")}
						backgroundColor={"Positive"}
						size={"Compact"}
						overrideWidth={"150px"}
						caps={false}
						color={"Gray0"}
						hoverDark={false}
						onClick={onConfirmEdit}
						isLoading={false}
						isDisabled={false}
					/>
				</ButtonRow>
			</MainWrapper>
		</FullScreenBackground >
	);
};

interface IProps {
	customer: ICustomer | undefined;
	onCancelEdit: () => void;
	onNewValue: (customer: ICustomer) => void;
}

export default DialogEditCustomer;