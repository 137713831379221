import React from "react";

import {useAppSelector} from "./store/hook";
import {ThemeProvider} from "styled-components";

import PropTypes from "prop-types";


const ThemeProviderSelectorProps = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired
};

type ThemeProviderSelectorProp = PropTypes.InferProps<typeof ThemeProviderSelectorProps>;

function ThemeProviderSelector(props: ThemeProviderSelectorProp) {

	const activeThemeName = useAppSelector((state) => state.theme.activeThemeName);
	const activeTheme = useAppSelector((state) => state.theme.themes[activeThemeName]);

	return (
		<ThemeProvider theme={activeTheme}>
			{props.children}
		</ThemeProvider>
	);
}

ThemeProviderSelector.propTypes = ThemeProviderSelectorProps;

export default ThemeProviderSelector;