import React from "react";
import {useTranslation} from "react-i18next";
import Webcam, { WebcamProps } from "react-webcam";
import styled from "styled-components";

import { Button } from "components/button/button";
import { CustomToast } from "components/toast/customToast";


const FullScreenBackground = styled.div.withConfig({displayName:'FullScreenBackground'})`
	position: absolute;
	inset: 0;
	z-index: var(--z-level-5);

	width: 100%;
	height: 100%;

	display: flex;
	//flex-direction: column;
	justify-content: center;
	align-items: center;

	//overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
`;

const CloseButtonRow = styled.div`
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	@media screen and (max-width: 704px) {
		padding-bottom: 30px;
	}
`;

const CaptureImageButtonContainer = styled.div`
	position: absolute;
	bottom: 5px;
	//left: 50%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

//	@media screen and (max-width: 704px) {
//		padding-bottom: 30px;
//	}
`;


export default function ImageCaptureDialog({onImageCaptured, onClose, maxResolutions} : IProps): JSX.Element
{
	const screenshotFormatType: WebcamProps['screenshotFormat'] = 'image/jpeg';


	const webcamRef = React.useRef<Webcam|null>(null); // create a webcam reference
	const { t } = useTranslation();
	React.useEffect(() =>
	{
		return () => {};
	}, []);

	// create a capture function
	async function CaptureImage() {
		if (webcamRef && webcamRef.current) {
			if (webcamRef.current.state.hasUserMedia) {
				if (webcamRef.current.getCanvas()) {
					let screenshot: string | null = 
					//Image4kTest;
					webcamRef.current.getScreenshot();
					if (typeof screenshot === 'string') {
						if (maxResolutions) {
							screenshot = await ResizeImage(screenshot, maxResolutions, screenshotFormatType, webcamRef.current.props.screenshotQuality || 1.0);
						}
						onImageCaptured(screenshot);
						return;
					}
				}
			}
		}
		
		onClose();
		CustomToast.error({title:t('Error'), msgText:t('UnableToCaptureImage')});
	};

	function ErrorHandler(error: string | DOMException): void
	{
		onClose();
		CustomToast.error({title:t('Error'), msgText:t('CaptureImageInitializationError')});
		console.error(error);
	}

	return (
		<FullScreenBackground>
			<Webcam
				ref={webcamRef}
				audio={false}
				screenshotFormat={screenshotFormatType}
				imageSmoothing={false}
				videoConstraints={videoConstraints}
				onUserMediaError={ErrorHandler}
				style={{width:'90%', height:'90%'}}
			/>
			<CaptureImageButtonContainer>
				<Button onClick={CaptureImage} isOutlined={false} backgroundColor={"Gray5"} hoverDark={false} color={"Gray0"} label={t("CaptureImage")} />
			</CaptureImageButtonContainer>
			<CloseButtonRow>
				<Button onClick={onClose} isOutlined={false} backgroundColor={"Gray5"} hoverDark={false} color={"Gray0"} label={t("Close")} />
			</CloseButtonRow>
		</FullScreenBackground>
	);
}

interface IProps {
	onImageCaptured: (arg1: string) => any;
	onClose: () => void;

	maxResolutions?: IMaxResolutions;
}

interface IMaxResolutions {
	width: number;
	height: number;
}

const videoConstraints : MediaTrackConstraints = {
    facingMode: "environment",
	autoGainControl: true,
};

function ResizeImage(screenshot: string, maxResolutions: IMaxResolutions, screenshotFormat: WebcamProps['screenshotFormat'], screenshotQuality: number): Promise<string> {
	const image = new Image();
	image.src = screenshot;

	return new Promise(resolve => {
		image.onload = () => {
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");

			const originalWidth = image.width;
			const originalHeight = image.height;

			//console.log(originalWidth + " " + originalHeight);

			const ratio = Math.min(1, maxResolutions.width / originalWidth, maxResolutions.height / originalHeight);
			//console.log(ratio);

			const canvasWidth = originalWidth * ratio;
			const canvasHeight = originalHeight * ratio;

			canvas.width = canvasWidth;
			canvas.height = canvasHeight;

			//console.log(canvasWidth + " " + canvasHeight);

			context?.drawImage(
				image,
				0,
				0,
				originalWidth * ratio,
				originalHeight * ratio
			);

			resolve(canvas.toDataURL(screenshotFormat, screenshotQuality));
			canvas.remove();
			image.remove();
		};
	});
}

//const Image4kTest = 'data:image/jpeg;base64,BASE64IMAGE;