import React, { CSSProperties } from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
	z-index: var(--z-level-2);
`;
const HelpButton = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;
	background-color: ${props => props.theme.TextDark}88;
	border: 2px solid ${props => props.theme.Background};
	border-radius: 14px;
	color: ${props => props.theme.Background};
	cursor: pointer;
	opacity: 0.5;
	transition: opacity 300ms ease-in;

	&:hover {
		opacity: 1;
	}

	&:hover > div {
		opacity: 1;
	}
`;
const Tooltip = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 15px;
	right: 38px;
	bottom: 0px;
	color: ${props => props.theme.Gray0};
	background: #00000088;
	border-radius: 4px;
	opacity: 0;
	pointer-events: none;
	transition: opacity 300ms ease-in;
`;
const TooltipTitle = styled.div`
	text-transform: uppercase;
	margin-bottom: 10px;
	font-weight: 500;
	letter-spacing: 0.07rem;
`;
const TooltipRow = styled.div`
	display: flex;
	justify-content: space-between;
	min-width: 100%;
	margin-top: 5px;
	gap: 20px;

	& > span:first-child {
		white-space: nowrap;
		color: ${props => props.theme.Gray2}
	}

	& > span:last-child {
		white-space: nowrap;
		color: ${props => props.theme.Gray0}
	}
`;
const Button = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 30px;
	height: 28px;
	background-color: ${props => props.theme.Background};
	border-width: 2px 2px 0px 2px;
	border-style: solid;
	border-color: ${props => props.theme.Background1};
	border-radius: 8px 8px 0 0;
	color: ${props => props.theme.TextLight};
	cursor: pointer;
	transition: color 300ms ease-in;

	&:hover {
		color: ${props => props.theme.Primary};
	}

	&:last-child {
		height: 30px;
		border-width: 2px 2px 2px 2px;
		border-radius: 0 0 8px 8px;
	}
`;

export function CameraHelpButton ({ ctrlToZoom = false, style = undefined }: { ctrlToZoom?: boolean, style?: CSSProperties | undefined }) {

	const { t } = useTranslation();

	return (
		<HelpButton className="text-xl" style={style} >
			?
			<Tooltip className="ptext">
				<TooltipTitle className="text-l">{t("Help3dHeader")}</TooltipTitle>
				<TooltipRow>
					<span>{t("LeftMouseButton")}</span>
					<span>{t("RotateCamera")}</span>
				</TooltipRow>
				<TooltipRow>
					<span>{t("RightMouseButton")}</span>
					<span>{t("PanCamera")}</span>
				</TooltipRow>
				<TooltipRow>
					<span>{(ctrlToZoom ? "Ctrl + " : "") + t("MouseWheel")}</span>
					<span>{t("ZoomCamera")}</span>
				</TooltipRow>
			</Tooltip>
		</HelpButton>
	);
}

function ZoomSlider ({ value = 1, onChange, zoomActive = true }: iProps) {

	return (
		<Wrapper>
			<CameraHelpButton />
			{
				zoomActive &&
				<div>
					<Button className="heading" onClick={() => onChange(value + 1)}>+</Button>
					<Button className="heading" onClick={() => onChange(value - 1)}>-</Button>
				</div>
			}
		</Wrapper >
	);
}

interface iProps {
	value?: number,
	onChange: (value: number) => void,
	zoomActive?: boolean
}

export default ZoomSlider;