import axios from "axios";
import Settings from "../configs/settings";

export const BE_API = axios.create({
	baseURL: Settings.be_url,
	headers: { "Content-Type": "application/json" },
});

export const USER_REGISTRATION_API = axios.create({
	baseURL: Settings.be_userRegistration_url,
	headers: { "Content-Type": "application/json" },
});

