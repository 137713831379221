import React from "react";
import styled from "styled-components";
import chroma from "chroma-js";

import { Icon, IconPathsKeysType } from "../icon/icon";
import { ThemePaletteKeys } from "store/Theme";
import { ComponentSize } from "types";

const ButtonWrapper = styled.button<{ color: ThemePaletteKeys, backgroundColor: ThemePaletteKeys, hoverDark: boolean }>`
	position: relative;
	flex: 0 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	user-select: none;
	cursor: pointer;
	border: none;

	background-color: ${props => props.theme[props.backgroundColor]};
	color: ${props => props.theme[props.color]};

	transition: background-color 250ms ease-out;

	&.size-Compact {
		padding: 3px 8px 4px 8px;
		gap: 5px;
	}
	&.size-Default {
		padding: 10px 10px;
		gap: 20px;
	}
	&.size-Large {
		padding: 10px 10px;
		gap: 10px;
	}

	&.fill {
		width: 100%;
	}

	&.rounded {
		border-radius: 3px;
	}

	&.outlined {
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}

	&:disabled {
		color: ${props => props.theme.Gray2};
		background-color: ${props => props.theme.Gray1};
		pointer-events: none;
		cursor: default;
	}

	&:hover {
		background-color: ${props => props.hoverDark
			? chroma(props.theme[props.backgroundColor]).darken(0.5).css()
			: chroma(props.theme[props.backgroundColor]).brighten(1.6).css()};
	}
`;


const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Label = styled.div`
	font-style: normal;
	flex: 1 0 auto;
`;

export const Button = ({
						   label,
						   color = "TextDefault",
						   backgroundColor = "Background",
						   hoverDark = true,
						   size = "Default",
						   caps = true,
						   isRounded = true,
						   isOutlined = false,
						   isDisabled = false,
						   isLoading = false,
						   shouldFill = false,
						   overrideWidth = "",
						   iconWidth = "18px",
						   leftIcon,
						   rightIcon,
						   leftIconSrcOverride, rightIconSrcOverride,
						   leftIconFlipHoriz = false,
						   rightIconFlipHoriz = false,
						   ...props
					   }: IProps) => {

	const disabled = isDisabled || isLoading;

	const classes = [
		"size-" + size,
		isRounded ? "rounded" : null,
		isOutlined ? "outlined" : null,
		shouldFill ? "fill" : null,
		caps ? "caps": null,
	].join(" ");

	const IconsProps = {
		type: disabled ? "Gray2" : color,
		size: iconWidth,
		//width: size === "Large" ? 20 : 18,
		strokeWidth: size === "Compact" ? 1 : 1.5,
	};

	const labelClass = size === "Large" ? "heading-xs" : size === "Default" ? "heading-xs" : "text-l";

	return (
		<ButtonWrapper className={classes} disabled={disabled} color={color} backgroundColor={backgroundColor} hoverDark={hoverDark}
					   style={ overrideWidth ? {width: overrideWidth} : {}}
					   {...props}>
			{ (leftIcon || leftIconSrcOverride) && (
				<IconWrapper className={isLoading ? "spinning" : ""}>
					{ !leftIconSrcOverride
						? <Icon name={isLoading ? "loading" : leftIcon!} {...IconsProps} />
						:<img style={leftIconFlipHoriz ? {transform: "rotate(180deg)"} : {}} src={leftIconSrcOverride} width={iconWidth} {...IconsProps}/> }
				</IconWrapper>
			)}
			{	label?.length > 0 &&
				<Label className={labelClass}>{label}</Label>
			}
			{ (rightIcon || rightIconSrcOverride) && (
				<IconWrapper className={isLoading ? "spinning" : ""}>
					{ !rightIconSrcOverride
						? <Icon name={isLoading ? "loading" : rightIcon!} {...IconsProps} />
						:<img style={rightIconFlipHoriz ? {transform: "rotate(180deg)"} : {}} src={rightIconSrcOverride} width="14px" {...IconsProps}/> }
				</IconWrapper>
			)}
		</ButtonWrapper>
	);
};


interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	label: string,
	color?: ThemePaletteKeys,
	backgroundColor?: ThemePaletteKeys,
	hoverDark?: boolean,
	size?: ComponentSize,
	isRounded?: boolean,
	isOutlined?: boolean,
	isDisabled?: boolean,
	isLoading?: boolean,
	shouldFill?: boolean,
	overrideWidth?: string,
	caps?: boolean,
	iconWidth?: string,
	leftIcon?: IconPathsKeysType,
	rightIcon?: IconPathsKeysType,
	leftIconSrcOverride?: string,
	rightIconSrcOverride?: string,
	leftIconFlipHoriz?: boolean,
	rightIconFlipHoriz?: boolean,
}
