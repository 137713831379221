import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Logo } from "components/logo/logo";
import Footer from "../../components/footer/footer";


const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	height: 100%;
	padding-top: 80px;
	
	overflow-y: auto;
	background-color: ${props => props.theme.Background};

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;
const RowParent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 1200px;
`;

const Header = styled.div`
    padding-top: 30px;
	color: ${props => props.theme.Gray5};
	font-weight: bold;
`;

const WrapperMessage = styled.div`
	display: flex;
	flex-direction: column;
	width: 900px;
	@media screen and (max-width: 900px) {
		min-width: calc(100% - 10px);
		width: calc(100% - 10px);
		max-width: calc(100% - 10px);
	}
    
	align-items: start;
	padding: 40px 30px 30px 30px;
	gap:0px;
`;

const Message = styled.div`
    padding-top: 0px;
    text-align: left;
	color: ${props => props.theme.TextDefault};
    
    padding-bottom: 20px;

	&.bold {
		font-weight: 510;
	}
`;

const BoldSpan = styled.span`
    font-weight: 510;
`;

const ItalicSpan = styled.span`
	font-style: italic;
`;

const UL = styled.ul`
	padding-top: 10px;
    padding-left: 10px;
    
	list-style-type: circle;
	list-style-position: inside;
    
    //position: relative;
    //left: 40px;
`;

export const PrivacyPolicy = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    return (
        <PageWrapper>
            <RowParent>
                <Logo style={{ height: "120px", width: "unset", cursor: "pointer" }} onClick={() => navigate("/")}/>
            </RowParent>
            <RowParent>
                <Header className="heading caps">{t("PrivacyPolicy")}</Header>
            </RowParent>
            <RowParent style={{ flex: "1 0 auto" }}>
                <WrapperMessage>
                    <Message className="heading-s bold">{t("PrivacyPolicy1")}<br/>{t("PrivacyPolicy2")}</Message>
                    <Message className="heading-s bold">{t("PrivacyPolicy3")}</Message>
                    <Message className="heading-s">{t("PrivacyPolicy4")}</Message>
                    <Message className="heading-s bold">{t("PrivacyPolicy5")}</Message>
                    <Message className="heading-s">{t("PrivacyPolicy6")}</Message>
                    <Message className="heading-s"><BoldSpan>Eurojersey Spa</BoldSpan> - {t("PrivacyPolicy7")}</Message>
                    <Message className="heading-s bold">{t("PrivacyPolicy8")}
                    </Message>
                    <Message className="heading-s">{t("PrivacyPolicy9")}
                        <UL>
                            <li className="heading-s">{t("PrivacyPolicy10")}</li>
                            <li className="heading-s">{t("PrivacyPolicy11")}</li>
                            <li className="heading-s">{t("PrivacyPolicy12")}</li>
                            <li className="heading-s">{t("PrivacyPolicy13")}</li>
                        </UL>
                    </Message>
                    <Message className="heading-s bold">{t("PrivacyPolicy14")}</Message>
                    <Message className="heading-s"><ItalicSpan>{t("PrivacyPolicy15")}</ItalicSpan> {t("PrivacyPolicy16")}</Message>
                    <Message className="heading-s">{t("PrivacyPolicy17")}
                        <UL>
                            <li className="heading-s">{t("PrivacyPolicy18")}</li>
                            <li className="heading-s">{t("PrivacyPolicy19")}</li>
                        </UL>
                    </Message>
                    <Message className="heading-s">{t("PrivacyPolicy20")}</Message>
                    <Message className="heading-s"><ItalicSpan>{t("PrivacyPolicy21")}</ItalicSpan> {t("PrivacyPolicy22")}</Message>
                    <Message className="heading-s">{t("PrivacyPolicy23")}</Message>
                    <Message className="heading-s"><ItalicSpan>{t("PrivacyPolicy24")}</ItalicSpan> {t("PrivacyPolicy25")}</Message>

                    <Message className="heading-s bold">{t("PrivacyPolicy26")}</Message>
                    <Message className="heading-s">{t("PrivacyPolicy27")}
                        <UL>
                            <li className="heading-s">{t("PrivacyPolicy28")}</li>
                            <li className="heading-s">{t("PrivacyPolicy29")}</li>
                            <li className="heading-s">{t("PrivacyPolicy30")}</li>
                            <li className="heading-s">{t("PrivacyPolicy31")}</li>
                        </UL>
                    </Message>
                    <Message className="heading-s">{t("PrivacyPolicy32")}</Message>
                    <Message className="heading-s">{t("PrivacyPolicy33")}</Message>
                    <Message className="heading-s">{t("PrivacyPolicy34")}</Message>

                    <Message className="heading-s bold">{t("PrivacyPolicy35")}</Message>
                    <Message className="heading-s">{t("PrivacyPolicy36")}</Message>
                    <Message className="heading-s">{t("PrivacyPolicy37")}
                        <UL>
                            <li className="heading-s">{t("PrivacyPolicy38")}</li>
                            <li className="heading-s">{t("PrivacyPolicy39")}</li>
                            <li className="heading-s">{t("PrivacyPolicy40")}</li>
                            <li className="heading-s">{t("PrivacyPolicy41")}</li>
                            <li className="heading-s">{t("PrivacyPolicy42")}</li>
                            <li className="heading-s">{t("PrivacyPolicy43")}</li>
                        </UL>
                    </Message>

                    <Message className="heading-s bold">{t("PrivacyPolicy44")}</Message>
                    <Message className="heading-s">{t("PrivacyPolicy45")}</Message>
                    <Message className="heading-s">{t("PrivacyPolicy46")}</Message>
                    <Message className="heading-s">{t("PrivacyPolicy47")}</Message>
                    {/*<Row style={{marginTop: "70px", width: "200px"}}>
                        <Button
                            label={t("BackToLogin")}
                            backgroundColor={"TextDark"}
                            color={"Gray0"}
                            hoverDark={false}
                            onClick={() => navigate("/")}
                            isLoading={false}
                            isDisabled={false}
                            shouldFill
                            type="button"
                        />
                    </Row>*/}
                </WrapperMessage>
            </RowParent>
            <Footer/>
        </PageWrapper>
    );
};