import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {useNavigate, useParams} from "react-router-dom";

import {USER_REGISTRATION_API} from "api/axios";
import {
    EGrantAssessCode,
    IGrantAccessResponse,
} from "types";

import { Logo } from "components/logo/logo";
import Footer from "../../components/footer/footer";
import {Button} from "../../components/button/button";
import {Spinner} from "../../components/spinner/spinner";


const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	height: 100%;
	padding-top: 80px;
	
	overflow-y: auto;
	background-color: ${props => props.theme.Background};

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;
const RowParent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 1200px;
`;

const Header = styled.div`
    padding-top: 30px;
	color: ${props => props.theme.Gray5};
	font-weight: bold;
`;

const WrapperMessage = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	align-items: center;
	padding: 40px 30px 30px 30px;
	gap:0px;
`;

const Message = styled.div`
    padding-top: 0px;
    text-align: center;
	color: ${props => props.theme.TextDefault};
`;


const Row = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
    
    gap: 20px;
`;

const ErrorMessage = styled(Row)`
	justify-content: center;
	color: ${props => props.theme.Negative};
	height: 14px;
`;

const GrantAccessCall = async (code: string) => {
    const { data } = await USER_REGISTRATION_API.get<IGrantAccessResponse>(`/user/confirm/${code}`);
    return {respData: data };
};

export const GrantAccess = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { code } = useParams();
    const [showShowCompleteMessage, setShowCompleteMessage] = useState<EGrantAssessCode | null>(null);

    useEffect( () => {
        if(code) {
            let statusCatch: number | null = null;

            Promise.all([
                GrantAccessCall(code).
                catch(resp => {
                    //console.log("catch: ", resp.response.status);
                    statusCatch = resp.response.status;
                }),
                new Promise(resolve => setTimeout(resolve, 1000))
            ])
                .then(([resp]) => {
                    if(!resp) {
                        /*if (statusCatch === EGrantAssessCode.AlreadyActivated) {
                              setShowCompleteMessage(EGrantAssessCode.AlreadyActivated);
                        }*/
                        if (statusCatch === EGrantAssessCode.CodeNotValid) {
                              setShowCompleteMessage(EGrantAssessCode.CodeNotValid);
                        }
                        else {
                            setShowCompleteMessage(EGrantAssessCode.ServerError);
                        }
                        return;
                    }

                    if (!resp.respData.Error && resp.respData.StatusCode === EGrantAssessCode.Ok) {
                        setShowCompleteMessage(EGrantAssessCode.Ok);
                    }
                    else {
                        setShowCompleteMessage(EGrantAssessCode.ServerError);
                    }
                }).catch(resp => {
                    console.log("fawfwa", resp);


            });
        }
    }, []);

    return (
        <PageWrapper>
            <RowParent>
                <Logo style={{ height: "120px", width: "unset", cursor: "pointer" }} onClick={() => navigate("/")} />
            </RowParent>
            <RowParent>
                <Header className="heading caps">Digital Library</Header>
            </RowParent>
            <RowParent style={{ flex: "1 0 auto" }}>
                {
                    showShowCompleteMessage === null &&
                        <WrapperMessage>
                            <Message className="heading-s">{t("RequestAccessLoading")}</Message>
                            <div style={{height: "30px"}}></div>
                            <Spinner size={"Default"}></Spinner>
                        </WrapperMessage>
                }
                {
                    showShowCompleteMessage &&
                        <WrapperMessage>
                            {showShowCompleteMessage === EGrantAssessCode.Ok &&
                                <Message className="heading-s">{t("CompletedActivation")}</Message>
                            }
                            {/*showShowCompleteMessage === EGrantAssessCode.AlreadyActivated &&
                                <Message className="heading-s">{t("UserAlreadyActivated")}</Message>
                            */}
                            {showShowCompleteMessage === EGrantAssessCode.CodeNotValid &&
                                <ErrorMessage className="text-s">{t("CodeNotValid")}</ErrorMessage>
                            }
                            {showShowCompleteMessage === EGrantAssessCode.ServerError &&
                                <ErrorMessage className="text-s">{t("ServerError")}</ErrorMessage>
                            }

                            <Row style={{marginTop: "70px", width: "200px"}}>
                                <Button
                                    label={t("BackToLogin")}
                                    backgroundColor={"TextDark"}
                                    color={"Gray0"}
                                    hoverDark={false}
                                    onClick={() => navigate("/")}
                                    isLoading={false}
                                    isDisabled={false}
                                    shouldFill
                                    type="button"
                                />
                            </Row>
                        </WrapperMessage>
                }
            </RowParent>
            <div style={{flex: "1 0 auto"}}></div>
            <Footer/>
        </PageWrapper>
    );
};