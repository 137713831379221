import React, {useEffect} from "react";
import styled, {useTheme} from "styled-components";

import { useTranslation } from "react-i18next";
import {Button} from "../button/button";
import {FileDescriptor, TypeDownload} from "../../types/categories";

const FullScreenBackground = styled.div`
    position: absolute;
	inset: 0;
	z-index: var(--z-level-5);
	
	width: 100%;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//padding: 160px 400px 50px 50px;
	gap: 0px;
	
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
`;

const MainWrapper = styled.div`
	position: relative;
	
	display: flex;
	flex-direction: column;
	
	justify-content: flex-start;
	//align-items: center;
	gap: 10px;
	padding: 20px;

	min-height: 400px;
	max-height: 800px;
	min-width: 600px;
	max-width: 600px;
	@media screen and (max-width: 704px) {
		min-width: calc(100% - 10px);
		max-width: calc(100% - 10px);
	}

	background-color: ${props => props.theme.Background};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	
	padding-bottom: 12px;
	border-bottom: 1px dashed ${props => props.theme.TextLight};
`;


const RowRight = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media screen and (max-width: 704px) {
		flex-direction: column;
		gap: 15px;
	}
`;

const Title = styled.p`
	text-align: center;
	color: ${props => props.theme.TextDark};
`;

const HintRow = styled.div`
	flex: 1 0 auto;
	
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 30px;
	padding: 25px 10px;

	border-bottom: 1px dashed ${props => props.theme.TextLight};
`;

const HintColumn = styled.div`
	flex: 1 1 auto;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	
	text-align: justify;
	gap: 20px;
	//padding: 10px;
`;

const Number = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	
	//background: #E7E7E7;
	border-radius: 12px;
	
	font-size: 20px;
	width: 90px;
	height: 28px;
`;

const Text = styled.div`
	flex: 1 0 auto;
	//text-align: center;
	
	color: ${props => props.theme.TextDefault};
`;

const Bold = styled.span`
	font-weight: bold;
	color: ${props => props.theme.TextDark};
`;

const BoldSmall = styled.span`
	font-weight: bold;
	font-size: 11px;
	color: ${props => props.theme.TextDark};
`;

const Spacer = styled.div`
	flex: 1 1 auto;
`;


const FullscreenDownload = ({ fileDescriptors, requestClose }: IProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	function CanDownload(fileType: TypeDownload) : boolean {
		const fileToDownload =
			fileDescriptors.find(fileDescriptor => fileDescriptor.Name === fileType)
		return !!(fileToDownload && fileToDownload.Url !== "");
	}

	function Download(fileType: TypeDownload) : void {
		const fileToDownload =
			fileDescriptors.find(fileDescriptor => fileDescriptor.Name === fileType)
		if (fileToDownload && fileToDownload.Url !== "") {
			window.location.href = fileToDownload.Url;
		}
	}

	return (
		<FullScreenBackground>
			<MainWrapper>
				<Row>
					<Title className={"heading-l"} >{t("DownloadStarted")}</Title>
					{/*<img src={"/assets/navigation-down.svg"} width={"27px"}/>*/}
				</Row>
				<HintRow>
					<HintColumn>
						<Number>.zfab</Number>
						<Text className={"text-l"}>{t("zfabDesc1")}</Text>
						<Text className={"text-l"}>{t("zfabDesc2")} <Bold>CLO® | </Bold><BoldSmall>3D Fashion Design Software</BoldSmall></Text>
						<Spacer/>
						<Button onClick={() => Download("ModelsArchive_zfab")} isRounded={true} caps={false} backgroundColor={"Gray1"} hoverDark={false} color={"TextDark"} label={t("Download")}
								disabled={!CanDownload("ModelsArchive_zfab")}
						></Button>
					</HintColumn>
					<HintColumn>
						<Number>.ase</Number>
						<Text className={"text-l"}>{t("aseDesc1")}</Text>
						<Text className={"text-l"}>{t("aseDesc2")} <Bold>3D</Bold> {t("aseDesc3")} <Bold>Software Adobe</Bold></Text>
						<Spacer/>
						<Button onClick={() => Download("ModelsArchive_ase")} isRounded={true} caps={false} backgroundColor={"Gray1"} hoverDark={false} color={"TextDark"} label={t("Download")}
								disabled={!CanDownload("ModelsArchive_ase")}
						></Button>
					</HintColumn>
					<HintColumn>
						<Number>.U3M</Number>
						<Text className={"text-l"}>{t("u3mDesc1")}</Text>
						<Text className={"text-l"}>{t("u3mDesc2")} <Bold>VStitcher® | </Bold><BoldSmall>Browzwear</BoldSmall></Text>
						<Spacer/>
						<Button onClick={() => Download("ModelsArchive_u3m")} isRounded={true} caps={false} backgroundColor={"Gray1"} hoverDark={false} color={"TextDark"} label={t("Download")}
								disabled={!CanDownload("ModelsArchive_u3m")}
						></Button>
					</HintColumn>
				</HintRow>
				<RowRight>
					<Text className={"text-l"} style={{color: theme.TextLight}}>© EUROJERSEY SPA 2023 Confidential - All Rights Reserved<br/>⚠ {t("fileWarning")}</Text>
					<Button onClick={requestClose} isOutlined={false} backgroundColor={"TextDark"} hoverDark={false} color={"Gray0"} label={t("Close")} ></Button>
				</RowRight>
			</MainWrapper>
		</FullScreenBackground >
	);
};

interface IProps {
	fileDescriptors: FileDescriptor<TypeDownload>[],
	requestClose: () => void,
}

export default FullscreenDownload;