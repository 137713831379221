import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Html5QrcodeScanner, QrcodeErrorCallback, QrcodeSuccessCallback } from "html5-qrcode";
import { Html5QrcodeResult, QrDimensionFunction, QrDimensions } from "html5-qrcode/esm/core";
import { Html5QrcodeScannerStrings } from "html5-qrcode/esm/strings";

import { Button } from "components/button/button";

const qrcodeRegionId = "html5qr-code-full-region";

const FullScreenBackground = styled.div.withConfig({displayName:'FullScreenBackground'})`
	position: absolute;
	inset: 0;
	z-index: var(--z-level-5);

	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba(0, 0, 0, 0.3);
`;

const CloseButtonRow = styled.div`
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	@media screen and (max-width: 704px) {
		padding-bottom: 30px;
	}
`;

const QRCodeReaderContainer = styled.div`
	// Using !important to override/impose current style
	
	background-color: rgb(219, 219, 219);
	border: 3px solid ${props => props.theme.TextLight};
	border-radius: 4px;

	// Hiding the 'info' icon
	& > div:first-child { // No id pr class for identification unfortunately
		& > img:first-of-type { // First (and only) of type of img
			display: none!important;
		}

		// 'Requesting camera permissions...' Or 'NotAllowedError: Permission dismissed'
		#html5qr-code-full-region__header_message {
			font-family: "acumin-pro",sans-serif!important;
			font-size: 14px!important;
			font-style: normal!important;
			font-weight: 400!important;
			line-height: normal!important;
		}
	}
	
	// Scan area
	& #html5qr-code-full-region__scan_region {
		
		align-items: center!important;
		justify-content: center!important;

		// Border around video element
		& > video:first-child {
			width: 100%!important;
			height: 100%!important;
			border: 1px solid ${props => props.theme.TextDark};
			border-radius: 4px;

		}

		// The 'camera style' frame
		& #qr-shaded-region {
			display: none!important;
		}
	}
	
	& > #html5qr-code-full-region__dashboard {
		& > #html5qr-code-full-region__dashboard_section {
			& > div:first-child {
				& > #html5qr-code-full-region__dashboard_section_csr {

					// Request Camera Permission Button
					& > div:first-child {

						// button label
						#html5-qrcode-button-camera-permission { // button
							background-color: ${props => props.theme.Gray5};
							color: ${props => props.theme.Gray0};
							font-family: "acumin-pro",sans-serif!important;
							font-size: 16px!important;
							font-style: normal!important;
							font-weight: 400!important;
							line-height: normal!important;
						}
					}

					// Label and dropdown container
					& > span:nth-child(2) {
						
						margin-left: 10px;

						// Customization of label
						font-family: "acumin-pro",sans-serif!important;
						font-size: 16px!important;
						font-style: normal!important;
						font-weight: 400!important;
						line-height: normal!important;
						
						// Customization of dropdown
						#html5-qrcode-select-camera { // select
							font-family: "acumin-pro",sans-serif!important;
							font-size: 16px!important;
							font-style: normal!important;
							font-weight: 400!important;
							line-height: normal!important;
							appearance: auto!important; // none | auto | textfield
							
							// Customization of options
							& > option {
								font-family: "acumin-pro",sans-serif!important;
								font-size: 16px!important;
								font-style: normal!important;
								font-weight: 400!important;
								line-height: normal!important;
							}
						}
					}
					
					// Button container
					& > span:nth-child(3) {
						
						margin-right: 10px;

						// 'Start Scanning' button label
						#html5-qrcode-button-camera-start { // button
							background-color: ${props => props.theme.Gray5};
							color: ${props => props.theme.Gray0};
							font-family: "acumin-pro",sans-serif!important;
							font-size: 14px!important;
							font-style: normal!important;
							font-weight: 400!important;
							line-height: normal!important;
						}

						// 'Stop Scanning' button label
						#html5-qrcode-button-camera-stop { // button
							background-color: ${props => props.theme.Gray5};
							color: ${props => props.theme.Gray0};
							font-family: "acumin-pro",sans-serif!important;
							font-size: 14px!important;
							font-style: normal!important;
							font-weight: 400!important;
							line-height: normal!important;
						}
					}
				}
			}
			// Hiding the 'Scan an Image File' span
			& > div:nth-child(2) {
				#html5-qrcode-anchor-scan-type-change {
					display: none!important;
				}
			}
		}
	}
`;


const useOutsideClick = (callback: Function) : React.MutableRefObject<HTMLDivElement | null> =>
{
	const ref = React.useRef<HTMLDivElement | null>(null);
	React.useEffect(() =>
	{
		const handleClick = (event: MouseEvent) =>
		{
			if (ref.current && !ref.current.contains(event.target as Node))
			{
				callback();
			}
		}

		document.addEventListener('click', handleClick);
		return () => document.removeEventListener('click', handleClick);
	}, []);
	return ref;
};


export default function DialogQrCode(props: IProps) : JSX.Element {
	const { t } = useTranslation();
	let hideCallback: Function;
	const ref = useOutsideClick(() => hideCallback && hideCallback());

	let html5QrcodeScanner: Html5QrcodeScanner;

	function qrCodeSuccessCallback(decodedText: string, result: Html5QrcodeResult): void {
		html5QrcodeScanner?.pause();
		props.qrCodeSuccessCallback(decodedText, result);
	}

	React.useEffect(() => {
        // when component mounts
        const verbose = props.verbose === true;
        // Suceess callback is required.
        if (!(props.qrCodeSuccessCallback)) {
            throw "qrCodeSuccessCallback is required callback.";
        }
		if (!html5QrcodeScanner) {
			html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, 
			{
				fps: props.fps,
				qrbox: props.qrBox,
				aspectRatio: props.aspectRatio,
				disableFlip: props.disableFlip
			}, verbose);
			html5QrcodeScanner.render(qrCodeSuccessCallback, props.qrCodeErrorCallback);
		}
		else html5QrcodeScanner.resume();

		Html5QrcodeScannerStrings.cameraPermissionTitle = () => t('QrcodeScanner_CameraPermissionTitle');
		Html5QrcodeScannerStrings.cameraPermissionRequesting = () => t('QrcodeScanner_CameraPermissionRequesting');
		Html5QrcodeScannerStrings.selectCamera = () => t('QrcodeScanner_SelectCamera');
		Html5QrcodeScannerStrings.scanButtonScanningStarting = () => t('QrcodeScanner_ScanButtonScanningStarting');
		Html5QrcodeScannerStrings.scanButtonStartScanningText = () => t('QrcodeScanner_StartScanning');
		Html5QrcodeScannerStrings.scanButtonStopScanningText = () => t('QrcodeScanner_StopScanning');

		// using setTimeout here since the callback is assigned and alredy used
		// at the mount of the 'useOutsideClick'
		setTimeout(() => hideCallback = props.hide, 200);

        // cleanup function when component will unmount
        return () => {
            html5QrcodeScanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, []);

	return (
		<FullScreenBackground>
			<CloseButtonRow>
				<Button onClick={() => props.hide()} isOutlined={false} backgroundColor={"Gray5"} hoverDark={false} color={"Gray0"} label={t("Close")} />
			</CloseButtonRow>
			<QRCodeReaderContainer ref={ref} id={qrcodeRegionId} />
		</FullScreenBackground>
	);
};

interface IProps {
	qrCodeSuccessCallback: QrcodeSuccessCallback;
	hide: () => void;
	qrCodeErrorCallback?: QrcodeErrorCallback;

	verbose?: boolean;
	fps?: number;
	qrBox?: number | QrDimensions | QrDimensionFunction;
	aspectRatio?: number;
	disableFlip?: boolean;
}
