import React, {
	useEffect,
	useMemo,
	useState,
} from "react";
import styled from "styled-components";
import { useQuery} from "react-query";
import { useTranslation } from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

import { useDebounce } from "utils/debounce";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/use_BE_API_Private";

import { Header } from "components/header/header";
import { ProductCard } from "./productCard/productCard";
import { ProductCardPlaceholder } from "./productCard/placeholder/placeholder";
import Footer from "components/footer/footer";
import CategoryRow from "./CategoryRow";
import {IResponse} from "../../types/api";
import {
	FileDescriptor,
	ICategory,
	ICategoryPath,
	IGetCategoriesRequest,
	TypeDownload,
	TypeUrl
} from "../../types/categories";
import FullscreenDownload from "../../components/fullscreenDownload/fullscreenDownload";
import useLogout from "../../hooks/useLogout";
import {removeOrderPrefix} from "../../utils/stringUtils";
import DialogAddToCart from "../../components/dialogAddToCart/dialogAddToCart";
import {AxiosResponse} from "axios";
import {ICustomer, IOrderItem} from "../../types/orders";
import {CustomToast} from "../../components/toast/customToast";
import useCart from "../../hooks/useCart";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	height: 100%;
	overflow-y: auto;

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;

const ProductList = styled.div<{numChild: number}>`
	flex: 1 0 auto;
	display: grid;
	gap: 22px;
	//width: 100%;
	min-width: 320px;
	max-width: 1379px;
	padding: 30px 0px;

	grid-template-columns: repeat(4, minmax(307.5px, 1fr));
	max-height: ${props => {
		const rows = Math.ceil(props.numChild / 4);
		return (rows * 272) + (rows - 1) * 22 + 60;
	}}px;

	@media screen and (max-width: 1379px) {
		max-height: ${props => {
			const rows = Math.ceil(props.numChild / 3);
			return (rows * 272) + (rows - 1) * 22 + 60;
		}}px;
		grid-template-columns: repeat(3, minmax(307.5px, 1fr));
	}
	
	@media screen and (max-width: 1041.5px) {
		max-height: ${props => {
			const rows = Math.ceil(props.numChild / 2);
			return (rows * 272) + (rows - 1) * 22 + 60;
		}}px;
		grid-template-columns: repeat(2, minmax(307.5px, 1fr));
	}

	@media screen and (max-width: 704px) {
		max-height: ${props => {
			const rows = Math.ceil(props.numChild / 1);
			return (rows * 272) + (rows - 1) * 22 + 0;
		}}px;
		padding: 0 10px;
		grid-template-columns: repeat(1, minmax(307.5px, 1fr));
	}

	overflow: hidden;
	transition: all 300ms ease-in;

	&.closed {
		padding: 0px;
		max-height: 0px;
	}
`;

const Message = styled.div`
    padding-top: 100px;
    text-align: center;
	color: ${props => props.theme.TextDefault};
`;

const Arrow = styled.div<{ iconUrl: string, className?: string }>`
	//position: absolute;
	position: sticky;
	
	align-self: flex-end;
	//right: 10px;
	
	bottom: 40px;
	@media screen and (max-width: 1041.5px) {
		bottom: 60px;
	}

	@media screen and (max-width: 704px) {
		bottom: 15px;
		margin-bottom: 20px;
	}
	
	cursor: pointer;
	background-color: transparent;

	background-image: url("${props => props.iconUrl}");
	background-repeat: no-repeat;
	background-size: 20px;
	background-position-x: center;
	background-position-y: center;
	
	min-width: 50px;
	width: 50px;
	max-width: 50px;
	
	min-height: 50px;
	height: 50px;
	max-height: 50px;
	
	border-radius: 30px;
	border-inline: 3px solid ${props => props.theme.Gray3};
	border-block: 3px solid ${props => props.theme.Gray3};
	opacity: 0.6;
	
	transition: all 250ms ease-out;
	
	&:hover {
		opacity: 1;
	}
	
	&.disabled {
		opacity: 0.3;
		
	}
`;

/*const SortModeArray = [
	"sortByNameAsc",
	"sortByNameDesc",
	"sortByRecent",
] as const;
export type SortModeType = typeof SortModeArray[number];

const sortModes: { [key in SortModeType]: any } = {
	"sortByRecent": { sortBy: "createdAt", sortDir: "asc" },
	"sortByNameAsc": { sortBy: "name", sortDir: "asc" },
	"sortByNameDesc": { sortBy: "name", sortDir: "desc" },
};*/



//const productPerPage = 30;

export interface CategoryDataWithChildren extends ICategory{
	children?: ICategory[];
}

interface IAddToCartParam {
	name: string,
	categoryId: string;
}

export const Products = ({ searchArticles }: IProps) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const { externalCategoryId } = useParams();

	const { auth } = useAuth();
	const Private_BE_API = useAxiosPrivate();
	const logout = useLogout();

	const { cartContent, setCartContent } = useCart();
	//const [addToCartParam, setAddToCartParam] = useState<IAddToCartParam | null>(null);
	//const [downloadDialogUrl, setDownloadDialogUrl] = useState<string>("");

	const [searchParams, useSearchParam] = useSearchParams();
	/*const sortOptionQueryValue = searchParams.get("sortOption");
	const validateSortOption = (value?: string): SortModeType => {
		return SortModeArray.find(curr => curr === value) ? value as SortModeType : SortModeArray[0];
	};
	const [sortOption, setSortOption] = useState<SortModeType>(sortOptionQueryValue ? validateSortOption(sortOptionQueryValue) : SortModeArray[0]);

	useEffect(() => {
		searchParams.set("sortOption", sortOption);
		useSearchParam(searchParams, { replace: true });
	}, [sortOption]);*/

	const searchValueQueryValue = searchParams.get("searchV");
	const validateSearch = (value?: string): string => {
		return value || "";
	};
	const [searchValue, setSearchValue] = useState<string>(validateSearch(searchValueQueryValue!));
	useEffect(() => {
		searchParams.set("searchV", searchValue);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useSearchParam(searchParams, { replace: true });
	}, [searchValue]);

	useEffect(() => {
		const newSearchParam = validateSearch(searchParams.get("searchV")!);
		if (newSearchParam !== searchValue)
			setSearchValue(newSearchParam);

		/*const newSortOption = validateSortOption(searchParams.get("sortOption")!);
		if (newSortOption !== sortOption)
			setSortOption(newSortOption);*/

		const newPage = validatePageLight(Number(searchParams.get("page")));
		if (newPage !== page)
			setPage(newPage);

	}, [searchParams]);

	const pageQueryValue = searchParams.get("page");
	const validatePageLight = (page: number): number => {
		return !Number.isNaN(page) && page > 0
			? page
			: 1;
	};
	const [page, setPage] = useState<number>(pageQueryValue ? validatePageLight(Number(pageQueryValue)) : 1);
	useEffect(() => {
		searchParams.set("page", page.toString());
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useSearchParam(searchParams, { replace: true });
	}, [page]);


	/*const ref = React.useRef<HTMLDivElement | null>(null);
	useEffect( () => {
		if(searchArticles) {
			document.getElementById("HeaderSearch")?.focus({preventScroll: true});
			console.error("fef");
		}
	}, [searchArticles])*/


	const [openedCategories, setOpenedCategories] = useState<boolean[]>([]);

	const debouncedSearch = useDebounce(searchValue, 500);

	useEffect( () => {
		if(!searchArticles && debouncedSearch.length > 0) {
			navigate(`search?searchV=${debouncedSearch}`);
		}
		else if(searchArticles && (!searchValueQueryValue || searchValueQueryValue.length === 0)){
			//console.error("BACK", searchValue);
			navigate("./..");
		}
	}, [debouncedSearch])

	const CategoriesQuery = async () : Promise<CategoryDataWithChildren | undefined> => {
		try {
			let parentCategoryId = undefined;
			let category : CategoryDataWithChildren = { //Fake category
				"IdCategory": "FakeId", //"9c769658-bd17-47fe-8d90-30e7424f9b34",
				"IdCategoryParent":  "FakeId", //"3cc07c03-9607-4339-9a47-8c17e9dd7768",
				IdReference:  "FakeId", //External id of the client
				"Name":  t("GlobalArticleSearch"), //"Art2",
				"Description":  "FakeId", //"altro nome",
				"ImageUrl":  "FakeId", //"https://fabric-senseir-dev.s3.amazonaws.com/0102e067-70db-4ac3-8992-be5d09513008/categories/image/Daco_4427336_20230411210933475.png",
				"HasChildren": true, //false

				"Path": [], //self included, order: [root -> leaf]
				"HasGrandChildren": false, //
			};

			if(!searchArticles) {
				const {data, status} = await Private_BE_API.get<IResponse<CategoryDataWithChildren>>(`/Fabric/GetCategoryByIdReference/${externalCategoryId}`);
				console.log("categoryByRef:" + JSON.stringify(data, null, 4));

				if(status === 204) {
					navigate("/404");
				}

				parentCategoryId = data.Data.IdCategory;

				category = data.Data;
				category.Name = removeOrderPrefix(category.Name);
			}

			const payloadChildren: IGetCategoriesRequest = {
				IdCategoryParent: searchArticles ? undefined : parentCategoryId,
				Name: debouncedSearch,
				State: "Approved",
				PageFilter: {
					Page: 0,
					Length: 999
				},
				OnlyLeaf: searchArticles
			};


			const {data: dataChildren} = await Private_BE_API.post<IResponse<ICategory[]>>("/Fabric/GetCategories", payloadChildren);
			//const {data} = await Private_BE_API.post<IResponse<CategoryData>>(`/Fabric/GetCategoryByIdReference/${categoryIdReference}`, payloadRoot);


			const childCategories = dataChildren.Data;
			category.children = childCategories;

			for(const childCategory of childCategories)
			//const rootCategory = data.Data
			{
				childCategory.Name = removeOrderPrefix(childCategory.Name);
			}

			//console.log("Categories:" + JSON.stringify(categories, null, 4));
			return category;
		}
		catch (e : any) {
			console.warn("/Fabric/GetCategories returned error:", e);
			if(e.response.status === 401)
			{
				console.error("Token expired: logout");
				logout();
			}

			return undefined;
		}
	};

	const {
		isLoading,
		data: category,
		error,
		isFetching,
	} = useQuery(
		["GetCategories2", searchArticles, auth!.succeeded, page, /*sortModes[sortOption],*/ externalCategoryId, debouncedSearch],
		CategoriesQuery,
		{ enabled: auth?.succeeded, keepPreviousData: true, staleTime: /*10000*/10 }
	);

	useEffect(() => {
		if (category) {
			setOpenedCategories(Array.from({ length: 1/*category.length*/ }).map( _ => true));
		}
	}, [category]);

	const ProductsGrid = useMemo(() => {
		return (
			< >
				{isLoading &&
					<ProductList numChild={8}>
						{Array.from({ length: 8 }).map((_, i) => <ProductCardPlaceholder key={"pp" + i} />)}
					</ProductList>
				}

				{
					!isLoading && (category === undefined || (category.children?.length === 0)) &&
					<Message className="heading-s">{t("EmptyStateProducts")}</Message>
				}

				{ category &&
					[category]?.map((rootCategory, index) => (
					<React.Fragment key={"id_"+rootCategory.IdCategory}>
						{rootCategory?.children?.length && rootCategory?.children?.length > 0 ?
						<>
							<CategoryRow key={"rootCat_"+rootCategory.IdCategory} categoryName={rootCategory.Name}
										 onToggleClick={ (newState) => {
											 //console.log("fawfaw", newState);
											 openedCategories[index] = newState;
											 setOpenedCategories({...openedCategories});
										 }}
										 globalSearch={searchArticles}
							/>
							<ProductList className={(openedCategories[index] === false) ? "closed" : ""} numChild={rootCategory.children.length}>
							{rootCategory.children?.map(product => (
								<ProductCard
									key={"ProductCat_"+product.IdCategory}
									product={product}
									onCardClick={() => {
										searchParams.set("scrollTo", product.IdReference);
										
										// eslint-disable-next-line react-hooks/rules-of-hooks
										useSearchParam(searchParams, { replace: true });
										navigate(`/products/product/${product.IdReference}`);
									}}
									onDownloadClick={(webLink) => {
										/*if(webLink && webLink !== "") {
											setDownloadDialogUrl(webLink);
										}*/
									}}
									onAddedToCartColorFolder={() => {
										//Old behaviour open dialog
										/*setAddToCartParam({
											name: product.Name,
											categoryId: product.IdCategory
										})*/
										const newCartItem : IOrderItem = {
											"Name": product.Name,
											"Note1": "", //Note
											"Note2": "", //PriceNote
											"CutLength": 0,
											"ClothPieceNumber": 0,
											"Folder": true,
											"IdProduct": undefined,
											"IdCategory": product.IdCategory
										}
										cartContent.purchaseOrder.Items.push(newCartItem);
										setCartContent( {...cartContent});

										CustomToast.success({title:t('AddToCart'), msgText:name+" "+t('AddedToCartSuccessfully')}, {autoClose: 700});
									}}
								/>
							))}
							</ProductList>
						</> : <></>
						}
					</React.Fragment>
				))}
			</>
		);}, [i18n.language, isLoading, category, openedCategories]);

	const scrollToQueryValue = searchParams.get("scrollTo");
	useEffect(() => {
		if (category && scrollToQueryValue && scrollToQueryValue !== "") {
			const elementToFocus = document.getElementById(scrollToQueryValue);
			if (elementToFocus) {
				//elementToFocus.scrollIntoView({ block: "center", behavior: "smooth" });
				elementToFocus.scrollIntoView({ block: "center", behavior: "auto" });
			}
		}
	}, [isLoading, category]);

	return (
		<Wrapper>
			<Header onSearchValueChange={setSearchValue} /*crumbs={crumbs}*/ searchValue={searchValue} showSearch autofocus={searchArticles}/>
			{/*<ControlBar isSticky={isSticky} leftSlot={ProductsCount} rightSlot={[FiltersControls, SortControls]} />*/}
			<div id={"scrollTop"} style={{height:"0px"}}/>

			{ProductsGrid}

			<div style={{flex: "1 0 auto"}}></div>
			{/*<Pagination
				currentPage={page}
				totalPages={totalProducts ? Math.ceil(totalProducts / productPerPage) : 1}
				siblingCount={2}
				onChange={setPage}
			/>*/}

			<Arrow /*className={windowsIndex <= 0 ? "disabled" : ""}*/ iconUrl={"/assets/UpArrow2.svg"}
			   onClick={() => {
				   const elementToFocus = document.getElementById("scrollTop");
				   if (elementToFocus) {
					   elementToFocus.scrollIntoView({ block: "center", behavior: "smooth" });
				   }
			   }}></Arrow>


			{/* downloadDialogUrl !== "" &&
				<FullscreenDownload downloadUrl={downloadDialogUrl} requestClose={() => setDownloadDialogUrl("")}/>
			*/}
			{/* addToCartParam &&
				<DialogAddToCart name={addToCartParam.name} categoryId={addToCartParam.categoryId} colorFolderDefault={true} requestClose={() => setAddToCartParam(null)}/>
			*/}
			<Footer marginTop={"0px"}/>
		</Wrapper>
	);
};

interface IProps {
	searchArticles: boolean;
}
