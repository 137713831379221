import React, { useEffect, useState } from "react";
import styled, {useTheme} from "styled-components";

import { useTranslation } from "react-i18next";
import {Button} from "../../components/button/button";
import {IInsertImageRequest, IInsertImageResponse, IPurchaseOrder, IPutPurchaseOrderResponse} from "../../types/orders";
import {AxiosResponse} from "axios";
import {IResponse} from "../../types/api";
import getBase64 from "../../utils/getBase64";
import {CustomToast} from "../../components/toast/customToast";
import {useMutation} from "react-query";
import useAxiosPrivate from "../../hooks/use_BE_API_Private";


const FullScreenBackground = styled.div`
  position: absolute;
	inset: 0;
	z-index: var(--z-level-5);
	
	width: 100%;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//padding: 160px 400px 50px 50px;
	gap: 0px;
	
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
`;

const MainWrapper = styled.div`
	position: relative;
	
	display: flex;
	flex-direction: column;
	
	justify-content: flex-start;
	//align-items: center;
	gap: 10px;
	padding: 20px;

	//min-height: 500px;
	//max-height: 800px;
	min-width: 500px;
	max-width: 500px;
	@media screen and (max-width: 704px) {
		min-width: calc(100% - 10px);
		max-width: calc(100% - 10px);
	}

	background-color: ${props => props.theme.Background};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
`;

const Title = styled.p`
	color: ${props => props.theme.TextDark};
`;

const Label = styled.p`
	color: ${props => props.theme.TextDefault};
`;


const InputRow  = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	gap: 10px;
	min-width: 100%;
	width: 100%;
`;

const InputGroup = styled.div<{width?: string}>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;	
		
	width: 100%;
`;

const ErrorMessageContextual = styled.div<{visible?: boolean}>`
	//display: flex;
	display: ${({ visible }) => visible === false ? 'none' : 'flex'};
	align-items: start;
	width: 100%;

	flex-direction: row;
	gap: 20px;//${({visible}) => visible === false ? '0px' : '20px'};
	justify-content: left;
	color: ${props => props.theme.Negative};
	height: 14px; //${({visible}) => visible === false ? '0px' : '14px'};
	padding-left: 2px;
	padding-bottom: 0px;
`;

const ButtonRow  = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	padding-top: 10px;
	gap: 20px;
	min-width: 100%;
	width: 100%;
`;


const DialogRetryUploadCard = ({ imageRequest, onClose}: IProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const Private_BE_API = useAxiosPrivate();

	const [message, setMessage] = useState<string>(t("RetryUploadDefaultMessage"));

	const CreateQuery = async (payloadInsertImage?: IInsertImageRequest) => {
		let response: AxiosResponse<IResponse<IInsertImageResponse>, any>|null = null;
		try {
			response = await Private_BE_API.post<IResponse<IInsertImageResponse>>("/PurchaseOrder/InsertSingleImage", payloadInsertImage);

			onClose(true);
		} catch (error) {
			setMessage(t("RetryUploadFailed"));
		}
		return null;//response;
	};

	const createMutation = useMutation(CreateQuery);

	const doQueryPurchaseOrder = () => {

		console.log("Retry upload business card");
		createMutation.mutate(imageRequest)
	};

	return (
		<FullScreenBackground>
			<MainWrapper>
				<InputRow>
					<Title className={"heading"} >{"Update business card"}</Title>
				</InputRow>

				<Label className={"heading-s"} >{message}</Label>

				<ButtonRow>
					<Button
						label={t("DiscardCard")}
						backgroundColor={"TextDefault"}
						size={"Compact"}
						overrideWidth={"180px"}
						caps={false}
						color={"Gray0"}
						hoverDark={false}
						onClick={() => onClose(false)}
						isLoading={false}
						isDisabled={false}
					/>
					<Button
						label={t("RetryUpload")}
						backgroundColor={"Positive"}
						size={"Compact"}
						overrideWidth={"180px"}
						caps={false}
						color={"Gray0"}
						hoverDark={false}
						onClick={ doQueryPurchaseOrder}
						isLoading={createMutation.isLoading}
						isDisabled={false}
					/>
				</ButtonRow>
			</MainWrapper>
		</FullScreenBackground >
	);
};

interface IProps {
	imageRequest: IInsertImageRequest;
	onClose: (result: boolean) => void;
}

export default DialogRetryUploadCard;