import { toast, Id, ToastOptions, TypeOptions } from "react-toastify"
import { CloseButtonProps } from "react-toastify/dist/components";
import styled from "styled-components";

const ToastWrapper = styled.div<{bgStyle?: TypeOptions}>`
	display: flex;
	flex-direction: column;
	background: ${props => PickBackgroundColorByType(props.bgStyle)};
`;

const ToastIcon = styled.img`
	width: 34px;
	height: 34px;
	object-fit: cover;
`;

const ToastMessageTitle = styled.div<{txtStyle?: TypeOptions}>`
	font-family: 'Roboto Flex';
	font-style: normal;
	//font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	//margin-bottom: 10px;
	
	width: 93%;
	color: ${props => props.txtStyle === 'info' ? '#333333' : props.theme.TextLight};
`;

const Spacer = styled.div<{pixels: number}>`
	height: ${props => `${props.pixels}px`};// 10px;
`

const ToastMessageContent = styled.span<{txtStyle?: TypeOptions}>`
	font-family: 'Roboto Flex';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;

	width: 93%;
	color: ${props => props.txtStyle === 'info' ? '#333333' : props.theme.TextLight};
`;

function Msg({ title, msgText, options }: IProps & {options: ToastOptions}): JSX.Element {
	return (
		<ToastWrapper bgStyle={options.type}>
			<Spacer pixels={10}/>
				<ToastMessageTitle txtStyle={options.type}>
					{title}
				</ToastMessageTitle>
			<Spacer pixels={10}/>
				<ToastMessageContent txtStyle={options.type}>
					{msgText}
				</ToastMessageContent>
			<Spacer pixels={10}/>
		</ToastWrapper>
	);
};


function CloseButton(closeButtonProps: CloseButtonProps): JSX.Element {
	return (
		<button className="Toastify__close-button Toastify__close-button--colored" type="button" aria-label="close" onClick={closeButtonProps.closeToast}>
			<svg color={PickTextColorByType(closeButtonProps.type)} aria-hidden="true" viewBox="0 0 14 16">
				<path d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"/>
			</svg>
		</button>
	);
}

const kValidTypes = ['info', 'error', 'success'];

// GENERIC
/**
 * 
 * @param essentialProps Title and message
 * @param toastProps Optional toast library options
 * @example
 *  // Other type other then 'info'|'error'|'success' traslated to 'info' type
 *  CustomToast({title:'Title', msgText: 'Unsupported type'}, {autoClose: false, closeOnClick: true, type:'warning'});
 *  CustomToast.info({title:'Title', msgText: 'Message'}, {autoClose: false});
 *  CustomToast.error({title:'Title', msgText: 'Message'}, {autoClose: false, closeOnClick: true});
 *  CustomToast.success({title:'Title', msgText: 'Message'}, {autoClose: false, closeOnClick: true});
 * @returns An Id to maniputale the toast with
 */
export const CustomToast = (essentialProps: IProps, toastProps?: ToastOptions<void>) => {
	toastProps ??= {type:'info'}; // If no toastProps
	toastProps.type ??= 'info';   // If no toastProps.type
	if (!kValidTypes.includes(toastProps.type)) {
		toastProps.type = 'info'; // If no valid toastProps.type
	}
	toastProps.icon = <ToastIcon src={`/assets/icons/${toastProps.type}.svg`} />;
	toastProps.style = {width: '100%', height: '100%'};
	toastProps.style = { background: PickBackgroundColorByType(toastProps.type) };
	toastProps.closeButton = toastProps.closeButton && CloseButton;
	toastProps.pauseOnHover = false; //New default
	toastProps.autoClose = 1000; //New default
	toastProps.closeOnClick = true; //New default
	return toast<void>(<Msg {...essentialProps} options={toastProps} />, { ...toastProps });
};

// SPECIFIC
CustomToast.success = (myProps: IProps, toastProps?: ToastOptions<void>): Id => {
	toastProps ??= {type:'success'}; // If no toastProps
	toastProps.type = 'success';     // Force type
	toastProps.icon = <ToastIcon src={`/assets/icons/success.svg`} />;
	toastProps.style = {width: '100%', height: '100%'};
	toastProps.style = { background: PickBackgroundColorByType(toastProps.type) };
	toastProps.closeButton = toastProps.closeButton && CloseButton;
	toastProps.pauseOnHover = false; //New default
	toastProps.autoClose = 1000; //New default
	toastProps.closeOnClick = true; //New default
	return toast.success<void>(<Msg {...myProps} options={toastProps} />, { ...toastProps });
};

// SPECIFIC
CustomToast.error = (myProps: IProps, toastProps?: ToastOptions<void>): Id => {
	toastProps ??= {type:'error'}; // If no toastProps
	toastProps.type = 'error';     // Force type
	toastProps.icon = <ToastIcon src={`/assets/icons/error.svg`} />;
	toastProps.style = {width: '100%', height: '100%'};
	toastProps.style = { background: PickBackgroundColorByType(toastProps.type) };
	toastProps.closeButton = toastProps.closeButton && CloseButton;
	toastProps.pauseOnHover = false; //New default
	toastProps.autoClose = 3000; //New default
	toastProps.closeOnClick = true; //New default
	return toast.success<void>(<Msg {...myProps} options={toastProps} />, { ...toastProps });
};

// SPECIFIC
CustomToast.info = (myProps: IProps, toastProps?: ToastOptions<void>): Id => {
	toastProps ??= {type:'info'}; // If no toastProps
	toastProps.type = 'info';     // Force type
	toastProps.icon = <ToastIcon src={`/assets/icons/info.svg`} />;
	toastProps.style = {width: '100%', height: '100%'};
	toastProps.style = { background: PickBackgroundColorByType(toastProps.type) };
	toastProps.closeButton = toastProps.closeButton && CloseButton;
	toastProps.pauseOnHover = false; //New default
	toastProps.autoClose = 1000; //New default
	toastProps.closeOnClick = true; //New default
	return toast.success<void>(<Msg {...myProps} options={toastProps} />, { ...toastProps });
};

interface IProps {
	title: string;
	msgText: string;
}


function PickBackgroundColorByType(type?: TypeOptions): string {
	switch (type) {
		case "info": return "#FFFFFF";
		case "error": return "#964141";
		case "success": return "#678C2B";
	}
	return "#FFFFFF";
}

function PickTextColorByType(type?: TypeOptions): string {
	switch (type) {
		case "info": return "#333333";
		case "error": return "#FFFFFF";
		case "success": return "#FFFFFF";
	}
	return "#000000";
}