import React, { useEffect, useState } from "react";
import styled, {useTheme} from "styled-components";

import { useTranslation } from "react-i18next";
import {Button} from "../button/button";
import { InputField } from "../inputField/inputField";

const FullScreenBackground = styled.div`
  position: absolute;
	inset: 0;
	z-index: var(--z-level-5);
	
	width: 100%;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//padding: 160px 400px 50px 50px;
	gap: 0px;
	
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
`;

const MainWrapper = styled.div`
	position: relative;
	
	display: flex;
	flex-direction: column;
	
	justify-content: flex-start;
	//align-items: center;
	gap: 10px;
	padding: 20px;

	//min-height: 500px;
	//max-height: 800px;
	min-width: 500px;
	max-width: 500px;
	@media screen and (max-width: 704px) {
		min-width: calc(100% - 10px);
		max-width: calc(100% - 10px);
	}

	background-color: ${props => props.theme.Background};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
`;

const Title = styled.p`
	color: ${props => props.theme.TextDefault};
`;

const InputRow  = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	gap: 10px;
	min-width: 100%;
	width: 100%;
`;

const InputGroup = styled.div<{width?: string}>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;	
		
	width: 100%;
`;

const ErrorMessageContextual = styled.div<{visible?: boolean}>`
	//display: flex;
	display: ${({ visible }) => visible === false ? 'none' : 'flex'};
	align-items: start;
	width: 100%;

	flex-direction: row;
	gap: 20px;//${({visible}) => visible === false ? '0px' : '20px'};
	justify-content: left;
	color: ${props => props.theme.Negative};
	height: 14px; //${({visible}) => visible === false ? '0px' : '14px'};
	padding-left: 2px;
	padding-bottom: 0px;
`;

const ButtonRow  = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	padding-top: 10px;
	gap: 20px;
	min-width: 100%;
	width: 100%;
`;


const DialogEditNote = ({ dialogTitle, value, onCancelEdit, onNewValue}: IProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const [note, setNote] = useState<string>(value);

	return (
		<FullScreenBackground>
			<MainWrapper>
				<InputRow>
					<InputGroup>
						<Title className={"heading"} >{dialogTitle}</Title>
						<InputField
							size="Default"
							width="100%"
							multiline={true}
							backgroundColor={theme.Gray1}
							value={note}
							onChange={value => setNote(value)}
							placeholder={t("Note")}
							borderRadius={"4px"}
							style={{height: "250px", alignItems: "flex-start"}}
						/>
					</InputGroup>
				</InputRow>

				<ButtonRow>
					<Button
						label={t("Cancel")}
						backgroundColor={"TextDefault"}
						size={"Compact"}
						overrideWidth={"180px"}
						caps={false}
						color={"Gray0"}
						hoverDark={false}
						onClick={onCancelEdit}
						isLoading={false}
						isDisabled={false}
					/>
					<Button
						label={t("Confirm")}
						backgroundColor={"Positive"}
						size={"Compact"}
						overrideWidth={"180px"}
						caps={false}
						color={"Gray0"}
						hoverDark={false}
						onClick={ () => onNewValue(note)}
						isLoading={false}
						isDisabled={false}
					/>
				</ButtonRow>
			</MainWrapper>
		</FullScreenBackground >
	);
};

interface IProps {
	dialogTitle: string,
	value: string,
	onCancelEdit: () => void;
	onNewValue: (newValue: string) => void;
}

export default DialogEditNote;