
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Spinner, SpinnerSizes } from "../spinner/spinner";

const PLACEHOLDER_IMAGE_URL = process.env.PUBLIC_URL + "/assets/missing_img.png";

const Wrapper = styled.div<any>`
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
`;

const Img = styled.img<any>`
	width: 100%;
	height: 100%;
	object-fit: cover;
	margin: 0;
	padding: 0;
`;

// eslint-disable-next-line react/display-name
/*export const PictureRef = React.forwardRef<HTMLPictureElement, PictureProps>((props, ref) => {

   const { src, alt, style, ...others } = props;

   return (
      <picture>
         <Img ref={ref} src={src} alt={alt} style={style} {...others} />
      </picture>
   );
});*/

export const Picture = ({ src, alt, style, usePlaceholder = true, useSpinner = true, spinnerSize = "Default", ...others }: PictureProps) => {

	const [imageLoaded, setImageLoaded] = useState<boolean>(false);
	const [loadError, setLoadError] = useState<boolean>(false);

	useEffect(() => {
		if (src) {
			const img = new Image();

			setLoadError(false);

			img.onload = () => {
				//console.log("Image loaded");
				setImageLoaded(true);
			};
			img.onerror = () => {
				console.log("Image load error");
				setLoadError(true);
			};

			img.src = src;
		} else {
			setImageLoaded(true);
			setLoadError(true);
		}
	}, [src]);

	return (
		<picture>
			{
				(imageLoaded || loadError) ?
					<Img src={!loadError ? src : PLACEHOLDER_IMAGE_URL} alt={alt} style={style} {...others} />
					: <Spinner size={spinnerSize} />
			}
		</picture>
	);
};

interface PictureProps {
	src: string;
	alt?: string;
	usePlaceholder?: boolean,
	useSpinner?: boolean,
	style?: React.CSSProperties;
	spinnerSize? : SpinnerSizes,
	[key: string]: any;
}

export default Picture;