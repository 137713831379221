import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { Box3, PerspectiveCamera as PerspectiveCameraType, Quaternion, Vector3 } from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { OrbitControls as OrbitControlsType } from "three-stdlib";


import {
	expandByVisibleObjects, fEnoughDifferent,
	fInterpTo, fNearlyEqual,
	qInterpTo, qNearlyEqual, vEnoughDifferent,
	vInterpTo,
	vNearlyEqual
} from "utils/threeUtils";
import useEvent from "hooks/useEvent";


interface ILocalCameraInfo {
	position: Vector3,
	quaternion: Quaternion,
	fov: number
}

interface IControlsReplication {
	azimuth: number, //theta
	polar: number,
	distance: number,
	remoteTarget: Vector3,
	fov: number
}

const replicationIntervalS = 1 / 20;
const interpolationSpeed = 25;

function CameraControls ({
	controlId,
	cameras,
	selectedCameraName,
	enableZoom = true,
	nearPlane,
	farPlane,
	minDistance,
	maxDistance,
	cursorId,
	intersectGroup,
	zoomValue
}: IProps) {

	const { scene } = useThree();
	const [target, setTarget] = useState(new Vector3());

	//Real used camera and controls refs
	const cameraRef = useRef<PerspectiveCameraType | undefined>();
	const controlsRef = useRef<OrbitControlsType | null>(null);


	//Updated when selectedCameraName changes
	const [selectedCameraTargetClone, setSelectedCameraTargetClone] = useState<PerspectiveCameraType>(getSelectedCamera());

	//Local controlled camera target, not directly set above camera state so we can interpolate to
	//Local unlocked or guide data
	const [currentLocalCameraPosition, setCurrentLocalCameraPosition] = useState<ILocalCameraInfo>({
		position: selectedCameraTargetClone.position.clone(),
		quaternion: selectedCameraTargetClone.quaternion.clone(),
		fov: selectedCameraTargetClone.fov
	});
	const achievedlocalSelectedCameraTarget = useRef<boolean>(false);

	const zoomRef = useRef<number>(zoomValue);

	function getSelectedCamera () {
		const camera = cameras?.find(cam => cam.name === selectedCameraName);

		if (camera)
			return camera as PerspectiveCameraType;

		const defaultCamera = new THREE.PerspectiveCamera(50, 1, 0.01, 50);
		defaultCamera.name = "camera_default";
		defaultCamera.position.set(0.5, 0.5, 2);
		defaultCamera.updateProjectionMatrix();

		return defaultCamera;
	}

	useEffect(() => {
		if (controlsRef.current && cameraRef.current) {

			const zoomParam = 1.2;
			const actualZoomParam = zoomValue <= zoomRef.current ? zoomParam : -zoomParam;
			zoomRef.current = zoomValue;
			const minDistance = controlsRef.current.minDistance;

			const distanceCamTargetPoint = cameraRef.current.position.distanceTo(controlsRef.current.target);
			const newDistance = distanceCamTargetPoint + actualZoomParam;
			//console.log(newDistance, minDistance, actualZoomParam);

			//Enforce min distance, maxDistance is auto enforced
			if (newDistance >= minDistance) {
				const lookAtVector = cameraRef.current.position.clone().sub(controlsRef.current.target).normalize().multiplyScalar(actualZoomParam);
				const newPosition = cameraRef.current.position.clone().add(lookAtVector);

				setCurrentLocalCameraPosition({
					position: newPosition,
					quaternion: cameraRef.current.quaternion.clone(),
					fov: cameraRef.current.fov
				});

			}
		}
	}, [zoomValue]);

	useEffect(() => {
		const camera = getSelectedCamera();
		camera.updateProjectionMatrix();

		const cameraClone = camera.clone();
		cameraClone.near = selectedCameraTargetClone.near;
		cameraClone.far = selectedCameraTargetClone.far;

		setSelectedCameraTargetClone(cameraClone);

		setCurrentLocalCameraPosition({
			position: camera.position.clone(),
			quaternion: camera.quaternion.clone(),
			fov: camera.fov
		});
		achievedlocalSelectedCameraTarget.current = false;

	}, [controlId, cameras]);

	//only camera name change
	useEffect(() => {
		const camera = getSelectedCamera();

		setSelectedCameraTargetClone(camera.clone());
		achievedlocalSelectedCameraTarget.current = false;

	}, [selectedCameraName]);

	return (
		<>
			<PerspectiveCamera
				makeDefault
				name={selectedCameraName}
				position={currentLocalCameraPosition.position}
				quaternion={currentLocalCameraPosition.quaternion}
				fov={currentLocalCameraPosition.fov}
				near={nearPlane}
				far={farPlane}
				ref={cameraRef}
			/>
			<OrbitControls
				/* makeDefault */
				ref={controlsRef}
				enableDamping
				dampingFactor={0.1}
				rotateSpeed={0.5}
				enableZoom={enableZoom}
				enablePan={true}
				minPolarAngle={0.01}
				maxPolarAngle={3.14 / 2}
				minDistance={minDistance}
				maxDistance={maxDistance}
				target={target}
				enabled={true} //disabled will not update target and other info
			/>
		</>
	);
}

interface IProps {
	controlId: string,
	cameras?: THREE.PerspectiveCamera[],
	selectedCameraName?: string,
	enableZoom?: boolean,
	nearPlane: number,
	farPlane: number,
	minDistance: number,
	maxDistance: number,
	cursorId: string
	intersectGroup?: THREE.Group
	zoomValue: number
}

export default CameraControls;
