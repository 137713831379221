import React, { useId } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Label = styled.label`
	color: ${props => (props.color ? props.color : props.theme.TextDefault)};
`;

const Checkbox = styled.input`
	width: 20px;
	height: 20px;

	margin: 0px;

	background-color: ${props => props.theme.Gray1};
	accent-color: ${props => props.theme.TextDark};

	&input[type=checkbox]:checked:before {
		background-color: #FFFFFF;
	}
`;

export const Toggle = ({
						   checked,
						   onChange,
						   label,
						   labelPosition = "left",
						   labelColor,
						   enabled = true
					   }: IProps) => {
	const id = useId();

	return (
		<Wrapper>
			{label && labelPosition === "left" ?
				<Label style={{paddingRight: "5px"}} className={"ptext-l"} htmlFor={id} color={labelColor}>{label}</Label>
				: null}
			<Checkbox type={"checkbox"}
					  id={id}
					  checked={checked}
					  style={{cursor: enabled ? "pointer": "auto"}}
					  onChange={e => {
						  if(enabled) {
							  onChange != null && onChange(e.target.checked)
						  }
					  }}
			/>
			{label && labelPosition === "right" ?
				<Label style={{paddingLeft: "5px"}} className={"ptext-l"} htmlFor={id} color={labelColor}>{label}</Label>
				: null}
		</Wrapper>
	);
};

interface IProps {
	checked: boolean,
	onChange: (value: boolean) => void,
	label?: string,
	labelPosition?: "left" | "right",
	labelColor?: string
	enabled?: boolean
}
