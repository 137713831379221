import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import useAuth from "./hooks/useAuth";
import { App } from "./app";
import { Login, Products, Product, Page404 } from "./pages";
import {RequestAccess} from "./pages/requestAccess/requestAccess";
import {GrantAccess} from "./pages/grantAccess/grantAccess";
import {CookiesPolicy} from "./pages/Policies/CookiesPolicy";
import {PrivacyPolicy} from "./pages/Policies/PrivacyPolicy";
import ResetPassword from "pages/resetPassword/resetPassword";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import ChangePassword from "pages/changePassword/changePassword";
import {Categories} from "./pages/categories/categories";
import {Cart} from "./pages/cart/cart";


function ProtectedRoute ({ outlet }: { outlet: JSX.Element }) {

	const { auth } = useAuth();
	const location = useLocation();

	if (auth?.succeeded)
		return outlet;

	return <Navigate to={{ pathname: "/" }} replace state={location} />;
};

function RouterPage () {
	return (
		<Routes>
			<Route path="/" element={<App />}>
				<Route index element={<Login />} />
				<Route path="login" element={<Login />} />
				<Route path="requestAccess" element={<RequestAccess/>} />
				<Route path="grantAccess/:code" element={<GrantAccess/>} />

				<Route path="forgotPassword"  element={<ForgotPassword/>} />
				<Route path="resetPassword/:code" element={<ResetPassword/>} />

				<Route path="cookiesPolicy" element={<CookiesPolicy/>} />
				<Route path="privacyPolicy" element={<PrivacyPolicy/>} />

				<Route path="changePassword" element={< ProtectedRoute outlet={<ChangePassword />} />} />

				<Route path="categories" element={< ProtectedRoute outlet={<Categories />} />} />
				<Route path="categories/:externalCategoryId" element={< ProtectedRoute outlet={<Categories />} />} />
				<Route path="products/:externalCategoryId" element={< ProtectedRoute outlet={<Products searchArticles={false}/>} />} />
				<Route path="products/product/:externalProductId" element={<ProtectedRoute outlet={<Product />} />} />
				<Route path="cart" element={<ProtectedRoute outlet={<Cart />} />} />

				<Route path="categories/search" element={< ProtectedRoute outlet={<Products searchArticles={true}/>} />} />
				<Route path="categories/:externalCategoryId/search" element={< ProtectedRoute outlet={<Products searchArticles={true}/>} />} />
				<Route path="products/:externalCategoryId/search" element={< ProtectedRoute outlet={<Products searchArticles={true}/>} />} />
				<Route path="products/product/:externalProductId/search" element={<ProtectedRoute outlet={<Products searchArticles={true}/>} />} />
				<Route path="cart/search" element={<ProtectedRoute outlet={<Products searchArticles={true}/>} />} />

				<Route path="*" element={<Page404 />} />
			</Route>
		</Routes>
	);
}

export default RouterPage;
