import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import { Logo } from "../logo/logo";
import { Icon } from "../icon/icon";
import { /*Breadcrumbs,*/ ICrumb } from "components/breadcrumbs/breadcrumbs";
import { InputField } from "../inputField/inputField";
import useLogout from "hooks/useLogout";
import {useTranslation} from "react-i18next";
import useCart from "../../hooks/useCart";
import DialogQrCode from "components/dialogQrCode/dialogQrCode";
import { Html5QrcodeResult } from "html5-qrcode";
import { URLValidation } from "utils/stringUtils";
import useCanShowCart from "../../hooks/useCanShowCart";
import Settings from "../../configs/settings";
import useCanShowDashboardLink from "../../hooks/useCanShowDashboardLink";

const Wrapper = styled.div`
	position: sticky;
	top: 0px;
	z-index: var(--z-level-3);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-height: 80px;
	min-height: 80px;
	padding: 0 30px;
	@media screen and (max-width: 704px) {
		padding: 0 15px;
	}
	
	background-color: ${({ theme }) => theme.Background};
	//border-bottom: 1px solid ${({ theme }) => theme.Gray1};

	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
`;
const Content = styled.div`
	display: flex;
	gap: 20px;
	width: 100%;
	height: 100%;
	max-width: 1200px;
	align-items: center;
	justify-content: space-between;

	& > div {
		display: flex;
		flex: 1 1 auto;

		width: 33%;

		&:nth-child(2) {
			align-items: center;
			justify-content: center;
		}

		@media screen and (max-width: 704px) {
			//width: unset;
			//gap: 5px;
			
			&:first-child {
				display: none;
			}
			
			&:nth-child(2) {
				flex: 1 1 auto;
				justify-content: flex-start;
			}

			&:nth-child(3) {
				flex: 1 0 auto;
				justify-content: flex-start;
			}
		}
	}
`;

const IconButton = styled.div`
	position: relative;
	cursor: pointer;
	margin-left: 10px;
	stroke: ${props => props.theme.TextDefault};
	
	& > div {
		position: relative;
		transition: bottom linear 200ms;
		bottom: 0px;
	}
	
	&:hover > div {
		bottom: 4px;
	}
`;

const SlideMenu = styled.div<{canShowCart: boolean}>`
	position: absolute;
	top: 65px;
	z-index: var(--z-level-4);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	
	width: 240px;
	padding: 20px 20px 30px 20px;

	border: 2px solid #F5F5F5;
	box-shadow: 0px 4px 6px 0px #00000040;
	border-radius: 8px;
	
	background-color: ${({ theme }) => theme.Background};
	border-bottom: 1px solid ${({ theme }) => theme.Gray1};
	
	max-height: ${props => props.canShowCart ? "310px": "280px"};
	overflow: hidden;
	transition: all 300ms ease-in;
	
	&.closed {
		padding: 0px 20px 0px 20px;
		max-height: 0px;
		border: 0px solid ${({ theme }) => theme.Gray1};
	}
`;

const IconImage = styled.div<{src: string}>`
	width: 24px;
	height: 24px;

	background-image: url("${props => props.src}");
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: center;
	background-position-y: center;
`;

const IconBadge = styled.div`
	position: absolute;
	top: -12px;
	
	width: 12px;
	height: 12px;
	border-radius: 100%;
	
	background-color: #FF0404;
	
	display: flex;
	justify-content: center;
	align-items: center;
`;

const IconBadgeText = styled.span`
	font-size: 11px;
	line-height: 11px;
	color: white;
`;

const MenuEntry = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 0px;
	padding-top: 10px;
	
	user-select: none;
	
	transition: all ease-out 300ms;
	stroke: ${props => props.theme.TextDefault};
	color: ${props => props.theme.TextDefault};
`;

const SelectableEntry = styled(MenuEntry)`
	
	cursor: pointer;
	
	transition: all ease-out 300ms;
	stroke: ${props => props.theme.TextDefault};
	color: ${props => props.theme.TextDefault};

	&:hover {
		stroke: ${props => props.theme.Gray5};
		color: ${props => props.theme.Gray5};
	}
`;

const Language = styled.div`
	color: ${props => props.theme.TextLight} !important;
	
	&.selected {
		color: ${props => props.theme.TextDark} !important;
	}

	&:hover {
		stroke: ${props => props.theme.Gray5} !important;
		color: ${props => props.theme.Gray5} !important;
	}
`;

const Point = styled.div`
	width: 8px;
	height: 8px;
	margin-left: 10px;
	margin-right: 4px;
	
	background: ${({ theme }) => theme.TextDark};
	border-radius: 4px;
`;

const Line = styled.div`
	width: 100%;
	height: 1px;
	margin-top: 2px;
	
	background: ${({ theme }) => theme.TextLight};
	border-radius: 4px;
`;

export const Header = ({
	showSearch = false,
	autofocus = false,
	onSearchValueChange,
	searchValue = "",
	crumbs,
}: IProps) => {

	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();
	const logout = useLogout();

	const [showMenu, setShowMenu] = useState<boolean>(false);
	const [showQrCodeReader, setShowQrCodeReader] = useState<boolean>(false);

	const goHome = () => {
		navigate("/categories");
	};

	function isLang(code: string) : boolean {
		return i18n.language === code;
	}

	const { cartContent, setCartContent } = useCart();
	const canShowCart = useCanShowCart();
	const canShowDashboardLink = useCanShowDashboardLink();

	const onNewQRCodeScanResult = (decodedText: string, _: Html5QrcodeResult) => {
		if (URLValidation(decodedText))
		{
			setShowQrCodeReader(false);
			if (!decodedText.startsWith("http")) decodedText = `https://${decodedText}`;
			window.location.href = decodedText;
		}
		else console.error(`Invalid url from qrCode: '${decodedText}'`);
    };

	return (
		<>
			<Wrapper>
				<Content>
					<div onClick={goHome} style={{ cursor: "pointer" }}>
						<Logo style={{ height: "70px", maxHeight: "70px", objectFit: "scale-down" }} />
					</div>
					<div style={{zIndex: 5}}>
						{/*crumbs && <Breadcrumbs crumbs={crumbs} />*/}
						{showSearch &&
						  	<InputField
								id={"HeaderSearch"}
								autoFocus={autofocus}
								value={searchValue}
								onChange={onSearchValueChange}
								rightIcon={searchValue.length > 0 ? "cross" : "lens"}
								onRightIconClick={() => onSearchValueChange?.("")}
								iconSizeOverride={"21px"}
								width="max(100%, 400px)"
								size="Default"
								backgroundColor={theme.Gray1}
								placeholder={t("SearchProductsPlaceholder")}
								disabled={false}
							/>}
					</div>
					<div style={{ justifyContent: "flex-end" }}>
						{/*<IconButton onClick={() => {window.location.href="https://www.facebook.com/sensitive.fabrics/";}}>
							<IconImage src={"/assets/facebook.png"} />
						</IconButton>*/}
						{canShowCart &&
							<IconButton onClick={() => setShowQrCodeReader(true)}>
								<IconImage src={"/assets/qrCode.svg"}/>
							</IconButton>
						}
						{ canShowCart &&
							<IconButton onClick={() => navigate("/cart")}>
								<IconImage src={"/assets/cart.svg"}/>
								{cartContent.purchaseOrder.Items.length > 0 &&
									<IconBadge><IconBadgeText>{cartContent.purchaseOrder.Items.length}</IconBadgeText></IconBadge>
								}
							</IconButton>
						}
						{ !canShowCart &&
							<IconButton onClick={() => {
								window.location.href = "https://www.facebook.com/sensitive.fabrics/";
							}}>
								<IconImage src={"/assets/facebook.png"}/>
							</IconButton>
						}
						{!canShowCart &&
							<IconButton onClick={() => {
								window.location.href = "https://www.instagram.com/sensitivefabrics/?hl=it";
							}}>
								<IconImage src={"/assets/instagram.png"}/>
							</IconButton>
						}
						<IconButton onClick={() => {setShowMenu(!showMenu); }}>
							<div>
								<Icon name="menu" size="24" colorOverride={theme.Gray5} />
							</div>
						</IconButton>

							<SlideMenu className={showMenu ? "" : "closed"} canShowCart={canShowCart}>
								<MenuEntry style={{cursor: "auto",}}>
									<img src={"/assets/messages-bubble-settings-1.svg"} width="20px" height="20px"/>
									<div style={{width: "10px"}}></div>
									<span className="heading-xs caps" onClick={() => {
									}}>{t("Language")}</span>
								</MenuEntry>
								<Line/>
								<SelectableEntry onClick={() => {
									i18n.changeLanguage("it");
								}}>
									<Point style={{visibility: isLang("it") ? undefined : "hidden"}}></Point>
									<Language
										className={`text-l caps ${isLang("it") ? "selected" : ""}`}>{t("Italian")}</Language>
								</SelectableEntry>
								<SelectableEntry onClick={() => {
									i18n.changeLanguage("en");
								}}>
									<Point style={{visibility: isLang("en") ? undefined : "hidden"}}></Point>
									<Language
										className={`text-l caps ${isLang("en") ? "selected" : ""}`}>{t("English")}</Language>
								</SelectableEntry>

								<SelectableEntry style={{paddingTop: "20px"}} onClick={_ => navigate('/changePassword')}>
									<img src={"/assets/gears.svg"} width="20px" height="20px"/>

									<div style={{width: "10px"}}></div>
									<span className="heading-xs caps" onClick={() => {
									}}>{t("ChangePassword")}</span>
								</SelectableEntry>
								<Line/>

								{canShowDashboardLink &&
									<>
										<SelectableEntry style={{paddingTop: "20px"}}
														 onClick={() => {window.location.href = Settings.backofficeUrl}}>
											<img src={"/assets/backoffice.svg"} width="20px" height="20px"/>

											<div style={{width: "10px"}}></div>
											<span className="heading-xs caps" onClick={() => {
											}}>{t("GotoBackoffice")}</span>
										</SelectableEntry>
										<Line/>
									</>
								}

								<SelectableEntry style={{paddingTop: "20px"}} onClick={(e) => {
									window.location.href = "mailto:digitallibrary@eurojersey.it";
									e.preventDefault();
								}}>
									<img src={"/assets/question-help-message.svg"} width="20px" height="20px"/>

									<div style={{width: "10px"}}></div>
									<span className="heading-xs caps" onClick={() => {
									}}>{t("BookADemo")}</span>
								</SelectableEntry>
								<Line/>


								<SelectableEntry style={{paddingTop: "20px"}} onClick={logout}>
									<img src={"/assets/logout.svg"} width="20px" height="20px"/>
									<div style={{width: "10px"}}></div>
									<span className="heading-xs caps" onClick={() => {
									}}>{t("Logout")}</span>
								</SelectableEntry>
							</SlideMenu>
					</div>
				</Content>
			</Wrapper>

			{showQrCodeReader && <DialogQrCode
				qrCodeSuccessCallback={onNewQRCodeScanResult}
				hide={() => setShowQrCodeReader(false)}
				fps={10}
                qrBox={{width: 330, height: 330}}
                //disableFlip={false}
			/>}
		</>
	);
};

interface IProps {
	showSearch?: boolean;
	autofocus?: boolean;
	showFavorites?: boolean;
	onSearchValueChange?: (value: string) => void;
	searchValue?: string;
	crumbs?: ICrumb[]
}