
import { Box3, Quaternion, Vector3 } from "three";
import { Camera, Material, Mesh, MeshPhysicalMaterial, MeshStandardMaterial, Object3D, PerspectiveCamera } from "three/src/Three";

export const PRECISIONVECTOR = 0.0001;
export const PRECISIONQUAT = 0.001;

export const fInterpTo = (current: number, target: number, deltaTimeS: number, interpSpeed: number) => {

	// If no interp speed, jump to target value
	if (interpSpeed <= 0) {
		return target;
	}

	// Distance to reach
	const dist = target - current;

	// If distance is too small, just set the desired location
	if (Math.sqrt(dist) < PRECISIONVECTOR) {
		return target;
	}

	// Delta Move, Clamp so we do not over shoot.
	const deltaMove = dist * clamp(deltaTimeS * interpSpeed, 0, 1);

	return current + deltaMove;
};

export const vInterpTo = (current: Vector3, target: Vector3, deltaTimeS: number, interpSpeed: number): Vector3 => {

	// If no interp speed, jump to target value
	if (interpSpeed <= 0) {
		return target;
	}

	// Distance to reach
	const dist: Vector3 = target.clone().sub(current);

	// If distance is too small, just set the desired location
	if (dist.lengthSq() < PRECISIONVECTOR) {
		return target;
	}

	// Delta Move, Clamp so we do not over shoot.
	const delta = clamp(deltaTimeS * interpSpeed, 0, 1);
	const deltaMove = dist.multiplyScalar(delta);

	return deltaMove.add(current);
};

export const qInterpTo = (current: Quaternion, target: Quaternion, deltaTimeS: number, interpSpeed: number): Quaternion => {

	// If no interp speed, jump to target value
	if (interpSpeed <= 0) {
		return target;
	}

	if (qNearlyEqual(current, target)) {
		return target;
	}

	return SphericalLerp(current, target, clamp(interpSpeed * deltaTimeS, 0, 1)).normalize();
};

export const SphericalLerp = (current: Quaternion, target: Quaternion, sLerp: number): Quaternion => {

	// Get cosine of angle between quats.
	const rawCosom: number =
		current.x * target.x +
		current.y * target.y +
		current.z * target.z +
		current.w * target.w;

	// Unaligned quats - compensate, results in taking shorter route.
	const cosom: number = rawCosom >= 0 ? rawCosom : -rawCosom;

	let scale0: number;
	let scale1: number;

	if (cosom < 0.9999) {
		const omega = Math.acos(cosom);
		const invSin = 1 / Math.sin(omega);

		scale0 = Math.sin((1 - sLerp) * omega) * invSin;
		scale1 = Math.sin(sLerp * omega) * invSin;
	}
	else {
		// Use linear interpolation.
		scale0 = 1 - sLerp;
		scale1 = sLerp;
	}

	// In keeping with our flipped Cosom:
	scale1 = rawCosom >= 0 ? scale1 : -scale1;

	const result: Quaternion = new Quaternion(
		scale0 * current.x + scale1 * target.x,
		scale0 * current.y + scale1 * target.y,
		scale0 * current.z + scale1 * target.z,
		scale0 * current.w + scale1 * target.w
	);

	return result;
};

export const fNearlyEqual = (a: number, b: number, precision: number = PRECISIONVECTOR) => {
	return Math.abs(a - b) < precision;
};
export const fEnoughDifferent = (a: number, b: number, precision: number = PRECISIONVECTOR): boolean => {
	return !fNearlyEqual(a, b, precision);
};

export const vNearlyEqual = (a: Vector3, b: Vector3, precision: number = PRECISIONVECTOR) => {
	return Math.abs(a.x - b.x) < precision
		&& Math.abs(a.y - b.y) < precision
		&& Math.abs(a.z - b.z) < precision;
};
export const vEnoughDifferent = (a: Vector3, b: Vector3, precision: number = PRECISIONVECTOR) => {
	return !vNearlyEqual(a, b, precision);
};

export const qNearlyEqual = (a: Quaternion, b: Quaternion, precision: number = PRECISIONQUAT) => {
	return Math.abs(a.x - b.x) < precision
		&& Math.abs(a.y - b.y) < precision
		&& Math.abs(a.z - b.z) < precision
		&& Math.abs(a.w - b.w) < precision;
};
export const qEnoughDifferent = (a: Quaternion, b: Quaternion, precision: number = PRECISIONQUAT) => {
	return !qNearlyEqual(a, b, precision);
};

export const clamp = (num: number, min: number, max: number) => {
	return Math.min(Math.max(num, min), max);
};

// COPYED from original box function expandByObject and added the visibility filter,
//since if we add a mesh bbox at a time it won't add them correctly first load
export function expandByVisibleObjects (box: Box3, object: any, precise = false, keepNamesStartsWith: string[] = [], skipNamesStartsWith: string[] = []) {

	const _vector = new Vector3();
	const _box = new Box3();

	// Computes the world-axis-aligned bounding box of an object (including its children),
	// accounting for both the object's, and children's, world transforms

	const skipElement: boolean = skipNamesStartsWith.length > 0 && skipNamesStartsWith.filter(prefix => object.name.toLowerCase().startsWith(prefix)).length > 0;
	const keepElement: boolean = keepNamesStartsWith.length === 0 || keepNamesStartsWith.filter(prefix => object.name.toLowerCase().startsWith(prefix)).length > 0;
	//console.log("object: '"+ object.name + "'", object.type, keepElement, skipElement);

	if (object.visible && !skipElement && keepElement) {

		object.updateWorldMatrix(false, false);

		const geometry = object.geometry;

		if (geometry !== undefined) {

			if (precise && geometry.attributes !== undefined && geometry.attributes.position !== undefined) {

				const position = geometry.attributes.position;
				for (let i = 0, l = position.count; i < l; i++) {

					_vector.fromBufferAttribute(position, i).applyMatrix4(object.matrixWorld);
					box.expandByPoint(_vector);

				}

			} else {

				if (geometry.boundingBox === null) {

					geometry.computeBoundingBox();
				}

				_box.copy(geometry.boundingBox);
				_box.applyMatrix4(object.matrixWorld);

				//console.log("box partial:", _box);
				box.union(_box);

			}

		}
		//console.log("box total:", JSON.stringify(box, null, 4));
	}

	//console.log("box:", box);

	const children = object.children;

	for (let i = 0, l = children.length; i < l; i++) {
		const child = children[i];
		//console.log("child: " +  child.name);

		if (child.visible) {
			expandByVisibleObjects(box, child, precise, keepNamesStartsWith, skipNamesStartsWith);
		}
	}

}

export function object3DIsMesh (object3D: Object3D): object3D is Mesh {
	return object3D.type === "Mesh";
}

export function materialIsStandard (material: Material): material is MeshStandardMaterial {
	return material.type === "MeshStandardMaterial";
};

export function materialIsPhysical (material: Material): material is MeshPhysicalMaterial {
	return material.type === "MeshPhysicalMaterial";
};

export function cameraIsPerspective (camera: Camera): camera is PerspectiveCamera {
	return camera.type === "PerspectiveCamera";
}