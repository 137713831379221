import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import dayjs from "dayjs";
// need to check if we can optimize this by importing it at runtime based on locale
import "dayjs/locale/it";
import "dayjs/locale/en";
import it from "date-fns/locale/it";
import en from "date-fns/locale/en-US";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import translationEng from "./locales/en/translation.json";
import translationIt from "./locales/it/translation.json";

const options = {
	order: ["querystring", "navigator"],
	lookupQuerystring: "lng",
};

i18n
	// .use(XHR)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: false,
		// lng: "en",
		detection: options,
		fallbackLng: "en", // use en if detected lng is not available
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false, // react already safes from xss
			formatSeparator: ",",
			format: function (value, formatting) {
				dayjs.locale(i18n.language);
				if (value instanceof Date) return dayjs(value).format(formatting);
				return value.toString();
			}
		},
		supportedLngs: ["en", "it"],
		resources: {
			en: {
				translations: translationEng
			},
			it: {
				translations: translationIt
			},
		},
		// have a common namespace used around the full app
		ns: ["translations"],
		defaultNS: "translations"
	});

registerLocale("it", it);
registerLocale("en", en);
setDefaultLocale(i18n.language);
dayjs.locale(i18n.language);

export default i18n;