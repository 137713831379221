import React, {
    useEffect, useMemo,
    useState,
} from "react";
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import {Toggle} from "../../components/toggle/toggle";
import {ComboBox} from "../../components/comboBox/comboBox";
import chroma from "chroma-js";
import useCart from "../../hooks/useCart";
import {IOrderItem} from "../../types/orders";
import DialogEditNote from "../../components/dialogEditNote/dialogEditNote";
import {Button} from "../../components/button/button";
import DialogAddToCart from "../../components/dialogAddToCart/dialogAddToCart";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;

	max-width: 100%;
	width: 100%;
	height: 100%;

	//background-color: #EEE;
    &.border {
		border-left: 1px solid #999;
    }
    
	padding: 12px 18px 15px 10px;
	@media screen and (max-width: 1000px) {
		padding: 0px;
	}
	//border-radius: 4px;

	/*overflow-y: auto;
	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}*/
`;

const Table = styled.table`
	//min-width: 100%;
	width: 100%;
`;

const TR = styled.tr`
	background-color: #FFF;

	&:nth-child(even) {
		background-color: ${props => chroma("#FFF").darken(0.5).css()};
	}

	&:hover {
		background-color: ${props => chroma("#FFF").darken(0.8).css()};
	}
`;

const TH = styled.th`
	color: ${({theme}) => theme.TextDark};
	padding: 5px 10px;
	@media screen and (max-width: 1000px) {
		padding: 5px 2px;
	}

	text-align: left;
	border-bottom: 1px solid #666;
`;

const THCenter = styled.th`
	color: ${({theme}) => theme.TextDark};
	padding: 5px 10px;

	text-align: center;
	border-bottom: 1px solid #666;
`;

const TD = styled.td`
	color: ${({theme}) => theme.TextDefault};
	padding: 5px 10px;
	//line-height: normal;
`;

const TDCenter = styled.td`
	color: ${({theme}) => theme.TextDefault};
	padding: 2px 2px;

	text-align: center;
	vertical-align: middle;

`;

const SummaryRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
	border-top: 1px solid #999;
    
    padding-top: 10px;
	width: 100%;
`;

const Label = styled.p`
	color: ${props => props.theme.TextDefault};
`;

const ButtonRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	gap: 20px;
	min-width: 100%;
	width: 100%;
`;

interface IEditNodeParams {
    noteValue: string,
    editNoteDialogTitle: string,
    editNoteIndex: number,
    itemIndex: number,
}
const invalidNoteParams : IEditNodeParams = {
    noteValue: "",
    editNoteDialogTitle: "",
    editNoteIndex: -1,
    itemIndex: -1,
}

export const OrderSummary = ({border = false}: IProp) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const { cartContent, setCartContent } = useCart();

    const [showAddCartDialog, setShowAddCartDialog] = useState<boolean>(false);
    const [editNodeParams, setEditNodeParams] = useState<IEditNodeParams>(invalidNoteParams);

    const [cartItems, setCartItems] = useState<IOrderItem[]>(cartContent.purchaseOrder.Items);

    useEffect( () => {
        setCartItems(cartContent.purchaseOrder.Items);
    }, [cartContent])
    useEffect( () => {
        cartContent.purchaseOrder.Items = cartItems;
        setCartContent({...cartContent});
    }, [cartItems])

    const updateCartContent = (item: IOrderItem, index: number) => {
        //console.log("item: " + JSON.stringify(item));
        cartItems[index] = item;
        setCartItems([...cartItems]);
    }

    const removeCartContentItem = (index: number) => {
        //console.log("item: " + JSON.stringify(item));
        cartItems.splice(index, 1);
        setCartItems([...cartItems]);
    }

    const TableContent = useMemo(() => {
        return (
            < >
                {cartItems?.map((item, index) => (
                    <React.Fragment key={"cc_"+index}>
                        <TR>
                            <TD className={"ptext-l"}>{item.Name}</TD>
                            <TDCenter style={{verticalAlign: "middle"}}>
                                <img style={{cursor: "pointer"}} src={item.Note1 !== "" ? "/assets/noteOn.svg" : "/assets/noteOff.svg"} width="13px" onClick={() => {
                                    setEditNodeParams({
                                        editNoteDialogTitle: t("EditNote"),
                                        noteValue: item.Note1,
                                        editNoteIndex: 1,
                                        itemIndex: index,
                                    });
                                }}/></TDCenter>
                            <TDCenter style={{verticalAlign: "middle"}}>
                                <img style={{cursor: "pointer"}} src={item.Note2 !== "" ? "/assets/noteOn.svg" : "/assets/noteOff.svg"} width="13px" onClick={() => {
                                    setEditNodeParams({
                                        editNoteDialogTitle: t("EditNotePrice"),
                                        noteValue: item.Note2,
                                        editNoteIndex: 2,
                                        itemIndex: index,
                                    });
                                }}/></TDCenter>
                            <TDCenter className={"ptext-l"}>
                                <Toggle checked={item.Folder} onChange={(value) => {
                                    item.Folder = value;
                                    updateCartContent(item, index);
                                }}/>
                            </TDCenter>
                            <TDCenter>
                                <ComboBox
                                    keyValue={item.CutLength.toString()}
                                    onChange={(keyValue) => {
                                        item.CutLength = Number(keyValue);
                                        updateCartContent(item, index);
                                    }}
                                    keyOptions={["0", "0.3", "0.5", "1", "2", "3", "4", "5", "15", "30"]}
                                    displayOptions={["-", "0.3m", "0.5m", "1m", "2m", "3m", "4m", "5m", "15m", "30m"]}
                                    style={{width: "100%"}}
                                />
                            </TDCenter>
                            <TDCenter>
                                <ComboBox
                                    keyValue={item.ClothPieceNumber.toString()}
                                    onChange={(keyValue) => {
                                        item.ClothPieceNumber = Number(keyValue);
                                        updateCartContent(item, index);
                                    }}
                                    keyOptions={["0", "1", "2", "3", "4", "5"]}
                                    displayOptions={["-", "1", "2", "3", "4", "5"]}
                                    style={{width: "100%", maxHeight: "20px"}}
                                />
                            </TDCenter>
                            <TDCenter style={{verticalAlign: "middle"}}>
                                <img  style={{cursor: "pointer"}} src={"/assets/bin.svg"} width="14px" onClick={() => {
                                    removeCartContentItem(index);
                                }}/>
                            </TDCenter>
                        </TR>
                    </React.Fragment>
                ))}
            </>
        );}, [cartItems, showAddCartDialog]);

    return (
        <Wrapper className={border ? "border" : ""}>
            <Table>
                <thead>
                <tr>
                    <TH className={"heading-xs"}>{t("Product")}</TH>
                    <THCenter className={"heading-xs"}>{t("Note")}</THCenter>
                    <THCenter className={"heading-xs"}>{t("PriceNote")}</THCenter>
                    <THCenter className={"heading-xs"}>{t("ColorFolder")}</THCenter>
                    <TH className={"heading-xs"}>{t("Cuts")}</TH>
                    <TH className={"heading-xs"}>{t("Pezze")}</TH>
                    <TH className={"heading-xs"}></TH>
                </tr>
                </thead>
                <tbody>
                    {TableContent}
                </tbody>
            </Table>

            <SummaryRow>
                <Label className={"heading-s"} >{t("TotalProducts")}: {cartItems.length}</Label>
                <Button
                    label={t("AddGenericProduct")}
                    backgroundColor={"TextLight"}
                    size={"Compact"}
                    overrideWidth={"200px"}
                    caps={false}
                    color={"Gray0"}
                    hoverDark={false}
                    onClick={ () => {setShowAddCartDialog(true)}}
                    isLoading={false}
                    isDisabled={false}
                />
            </SummaryRow>

            { editNodeParams.itemIndex !== -1 &&
				<DialogEditNote dialogTitle={editNodeParams.editNoteDialogTitle} value={editNodeParams.noteValue}
												onCancelEdit={() => {
                                                    setEditNodeParams(invalidNoteParams);
												}}
												onNewValue={(newValue) => {
                                                    const item = cartItems[editNodeParams.itemIndex];
                                                    if(editNodeParams.editNoteIndex === 1)
                                                        item.Note1 = newValue;
                                                    if(editNodeParams.editNoteIndex === 2)
                                                        item.Note2 = newValue;
                                                    updateCartContent(item, editNodeParams.itemIndex);
                                                    setEditNodeParams(invalidNoteParams);
												}}/>
			}
            { showAddCartDialog &&
                <DialogAddToCart name={t("GenericProductName")} categoryId={""} colorFolderDefault={false} requestClose={() => setShowAddCartDialog(false)}/>
            }
        </Wrapper>
    );
};

interface IProp {
    border?: boolean
}