import {IResponse, PageFilter} from "./api";
import {AxiosResponse} from "axios";
import {FileDescriptor, TypeDownload, TypeUrl} from "./categories";

export interface IGetProductsRequest {
	Categories: string[],
	//Name?: string,
	State: "Approved",
	PageFilter: PageFilter;
}

export interface IProduct {
	"IdProduct": string,//"43e09654-7d83-4abc-a71f-fc59e511840e", //Id in Fabric
	"Name": string, //Visual name of the product
	"Description": null, //Visual code of the product
	"PreviewImage": string, //url
	"PreviewReverse": string, //url
	"IconImage": string, //url
	//"No3D": false,
	//"EnableCloth": false,
	//"TileHeight": 5.0,
	//"TileWidth": 5.0,
	//"NoTile": false,
	"IdCategory": string,
	"BasesIds": string[], //Override to the category ids euro 2.0 Ids for products base, 1 expected, used to fetch textures with simulation call
	//"IsPrint": false

	Urls?: FileDescriptor<TypeUrl>[], //can override Category Urls
	Files?: FileDescriptor<TypeDownload>[], //can override Category Files
	IdReference: string //to select from param the variant
}

export interface ProductData extends IProduct{
	simulation?: IProductSimulation;
	techView?: IProductTechView;

	//resultTechview?: Promise<AxiosResponse<IResponse<IProductTechView>, any>>;

	//Base and override
	//baseProductResultSimulation? : Promise<AxiosResponse<IResponse<IProductSimulation>, any>>;
	//resultSimulation?: Promise<AxiosResponse<IResponse<IProductSimulation>, any>>
}

export interface IProductSimulation {
	"IdProduct": string, //"43e09654-7d83-4abc-a71f-fc59e511840e",
	"Name": string, //"01",
	"PreviewImage": string, //"https://fabric-senseir-dev.s3.amazonaws.com/0102e067-70db-4ac3-8992-be5d09513008/products/43e09654-7d83-4abc-a71f-fc59e511840e/main_img_lacework_raschel_24267351_MainTex_20210201140637764_20230411224049123.jpg",
	"AlbedoMap": string, //"https://fabric-senseir-dev.s3.amazonaws.com/0102e067-70db-4ac3-8992-be5d09513008/products/43e09654-7d83-4abc-a71f-fc59e511840e/image/lacework_raschel_24267351_MainTex_20210201140633363_20230411224050434.jpg",
	"OcclusionMap": string,
	"MetallicMap": string, //null,
	"NormalMap": string, //"https://fabric-senseir-dev.s3.amazonaws.com/0102e067-70db-4ac3-8992-be5d09513008/products/43e09654-7d83-4abc-a71f-fc59e511840e/image/lacework_raschel_46375002_BumpMap_20210201140635024_20230411224051602.jpg",
	"OpacityMap": string //,
	"SmoothnessMap": string //null,
	"ReverseMap": string,
	//"Geometry": null,
	//"EnableZoom": false,
	//"No3D": false,
	//"EnableCloth": false,
	"TileWidth": number, //5.0,
	"TileHeight": number, //5.0,
	"NoTile": boolean, //false,
	"AltTileWidth": number, //5.0, //Controlled usage from TileMode
	"AltTileHeight": number, //5.0, //Controlled usage from TileMode
	"TileMode": TileMode, //false,
	//"Proxie": null,
	//"Physics": { "MaxBending": 0.0, "Lift": 0.0, "Drag": 0.0, "Damping": 0.0 },
	//"NormalVal": 0.0,
	//"SmoothnessVal": 1.0,
	//"OcclusionVal": 0.0,
	//"OpacityVal": 1.0,
	//"MetallicVal": 0.0,
	//"EnableSpecular": false,
	//"EnableGlossiness": false,
	"IdCategory": string, //"9c769658-bd17-47fe-8d90-30e7424f9b34",
	//"BasesIds": [],
	//"IsPrint": false
}

export enum TileMode {
	None = "none",
	AlbedoOnly = "albedoOnly",
}

export interface IProductTechView {
	"IdProduct": "43e09654-7d83-4abc-a71f-fc59e511840e",
	"Name": string,
	"Description": string,
	"Code": string, //"COT 10% LYC 90%" Composition
	"Weight": string,//"200gr",
	"Width": string, //"150 cm", Height
	"TechProperties": UniquePerformances[],
	//"TechviewMap": string, not used
}

export enum UniquePerformances {
	Breathability = "Breathability",
	ChlorineResistance = "ChlorineResistance",
	EasyCare = "EasyCare",
	ExtraConfort = "ExtraConfort",
	ExtraFine = "ExtraFine",
	NoPilling = "NoPilling",
	QuickDrying = "QuickDrying",
	SunBlock = "SunBlock",
	WaterProof = "WaterProof",
	WaterRepellent = "WaterRepellent",
	WrinkleFree = "WrinkleFree",
}