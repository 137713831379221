

export function Range(from: number, to: number): number[] {
    return [...Array(to - from + 1).keys()];
}

//Intorno del selezionato
export function RangeWindow(from: number, to: number, current: number, length: number): number[] {
    const range = Range(from, to);
    const currentIndex = range.indexOf(current);

    if(currentIndex < length / 2)
        return range.slice(0, length);

    if(currentIndex > range.length - length / 2)
        return range.slice(range.length - length);

    const halfLength = Math.floor(length / 2);
    return range.slice(currentIndex - halfLength, currentIndex + halfLength + 1);
}

//Finestra fissa k contiene il selezionato
export function RangeWindowFramed<T>(container: T[], windowIndex: number, windowsSize: number): T[] {
    return container.slice(windowIndex * windowsSize, windowIndex * windowsSize + windowsSize);
}