import CountriesJson from "./countriesJson";

export const CountryCodeToLocalizedName = (countryCode: string, langCode: string) : string => {

    const country = CountriesJson.find(country => country.cca3 === countryCode);
    if(country) {
        if(langCode === "en") {
            return country.name.common;
        }
        else  if(langCode === "it") {
            return country.translations?.ita?.common ?? country.name.common;
        }
    }

    return countryCode;
};

export const CountriesComboKeyList = (nameFilter: string, langCode: string) : string[] => {
   return CountriesJson.filter(country => {
       const localizedName = CountryCodeToLocalizedName(country.cca3, langCode);
       return country.cca3.toLowerCase().includes(nameFilter.toLowerCase()) || localizedName.toLowerCase().includes(nameFilter.toLowerCase());
   })
       .map( country => country.cca3);
}

export const CountriesComboDisplayNamesList = (nameFilter: string, langCode: string) : string[] => {
    const localized = CountriesJson.filter(country => {
        const localizedName = CountryCodeToLocalizedName(country.cca3, langCode);
        return country.cca3.toLowerCase().includes(nameFilter.toLowerCase()) || localizedName.toLowerCase().includes(nameFilter.toLowerCase());
    })
        .map( country => {
        const displayName = CountryCodeToLocalizedName(country.cca3, langCode);
        return displayName;
    });

    return localized;
}