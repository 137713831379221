import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Logo } from "components/logo/logo";
import Footer from "../../components/footer/footer";


const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	height: 100%;
	padding-top: 80px;
	
	overflow-y: auto;
	background-color: ${props => props.theme.Background};

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;
const RowParent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 1200px;
`;

const Header = styled.div`
    padding-top: 30px;
	color: ${props => props.theme.Gray5};
	font-weight: bold;
`;

const WrapperMessage = styled.div`
	display: flex;
	flex-direction: column;
	width: 900px;
	@media screen and (max-width: 900px) {
		min-width: calc(100% - 10px);
		width: calc(100% - 10px);
		max-width: calc(100% - 10px);
	}

	align-items: start;
	padding: 40px 30px 30px 30px;
	gap:0px;
`;

const Message = styled.div`
    padding-top: 0px;
    text-align: left;
	color: ${props => props.theme.TextDefault};
    
    padding-bottom: 20px;
`;

export const CookiesPolicy = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    return (
        <PageWrapper>
            <RowParent>
                <Logo style={{ height: "120px", width: "unset", cursor: "pointer" }} onClick={() => navigate("/")}/>
            </RowParent>
            <RowParent>
                <Header className="heading caps">{t("CookiePolicy")}</Header>
            </RowParent>
            <RowParent style={{ flex: "1 0 auto" }}>
                <WrapperMessage>
                    <Message className="heading-s">{t("CookiePolicy1")}</Message>
                    <Message className="heading-s">{t("CookiePolicy2")}</Message>
                    <Message className="heading-s">{t("CookiePolicy3")}</Message>
                    <Message className="heading-s">{t("CookiePolicy4")}</Message>
                    <Message className="heading-s">{t("CookiePolicy5")}</Message>
                    <Message className="heading-s">{t("CookiePolicy6")}</Message>
                    <Message className="heading-s">{t("CookiePolicy7")}</Message>
                    <Message className="heading-s">{t("CookiePolicy8")}</Message>
                    <Message className="heading-s">{t("CookiePolicy9")}</Message>
                    <Message className="heading-s">{t("CookiePolicy10")}</Message>
                    <Message className="heading-s">{t("CookiePolicy11")}</Message>
                    <Message className="heading-s">{t("CookiePolicy12")}</Message>
                    <Message className="heading-s">{t("CookiePolicy13")}</Message>
                    <Message className="heading-s">{t("CookiePolicy14")}</Message>
                    {/*<Row style={{marginTop: "70px", width: "200px"}}>
                        <Button
                            label={t("BackToLogin")}
                            backgroundColor={"TextDark"}
                            color={"Gray0"}
                            hoverDark={false}
                            onClick={() => navigate("/")}
                            isLoading={false}
                            isDisabled={false}
                            shouldFill
                            type="button"
                        />
                    </Row>*/}
                </WrapperMessage>
            </RowParent>
            <Footer/>
        </PageWrapper>
    );
};