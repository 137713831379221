import React from "react";
import styled, {useTheme} from "styled-components";

import { useTranslation } from "react-i18next";
import {Button} from "../../../components/button/button";

const FullScreenBackground = styled.div`
    position: absolute;
	inset: 0;
	z-index: var(--z-level-3);
	
	width: 100%;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//padding: 160px 400px 50px 50px;
	gap: 0px;
	
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
`;

const MainWrapper = styled.div`
	position: relative;
	
	display: flex;
	flex-direction: column;
	
	justify-content: flex-start;
	//align-items: center;
	gap: 30px;
	padding: 20px;

	min-height: 500px;
	max-height: 800px;
	min-width: 640px;
	max-width: 640px;
	@media screen and (max-width: 704px) {
		min-width: calc(100% - 10px);
		max-width: calc(100% - 10px);
	}
	
	background-color: ${props => props.theme.Background};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;

	margin:4px, 4px;
	overflow-x: hidden;
	overflow-y: auto;
	text-align:justify;
`;

const RowRight = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	
	gap: 10px;
`;

const Title = styled.p`
	padding-top: 40px;
	text-align: center;
	
	color: ${props => props.theme.TextDefault};
	
`;

const Text = styled.p`
	flex: 1 0 auto;
	
	//padding-top: 40px;
	padding-bottom: 0px;
	text-align: left;
	
	color: ${props => props.theme.TextDefault};

	&.bold {
		font-weight: 510;
	}
`;


const FullscreenDocument = ({ documentType, requestClose }: IProps) => {

	const { t } = useTranslation();

	return (
		<FullScreenBackground>
			<MainWrapper>
				<Title className={"heading-l"} >{documentType === "Cookie" ? t("CookiePolicy") : t("NdaPolicy1")}</Title>
				{
					documentType === "NDA" ?
						<>
							<Text className={"text-l"}>{t("NdaPolicy2")}</Text>
							<Text className={"text-l"}>{t("NdaPolicy3")}</Text>
							<Text className={"text-xl bold"}>{t("NdaPolicy4")}</Text>
							<Text className={"text-l"}>{t("NdaPolicy5")}</Text>
							<Text className={"text-l bold"}>{t("NdaPolicy6")}</Text>
							<Text className={"text-l"}>{t("NdaPolicy7")}</Text>
							<Text className={"text-l"}>{t("NdaPolicy8")}</Text>
							<Text className={"text-l"}>{t("NdaPolicy9")}</Text>
							<Text className={"text-l"}>{t("NdaPolicy10")}</Text>
						</>
						:
						<>
							<Text className={"text-l"}>{t("CookiePolicy1")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy2")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy3")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy4")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy5")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy6")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy7")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy8")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy9")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy10")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy11")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy12")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy13")}</Text>
							<Text className={"text-l"}>{t("CookiePolicy14")}</Text>
						</>
				}
				<RowRight>
					<Button onClick={() => requestClose(false)} isOutlined={false} backgroundColor={"TextDark"} hoverDark={false} color={"Gray0"} label={t("Refuse")} ></Button>
					<Button onClick={() => requestClose(true)} isOutlined={false} backgroundColor={"TextDark"} hoverDark={false} color={"Gray0"} label={t("Accept")} ></Button>
				</RowRight>
			</MainWrapper>
		</FullScreenBackground >
	);
};

export type DocumentType = "closed" | "NDA" | "Cookie";

interface IProps {
	documentType: DocumentType
	requestClose: (accepted: boolean) => void,
}

export default FullscreenDocument;