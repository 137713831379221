import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";

import {USER_REGISTRATION_API} from "api/axios";
import {
    ERequestAssessCode,
    IRequestAccessRequest,
    IRequestAccessResponse,
} from "types";

import { Logo } from "components/logo/logo";
import Footer from "../../components/footer/footer";
import {InputField} from "../../components/inputField/inputField";
import {Toggle} from "../../components/toggle/toggle";
import {Button} from "../../components/button/button";
import FullscreenDocument, {DocumentType} from "./fullscreenDocument/fullscreenDocument";
import {emailValidation, passwordValidation} from "../../utils/stringUtils";
import {AxiosRequestConfig} from "axios";
import {Spinner} from "../../components/spinner/spinner";


const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	height: 100%;
	padding-top: 80px;
	
	overflow-y: auto;
	background-color: ${props => props.theme.Background};

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;
const RowParent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 1200px;
`;

const Header = styled.div`
    padding-top: 30px;
	color: ${props => props.theme.Gray5};
	font-weight: bold;
`;

const WrapperMessage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 30px 30px 30px;
	gap:0px;
`;

const Message = styled.div`
    padding-top: 0px;
    text-align: center;
	color: ${props => props.theme.TextDefault};
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 30px 30px 30px;
	gap: 15px;
    
    width: 700px;
	@media screen and (max-width: 704px) {
		width: 100%;
	}
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
    
    flex-direction: row;
    gap: 20px;
`;

const RowCollapsible = styled(Row)`
	@media screen and (max-width: 704px) {
		flex-direction: column;
	}
`;


const CenterRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
    height: 21px;
    
    gap: 20px;
`;


const RowNoGap = styled(Row)`
    gap: 4.5px;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 5px;
`;

const ErrorMessage = styled(Row)`
	justify-content: center;
	color: ${props => props.theme.Negative};
	height: 14px;
`;

const ErrorMessageContextual = styled(Row)`
	justify-content: left;
	color: ${props => props.theme.Negative};
	height: 14px;
	padding-left: 22px;
    padding-bottom: 0px;
`;

const Label = styled.label`
	display: flex;
	flex-direction: row;
	padding-left: 22px;
	color: ${props => props.theme.TextDefault};
`;

const DialogText = styled.div`
    cursor: pointer;
    
	color: ${props => props.theme.TextDefault};
	transition: color easeout 300ms;
`;

const AccentText = styled.span`
	text-decoration: underline;
    cursor: pointer;
    
	color: #4B4B4B;
	transition: color easeout 300ms;
	&:hover {
		color: ${props => props.theme.TextDefault};
	}
`;

const RequestAccessLabel = styled.label`
	color: ${props => props.theme.TextLight};
	cursor: pointer;
	text-decoration: underline;
	
	transition: color easeout 300ms;
	&:hover {
		color: ${props => props.theme.TextDark};
	}
`;

const RequestAccessCall = async (payload: IRequestAccessRequest, language: string) => {
    const config : AxiosRequestConfig = {
        headers: {
            "language": language
        }
    };

    const { data } = await USER_REGISTRATION_API.put<IRequestAccessResponse>("/user/register", payload, config);
    return {respData: data };
};

export const RequestAccess = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const [documentType, setDocumentType] = useState<DocumentType>("closed");

    const [name, setName] = useState<string>("");
    const [surname, setSurname] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [workWith3D, setWorkWith3D] = useState(false);
    const [NDA, setNDA] = useState(false);
    const [cookie, setCookie] = useState(false);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showInputErrors, setShowInputErrors] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showShowCompleteMessage, setShowCompleteMessage] = useState<boolean | null>(false);

    const handleRequestAccess = () => {
        if(canSubmit) {
            setShowCompleteMessage(null);
            setErrorMessage("");

            let statusCatch: number | null = null;

            Promise.all([
                RequestAccessCall({
                        FullName: `${name} ${surname}`,
                        Name: email,
                        Email: email,
                        //Password: password,
                        Prop1: company,
                        Prop2: role,
                        Prop3: workWith3D ? 'TRUE' : 'FALSE'
                    },
                    i18n.language).
                catch(resp => {
                    //not valid resp
                    statusCatch = resp.response.status;
                }),
                new Promise(resolve => setTimeout(resolve, 1000))
            ]).then(([resp]) => {
                if(!resp) {
                    //passed through catch
                    if(statusCatch === ERequestAssessCode.UsernameDuplicated) {
                        setErrorMessage(t("EmailAlreadyUsed"));
                        setShowCompleteMessage(false);
                    }
                    else {
                        setErrorMessage(t("ServerError"));
                        setShowCompleteMessage(false);
                    }
                    return;
                }

                if(!resp.respData.Error && resp.respData.StatusCode === ERequestAssessCode.Ok)
                {
                    setShowCompleteMessage(true);
                }
                else {
                    setErrorMessage(t("ServerError"));
                    setShowCompleteMessage(false);
                }
            });
        }
        else {
            setShowInputErrors(true);
            setShowCompleteMessage(false);
        }
    };

    const canSubmit = name.length > 0 && surname.length > 0 && emailValidation(email) /*&& passwordValidation(password)*/ && company.length > 0 && role.length
        && NDA && cookie;

    const handleOnChange = (field: "name" | "surname" | "user" | "psw" | "company" | "role", value: string) => {
        field === "name" && setName(value);
        field === "surname" && setSurname(value);
        field === "user" && setEmail(value);
        //field === "psw" && setPassword(value);
        field === "company" && setCompany(value);
        field === "role" && setRole(value);

        setErrorMessage("");
    };
    return (
        <PageWrapper>
            <RowParent>
                <Logo style={{ height: "120px", width: "unset", cursor: "pointer" }} onClick={() => navigate("/")} />
            </RowParent>
            <RowParent>
                <Header className="heading caps">Digital Library</Header>
            </RowParent>
            <RowParent style={{ flex: "1 0 auto" }}>
                {
                    showShowCompleteMessage ?
                        <WrapperMessage>
                            <Message className="heading-s">{t("RequestAccessComplete1")}</Message>
                            <Message className="heading-s">{t("RequestAccessComplete2")}</Message>
                            <Row style={{marginTop: "70px", width: "200px"}}>
                                <Button
                                    label={t("BackToLogin")}
                                    backgroundColor={"TextDark"}
                                    color={"Gray0"}
                                    hoverDark={false}
                                    onClick={() => navigate("/")}
                                    isLoading={false}
                                    isDisabled={false}
                                    shouldFill
                                    type="button"
                                />
                            </Row>
                        </WrapperMessage>
                        :
                        <Wrapper>
                            <RowCollapsible>
                                <Column>
                                    <Label className="heading-s">{t("Name")}</Label>
                                    <InputField id="name"
                                                size="Default"
                                                width="100%"
                                                backgroundColor={theme.Gray1}
                                                value={name}
                                                onChange={value => handleOnChange("name", value)}
                                    />
                                    <ErrorMessageContextual
                                        className="ptext">{showInputErrors && !(name.length > 0) && t("NameError")}</ErrorMessageContextual>
                                </Column>
                                <Column>
                                    <Label className="heading-s">{t("Surname")}</Label>
                                    <InputField
                                        id="surname"
                                        size="Default"
                                        width="100%"
                                        backgroundColor={theme.Gray1}
                                        value={surname}
                                        onChange={value => handleOnChange("surname", value)}
                                        isInvalid={!!password.length && !!errorMessage}
                                    />
                                    <ErrorMessageContextual
                                        className="ptext">{showInputErrors && !(surname.length > 0) && t("SurnameError")}</ErrorMessageContextual>
                                </Column>
                            </RowCollapsible>
                            <RowCollapsible>
                                <Column>
                                    <Label className="heading-s">{t("Company")}</Label>
                                    <InputField id="company"
                                                size="Default"
                                                width="100%"
                                                backgroundColor={theme.Gray1}
                                                value={company}
                                                onChange={value => handleOnChange("company", value)}
                                    />
                                    <ErrorMessageContextual
                                        className="ptext">{showInputErrors && !(company.length > 0) && t("CompanyError")}</ErrorMessageContextual>
                                </Column>
                                <Column>
                                    <Label className="heading-s">{t("Role")}</Label>
                                    <InputField
                                        id="role"
                                        size="Default"
                                        width="100%"
                                        backgroundColor={theme.Gray1}
                                        value={role}
                                        onChange={value => handleOnChange("role", value)}
                                        isInvalid={!!password.length && !!errorMessage}
                                    />
                                    <ErrorMessageContextual
                                        className="ptext">{showInputErrors && !(role.length > 0) && t("RoleError")}</ErrorMessageContextual>
                                </Column>
                            </RowCollapsible>
                            <RowCollapsible>
                                <Column>
                                    <Label className="heading-s">{t("Email")}</Label>
                                    <InputField id="username"
                                                size="Default"
                                                width="100%"
                                                backgroundColor={theme.Gray1}
                                                value={email}
                                                onChange={value => handleOnChange("user", value)}
                                    />
                                    <ErrorMessageContextual
                                        className="ptext">{showInputErrors && !emailValidation(email) && t("MailError")}</ErrorMessageContextual>
                                </Column>
                                {/*<Column>
                                    <Label className="heading-s">{t("Password")}</Label>
                                    <InputField
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        size="Default"
                                        width="100%"
                                        backgroundColor={theme.Gray1}
                                        value={password}
                                        rightIcon={!showPassword ? "/assets/view.svg" : "/assets/view-off.svg"}
                                        iconSizeOverride={"32px"}
                                        onRightIconClick={() => setShowPassword(!showPassword)}
                                        onChange={value => handleOnChange("psw", value)}
                                        isInvalid={!!password.length && !!errorMessage}
                                    />
                                    <ErrorMessageContextual
                                        className="ptext">{showInputErrors && !passwordValidation(password) && t("PasswordError")}</ErrorMessageContextual>
                                </Column>*/}
                            </RowCollapsible>
                            <RowNoGap style={{marginBottom:"20px"}}>
                                <Toggle checked={workWith3D} onChange={() => setWorkWith3D(!workWith3D)}/>
                                <DialogText>
                                    <span className={"ptext-l"}>{t("WorkWith3Dlabel")}</span>
                                </DialogText>
                            </RowNoGap>
                            <RowNoGap>
                                <Toggle checked={NDA} onChange={() => setDocumentType("NDA")}/>
                                <DialogText className={"heading-s"} onClick={() => setDocumentType("NDA")}
                                ><span className={"ptext-l"}>{t("NDAPolicyLabel")}</span><AccentText>{t("NdaPolicy")}</AccentText></DialogText>
                            </RowNoGap>
                            <RowNoGap>
                                <Toggle checked={cookie} onChange={() => setDocumentType("Cookie")} />
                                <DialogText className={"heading-s"} onClick={() => setDocumentType("Cookie")}
                                ><span className={"ptext-l"}>{t("CookiePolicyLabel")}</span><AccentText>{t("CookiePolicy")}</AccentText></DialogText>
                            </RowNoGap>
                            <ErrorMessageContextual
                                className="ptext">{showInputErrors && !(NDA && cookie) && t("PolicyError")}</ErrorMessageContextual>
                            <CenterRow>
                                {showShowCompleteMessage === null ?
                                    <>
                                        <ErrorMessage style={{color: theme.TextDark, width: "auto"}}>{t("RequestAccessLoading")}</ErrorMessage>
                                        <div style={{width: "21px", height: "21px"}}><Spinner size={"Compact"}/></div>
                                    </>
                                :
                                    <ErrorMessage>{errorMessage}</ErrorMessage>}

                            </CenterRow>
                            <Row style={{marginTop: "0px", width: "200px"}}>
                                <Button
                                    label={t("RequestAccess")}
                                    backgroundColor={"TextDark"}
                                    color={"Gray0"}
                                    hoverDark={false}
                                    onClick={handleRequestAccess}
                                    isLoading={showShowCompleteMessage === null}
                                    isDisabled={false}
                                    shouldFill
                                    type="submit"
                                />
                            </Row>
                            <RequestAccessLabel onClick={() => navigate("/")}>{t("or sign in")}</RequestAccessLabel>
                        </Wrapper>
                }
            </RowParent>
            {
                documentType !== "closed" &&
                <FullscreenDocument documentType={documentType} requestClose={(accepted: boolean) => {
                    if(documentType === "NDA")
                        setNDA(accepted);
                    if(documentType === "Cookie")
                        setCookie(accepted);
                    setDocumentType("closed");
                }
                } />
            }
            <Footer/>
        </PageWrapper>
    );
};