import { useContext, useDebugValue } from "react";
import CartContext from "../context/CartProvider";

const useCart = () => {

	const { cartContent } = useContext(CartContext);
	useDebugValue(cartContent, cartContent => `CartContent: ${JSON.stringify(cartContent, null, 4)}`);

	return useContext(CartContext);
};

export default useCart;