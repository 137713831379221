import React, {createContext, useState, ReactNode, useEffect} from "react";
import { IAuthData, IAuthContext } from "../types";
import {useLocation, useNavigate} from "react-router-dom";

const AuthContext = createContext<IAuthContext>({});

export const AuthProvider = ({ children }: { children: ReactNode }) => {

	const [auth, setAuth] = useState<IAuthData>({ succeeded: false, rememberMe: false });

	//Local storage items
	//const [items, setItems] = useState<any>([]);

	useEffect(() => {
		const items = JSON.parse(localStorage.getItem("authData") || "{}");
		console.log(items);
		if (items && items.rememberMe && items.succeeded) {
			console.log("Auto re-log");
			setAuth(items);
		}
	}, []);

	useEffect(() => {
		if(auth.succeeded) {
			if(auth.rememberMe) {
				localStorage.setItem("authData", JSON.stringify(auth));
			}
		}
	}, [auth]);

	return (
		<AuthContext.Provider value={{ auth, setAuth }}>
			{children}
		</ AuthContext.Provider>
	);
};

export default AuthContext;