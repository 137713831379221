import React from "react";
import {OrderFilter, PageFilter} from "./api";

export interface ICartContext {
    cartContent: ICartContent,
    setCartContent: React.Dispatch<React.SetStateAction<ICartContent>>,
}

export interface ICartContent {
    purchaseOrder: IPurchaseOrder,

    //Local extras data
    valid: boolean,
    businessCardBase64?: string
}

export interface IPurchaseOrder {
    "State": EOrderState,
    "Priority": number,
    "Notes": string,
    "Customer"?: ICustomer,
    Email: string,
    SendEmail: boolean,
    "Items": IOrderItem[]

    //Seller: string //id of the user type seller that made the order
}

export interface IInsertImageRequest {
    "IdPurchaseOrder"?: string,
    "OriginalName": string,
    "Data"?: string,
    //"IdProject": string
}

export interface IInsertImageResponse {
    "IdPurchaseIMage": string,
    "Url": string
}

export enum EOrderState {
    New = "New",
    Inserted = "Inserted",
    Closed = "Closed"
}

export interface IPutPurchaseOrderResponse {
    IdPurchaseOrder: string
}


export interface IOrderItem {
    "Name": string,
    //"Description": string,
    "Note1": string, //Note
    "Note2": string, //PriceNote
    "CutLength": number,
    "ClothPieceNumber": number,
    "Folder": boolean,
    "IdProduct"?: string,
    "IdCategory": string
}

export interface ICustomer {
    "IdCustomer"?: string,
    "BusinessName": string,
    "Address": string,
    "StreetNumber": string,
    "City": string,
    "PostalCode": string,
    "Country": string,
    "CommercialArea": string, //comma separated ECommercialArea strings eg: "Bagno,Vari"
    "Operator": string, //id to user type operator
    //"ReferenceFirstName": string,
    //"ReferenceLastName": string,
    //"ReferenceEmail": string
    //CustomerCode: string //id in the client system, to be shown in the backoffice interface
}

export enum ECommercialArea {
    Bagno = "Bagno",
    Abbigliamento = "Abbigliamento",
    Sport = "Sport",
    Vari = "Vari",
    Lingerie = "Lingerie",
    Converter = "Converter",
}

export interface IGetCustomersRequest {
    SearchName?: string,
    PageFilter: PageFilter;
    OrderFilter? :OrderFilter
}