import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IGenericKeyToObject } from "../utils/BaseTypes";
import { RootState } from "./store";


class ThemePaletteClass {
	"Background": string;
	"Background1": string;
	"TextDark": string;
	"TextDefault": string;
	"TextLight": string;
	"Gray0": string;
	"Gray1": string;
	"Gray2": string;
	"Gray3": string;
	"Gray4": string;
	"Gray5": string;
	"Primary": string;
	"PrimaryHovered": string;
	"PrimarySelected": string;
	"Secondary": string;
	"SecondaryHovered": string;
	"SecondarySelected": string;
	"Positive": string;
	"PositiveHovered": string;
	"PositiveSelected": string;
	"Negative": string;
	"NegativeHovered": string;
	"NegativeSelected": string;
	"BreakpointSmall": string;
	"BreakpointMedium": string;
	"PageMaxWidth": string;
	"HeaderHeight": string;
}
export interface ThemePalette extends ThemePaletteClass { }

//Union type of all the key of ThemePalette interface
export type ThemePaletteKeys = keyof ThemePalette;

//Create array with all the keys of ThemePalette
type ThemePaletteKeysArray = Array<keyof ThemePalette>;
export const ThemePaletteArrayKey: ThemePaletteKeysArray =
	Object.keys(new ThemePaletteClass()) as ThemePaletteKeysArray;

const ThemeNames = ["light", "dark"] as const;
type ThemeType = typeof ThemeNames[number]

export interface ThemeState {
	activeThemeName: ThemeType,
	themes: IGenericKeyToObject<ThemeType, ThemePalette>
}

const staticVariables = {
	"BreakpointSmall": "400px",
	"BreakpointMedium": "800px",
	"PageMaxWidth": "1280px",
	"HeaderHeight": "70px"
};

const initialState: ThemeState = {
	activeThemeName: "light",
	themes: {
		light: {
			Background: "#FFFFFF",
			Background1: "#F6F5F3", //not set up

			TextDark: "#333333",
			TextDefault: "#666666",
			TextLight: "#999999",

			Gray0: "#FFFFFF",
			Gray1: "#ECECEC",
			Gray2: "#D5D5D5",
			Gray3: "#808080", //not set up
			Gray4: "#BBBBBB",
			Gray5: "#000000",

			Primary: "#8844CC",
			PrimaryHovered: "#553388",
			PrimarySelected: "#AA55FF",
			Secondary: "#7AACC9",
			SecondaryHovered: "#003B73",
			SecondarySelected: "#60A3D9",
			Positive: "#678C2B",
			PositiveHovered: "#538217",
			PositiveSelected: "#678C2B",
			Negative: "#D02E0B",
			NegativeHovered: "#A81E00",
			NegativeSelected: "#C34949",
			...staticVariables
		},
		dark: {
			Background: "#FFFFFF",
			Background1: "#F6F5F3",
			TextDark: "#222123",
			TextDefault: "#4F4E52",
			TextLight: "#79797B",
			Gray0: "#FFFFFF",
			Gray1: "#E6E6E6",
			Gray2: "#B3B3B3",
			Gray3: "#808080",
			Gray4: "#4D4D4D",
			Gray5: "#2F2F2F",
			Primary: "#8844CC",
			PrimaryHovered: "#553388",
			PrimarySelected: "#AA55FF",
			Secondary: "#7AACC9",
			SecondaryHovered: "#003B73",
			SecondarySelected: "#60A3D9",
			Positive: "#7DBC2D",
			PositiveHovered: "#538217",
			PositiveSelected: "#A2E052",
			Negative: "#D02E0B",
			NegativeHovered: "#A81E00",
			NegativeSelected: "#E53E1A",
			...staticVariables
		}
	}
};

export const ThemeSlice = createSlice({
	name: "ThemeSlice",
	initialState,
	reducers: {
		setActiveTheme: (state, action: PayloadAction<ThemeType>) => {
			state.activeThemeName = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setActiveTheme } = ThemeSlice.actions;

export const selectCurrentTheme = (state: RootState) => state.theme.themes[state.theme.activeThemeName];

export default ThemeSlice.reducer;