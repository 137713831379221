import useAuth from "./useAuth";
import {EIdUserType, IAuthData} from "../types";
import {useEffect, useState} from "react";


const useCanShowCart = () => {
	const { auth } = useAuth();

	const getCanShowCart = (auth?: IAuthData) => {
		return auth?.userData?.IdUserType === EIdUserType.SuperAdmin || auth?.userData?.IdUserType === EIdUserType.Admin || auth?.userData?.IdUserType === EIdUserType.Seller
	}

	const [canShowCart, setCanShowCart] = useState<boolean>(getCanShowCart(auth));

	useEffect( () => {
		const newCan = getCanShowCart(auth);
		//console.log("Can show cart", newCan, auth)

		setCanShowCart(newCan);

	}, [auth]);

	return canShowCart;
};

export default useCanShowCart;