
import React, {useEffect, useMemo, useState} from "react";
import styled, {useTheme} from "styled-components";

import chroma from "chroma-js";
import {RangeWindow, RangeWindowFramed} from "../../../utils/mathUtils";
import {ProductData} from "../../../types";
import {useTranslation} from "react-i18next";
import {InputField} from "../../../components/inputField/inputField";

const TotalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
	
	gap: 10px;
` ;

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	
	align-items: center;
	
	gap: 5px;
	padding: 10px;

	border-radius: 40px;
	background-color: rgba(255, 255, 255, 0.8);

	overflow-y: auto;
	//max-width: 90vw;//500px;
	//white-space: nowrap;

	&.isMenuOpen {
		min-height: 340px;
		min-width: 215px; //60 * 3 + gap + padding
	}
	
	::-webkit-scrollbar-track {
		margin-left: 28px;
		margin-right: 18px;
	}
`;

const Arrow = styled.div<{ iconUrl: string, className?: string }>`
	cursor: pointer;
	background-color: transparent;

	background-image: url("${props => props.iconUrl}");
	background-repeat: no-repeat;
	background-size: 20px;
	background-position-x: center;
	background-position-y: center;
	
	min-width: 50px;
	width: 50px;
	max-width: 50px;
	
	min-height: 50px;
	height: 50px;
	max-height: 50px;
	
	border-radius: 30px;
	border-inline: 3px solid rgba(255, 255, 255, 1);
	border-block: 3px solid rgba(255, 255, 255, 1);

	transition: all 250ms ease-out;
	
	&:hover {
		opacity: 0.6;
	}
	
	&.disabled {
		opacity: 0.3;
		
	}
`;

const ThumbnailsList = styled.div`
	height: 100%;
	
 	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	
	gap: 5px;
	//padding: 5px;

	//border-radius: 40px;
	//background-color: rgba(255, 255, 255, 0.5);
`;

const ThumbsContainer = styled.div<{ imageUrl: string, className?: string }>`
	cursor: pointer;
	background-image: url("${props => props.imageUrl}");
	background-repeat: no-repeat;
	//background-size: cover;
	background-position-x: center;
	background-position-y: center;
	
	min-width: 60px;
	width: 60px;
	max-width: 60px;
	
	min-height: 60px;
	height: 60px;
	max-height: 60px;
	
	display: flex;
	justify-content: center;
	align-items: center;
	
	border-radius: 30px;
	border-inline: 4px solid transparent;
	border-block: 4px solid transparent;

	transition: all 250ms ease-out;
	
	&.selected {
		border-inline: 4px solid rgba(255, 255, 255, 0.5);
		border-block: 4px solid rgba(255, 255, 255, 0.5);
		box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.25);
	}

	&:hover {
		opacity: 0.6;
	}
`;

const Label = styled.div`
	text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
	color: ${props => props.theme.Gray0};
`;

const OtherDiv = styled.div`
	width: 100%;
	padding: 5px;

	border-radius: 12px;
	background-color: rgba(255, 255, 255, 0.8);
	text-align: center;
	
	user-select: none;
`;

const OtherLabel = styled.div`
	background-color: white;
	border-radius: 6px;
	padding: 2px 4px 4px 4px;
	
	color: ${props => props.theme.TextDark};
	font-weight: 500;
	line-height: 14px;
	font-size: 14px;
`;

const EmptyStateWrapper = styled.div`
	width: 100%;
	padding: 5px;

	//border-radius: 12px;
	//background-color: rgba(255, 255, 255, 0.8);
	text-align: center;
	
	user-select: none;
`;

const EmptyStateLabel = styled.div`
	//background-color: white;
	//border-radius: 6px;
	padding: 2px 4px 4px 4px;
	
	color: ${props => props.theme.TextDark};
	font-weight: 500;
	line-height: 14px;
	font-size: 14px;
`;

const howManyPerView = 5;

const VariantsBlock = ({ variants, selectedVariant, onChange, maxWidthOverride }: IProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>("");

	const [filteredVariants, setFilteredVariants] = useState<ProductData[]>(variants);
	useEffect( () => {
		const newFilteredVariants = variants.filter(variant => variant.Name.includes(searchValue));
		setFilteredVariants(newFilteredVariants);
	},[variants, searchValue])

	const DrawThumbWindowFrame = (frameItems: ProductData[], index: number) => {
		return (
			<ThumbnailsList key={`Tl_${index}`} id={`Tl_${index}`}>
				{frameItems.map((variant) => (
					<ThumbsContainer key={`pa_${variant.IdProduct}`} id={`pa_${variant.IdProduct}`} imageUrl={variant.IconImage} className={`${selectedVariant?.IdProduct === variant.IdProduct ? "selected" : ""}`}
									 onClick={() => {onChange(variant); setIsMenuOpen(false)}} >
						<Label>{variant.Name}</Label>
					</ThumbsContainer>
				))}
			</ThumbnailsList>
	)};

	const DrawAllFrames = useMemo(() => {
		const howManyFrames = Math.ceil(filteredVariants.length / howManyPerView);

		return [...Array(howManyFrames).keys()].map((windowsIndex, index) => {
			return DrawThumbWindowFrame(RangeWindowFramed(filteredVariants, windowsIndex, howManyPerView), index);
		})

	}, [filteredVariants, selectedVariant, searchValue]);

	return (
		<TotalWrapper>
			<Wrapper className={isMenuOpen ? "isMenuOpen" : ""} style={{maxWidth: maxWidthOverride ? maxWidthOverride : "90vw" }}>
				{isMenuOpen &&
					 DrawAllFrames
				}
				{ !isMenuOpen && selectedVariant &&
					<ThumbnailsList>
						<ThumbsContainer imageUrl={selectedVariant.IconImage} className={"selected"} onClick={() => setIsMenuOpen(true)}>
							<Label>{selectedVariant.Name}</Label>
						</ThumbsContainer>
					</ThumbnailsList>
				}
				{isMenuOpen && filteredVariants.length === 0 &&
					<EmptyStateWrapper>
						<EmptyStateLabel className={"ptext"} >{t("NoItemsFound")}</EmptyStateLabel>
					</EmptyStateWrapper>
				}
			</Wrapper>
			{isMenuOpen &&
				<InputField
					size="Compact"
					width="215px"
					rightIcon={searchValue.length > 0 ? "cross" : "lens"}
					onRightIconClick={() => setSearchValue("")}
					backgroundColor={theme.Gray1}
					value={searchValue}
					onChange={value => setSearchValue(value)}
				/>
			}


			{!isMenuOpen && selectedVariant && variants.length > 1 &&
				<EmptyStateWrapper>
					<OtherLabel className={"ptext"} >{`+${variants.length - 1} ${t("Others")}`}</OtherLabel>
				</EmptyStateWrapper>
			}
		</TotalWrapper>
	);
};

interface IProps {
	variants: ProductData[],
	selectedVariant: ProductData | null,
	onChange: (newSelectedVariant: ProductData) => void,
	maxWidthOverride?: string,
}

export default VariantsBlock;