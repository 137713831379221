
import { configureStore } from "@reduxjs/toolkit";
import { ThemeSlice } from "./Theme";
import { combineReducers } from "redux";

const combinedReducer = combineReducers({
	theme: ThemeSlice.reducer,
});

const rootReducer = (state: any, action: any) => {
	if (action.type === "UserSlice/logout") {
		clearTimeout(state.refreshTokenHandle);

		//Setting undefined as state will recall the initial state
		state = undefined;
	}
	return combinedReducer(state, action);
};

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false
	}),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>
// Inferred type: {user: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
