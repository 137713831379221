export const getFirstNElementFromPath = (path: string, elementsToExtract = 1): string => {

	if (elementsToExtract < 1)
		return "";

	const pathParts = path.split("/");

	return pathParts.slice(0, elementsToExtract).join("/");
};

export const removeNEndElementFromPath = (path: string, elementsToRemove = 1): string => {

	if (elementsToRemove < 1)
		return path;

	const pathParts = path.split("/");

	if (elementsToRemove >= pathParts.length)
		return "";

	return pathParts.slice(0, -elementsToRemove).join("/");
};

export const removeNStartElementFromPath = (path: string, elementsToRemove : number = 1): string => {

	if (elementsToRemove < 1) {
		return path;
	}

	const pathParts = path.split("/").filter(value => value.length > 0);

	if (elementsToRemove >= pathParts.length)
		return "";

	return pathParts.slice(elementsToRemove).join("/");
};

export const emailValidation = (email: string) : boolean => {
	const regex = new RegExp("(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])");

	return regex.test(email);
};

export const passwordValidation = (email: string) : boolean => {
	// @$!%*?&
	const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$");

	return regex.test(email);
};

export const URLValidation = (maybeUrl: string) : boolean => {
	const regex  = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
	  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
	  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
	  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
	  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
	  '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
	return regex.test(maybeUrl);
}

export const removeOrderPrefix = (name: string) : string => {
	const splitArray = name.split("|");
	return splitArray[splitArray.length-1];
};