import React, {useCallback, useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";
import {useMutation, useQuery} from "react-query";

import useAxiosPrivate from "hooks/use_BE_API_Private";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import useAuth from "hooks/useAuth";

import {Header} from "components/header/header";
import Footer from "components/footer/footer";
import {Button} from "../../components/button/button";
import chroma from "chroma-js";
import useLogout from "../../hooks/useLogout";
import {ComboBox} from "../../components/comboBox/comboBox";
import {InputField} from "../../components/inputField/inputField";
import {Toggle} from "../../components/toggle/toggle";
import {OrderSummary} from "./orderSummary";
import {IResponse} from "../../types/api";
import {AxiosResponse} from "axios";
import {
	ICartContent,
	ICustomer,
	IGetCustomersRequest,
	IInsertImageRequest, IInsertImageResponse,
	IOrderItem,
	IPurchaseOrder, IPutPurchaseOrderResponse
} from "../../types/orders";
import useCart from "../../hooks/useCart";
import {CustomToast} from "../../components/toast/customToast";
import {DefaultEmptyCart} from "../../context/CartProvider";
import {useDebounce} from "../../utils/debounce";
import {SearchComboBox} from "../../components/comboBox/searchComboBox";
import DialogEditCustomer from "../../components/dialogEditCustomer/dialogEditCustomer";
import {useDropzone} from "react-dropzone";
import useCanShowCart from "../../hooks/useCanShowCart";
import getBase64 from "../../utils/getBase64";
import DialogRetryUploadCard from "./dialogRetryUploadCard";
import ImageCaptureDialog from "components/imageCaptureDialog/imageCaptureDialog";
import {emailValidation} from "../../utils/stringUtils";
import {CountryCodeToLocalizedName} from "../../utils/countriesUtils";


const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	
	width: 100%;
	max-width: 100%;
	min-height: 100%;
	height: 100%;
	
	overflow-y: auto;

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;

const MainHorizontalWrapper = styled.div`
	flex: 1 0 auto;
	width: 100%;
	padding: 20px;
	@media screen and (max-width: 1000px) {
		padding: 5px;
	}
	gap: 10px;
	
	display: flex;
	flex-direction: row;
	justify-content: stretch;
` ;

const LeftMainColumn = styled.div`
	flex: 1 1 auto;
	max-width: 500px;
	min-width: 350px;
	@media screen and (max-width: 1000px) {
		max-width: none;
	}
	
	gap: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

const InnerMainColumn = styled.div`
	flex: 1 0 auto;
	border-top: 1px solid #ECECEC;
	border-bottom: 1px solid #ECECEC;

	padding: 20px 10px 10px 10px;
	gap: 10px;
	
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

const CustomerColumn = styled.div`
	flex: 0 0 auto;
	
	gap: 2px;
	
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

const CustomerInnerColumn = styled.div`
	flex: 1 1 auto;
	gap: 0px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

const ColumnTitle = styled.p`
	color: ${props => props.theme.TextDefault};
	
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const ColumnTitleDark = styled.p`
	color: ${props => props.theme.TextDark};
	
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const ColumnLabel = styled.span`
	color: ${props => props.theme.TextDefault};
	
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	//white-space: pre-line;
`;


const Row = styled.div`
	width: 100%;
	
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
`;

const Line = styled.div`
	width: 100%;
	border-top: 1px solid #ECECEC;
`;

const ErrorMessage = styled(Row)`
	text-align: left;
	justify-content: left;
	color: ${props => props.theme.Negative};
	height: 14px;
`;

const BusinessCardImage = styled.div<{imageSrc: string}>`
	height: 100%;
	min-height: 200px;
	
	background-image: url(${props => props.imageSrc});
	background-repeat: no-repeat;
	background-size: contain;
	background-position-x: center;
	background-position-y: center;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const CollapsibleRightTableWrapper = styled.div`
	flex: 1 0 auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@media screen and (max-width: 1000px) {
		display: none;
	}
` ;

const CollapsableDiv = styled.div`
	display: block;
	
	@media screen and (max-width: 1000px) {
		display: none;
	}
` ;

const ColumnOrderCollapsible = styled.div`
	display: none;
	
	@media screen and (max-width: 1000px) {
		display: block;
	}
` ;

const FullScreenBackground = styled.div`
  	position: absolute;
	inset: 0;
	z-index: var(--z-level-4);
	
	width: 100%;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//padding: 160px 400px 50px 50px;
	gap: 0px;
	
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
`;

const MobileSpacer = styled.div`
	display: none;
	min-height: 30px;
	
	@media screen and (max-width: 1000px) {
		display: block;
	}
` ;

export const Cart = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();

	const { auth } = useAuth();
	const Private_BE_API = useAxiosPrivate();
	const logout = useLogout();

	const { cartContent, setCartContent } = useCart();
	const canShowCart = useCanShowCart();

	if(!canShowCart) {
		navigate("/categories");
	}

	const [searchValue, setSearchValue] = useState<string>("");
	const debouncedSearch = useDebounce(searchValue, 500);
	useEffect( () => {
		if(debouncedSearch.length > 0) {
			navigate(`search?searchV=${debouncedSearch}`);
		}
	}, [debouncedSearch])

	const [orderPriority, setOrderPriority] = useState<number>(0);
	const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | undefined>(undefined);

	const [overrideEmail, setOverrideEmail] = useState<string>("");
	const [isOverrideEmailValid, setIsOverrideEmailValid] = useState<boolean>(false);
	const [sendEmail, setSendEmail] = useState<boolean>(false);

	const [note, setNote] = useState<string>(cartContent.purchaseOrder.Notes);

	const [showCustomerDialog, setShowCustomerDialog] = useState<boolean>(false);
	const [showImageCaptureDialog, setShowImageCaptureDialog] = useState<boolean>(false);

	useEffect( () => {
		setOrderPriority(cartContent.purchaseOrder.Priority);
		setSelectedCustomer(cartContent.purchaseOrder.Customer)
		setOverrideEmail(cartContent.purchaseOrder.Email);
		setIsOverrideEmailValid(emailValidation(cartContent.purchaseOrder.Email));
		setSendEmail(cartContent.purchaseOrder.SendEmail);
		setNote(cartContent.purchaseOrder.Notes);
	}, [cartContent])

	const emptyCart = () => {
		cartContent.purchaseOrder.Items = [];
		setCartContent( {...cartContent});
	};
	const resetCart = () => {
		cartContent.purchaseOrder = Object.assign({}, DefaultEmptyCart);
		cartContent.purchaseOrder.Items = [];
		cartContent.businessCardBase64 = undefined;
		setCartContent( {...cartContent});
		setShootBase64(undefined);
		setSelectedFile(null);
		setBusinessCardUrl("");

		console.log("Cart reset", DefaultEmptyCart);
	};

	const [clientSearchValue, setClientSearchValue] = useState<string>("");
	const debouncedClientSearch = useDebounce(clientSearchValue, 500);

	const CustomersQuery = async () : Promise<ICustomer[]> => {
		try {
			const payloadRoot: IGetCustomersRequest = {
				SearchName: debouncedClientSearch,
				PageFilter: {
					Page: 0,
					Length: 999999,
				},
				/*OrderFilter: {
					By: "Fullname",
					Direction: "Asc"
				}*/
			};

			if(debouncedClientSearch.length > 2)
			{
				const {data} = await Private_BE_API.post<IResponse<ICustomer[]>>("/Customer/GetFilteredPage", payloadRoot);

				console.log("Customers:" + JSON.stringify(data.Data, null, 4));
				return data.Data;
			}

			return [];
		}
		catch (e : any) {
			console.warn("/api/User returned error:", e);
			if(e.response.status === 401)
			{
				console.error("Token expired: logout");
				logout();
			}

			return [];
		}
	};

	const {
		isLoading,
		data: customers,
		error,
		isFetching,
		refetch
	} = useQuery(
		["GetCustomers", auth!.succeeded, debouncedClientSearch],
		CustomersQuery,
		{ enabled: auth?.succeeded, keepPreviousData: true, staleTime: /*10000*/10 }
	);


	const CreateQuery = async ({payload, payloadInsertImage} :{payload: IPurchaseOrder, payloadInsertImage?: IInsertImageRequest}) => {
		console.log("Purchase order: " + JSON.stringify(payload, null, 4));

		let responsePutOrder: AxiosResponse<IResponse<IPutPurchaseOrderResponse>, any>|null = null;
		try {
			responsePutOrder = await Private_BE_API.put<IResponse<IPutPurchaseOrderResponse>>("/PurchaseOrder", payload);

			if(payloadInsertImage && responsePutOrder?.data.Data.IdPurchaseOrder) {
				payloadInsertImage.IdPurchaseOrder = responsePutOrder?.data.Data.IdPurchaseOrder;
				payloadInsertImage.Data = shootBase64?.split(',')[1];

				try {
					const getImageUrlResponse = await Private_BE_API.post<IResponse<IInsertImageResponse>>("/PurchaseOrder/InsertSingleImage", payloadInsertImage);
					CustomToast.success({
						title: t('OrderCreationQueryTitle'),
						msgText: t('OrderCreationQuerySuccessMessage')
					});
					resetCart();
					navigate("/");
				}
				catch (error) {
					console.warn("Failed to upload image");
					setRetryImageRequest(payloadInsertImage);
				}
			}
			else {
				CustomToast.success({title:t('OrderCreationQueryTitle'), msgText:t('OrderCreationQuerySuccessMessage')});
				resetCart();
				navigate("/");
			}

		} catch (error) {
			CustomToast.error({title:t('OrderCreationQueryTitle'), msgText:t('OrderCreationQueryFailedMessage')});

			if(cartContent.purchaseOrder.Customer!.BusinessName.endsWith(" (new)")) {
				cartContent.purchaseOrder.Customer!.BusinessName = cartContent.purchaseOrder.Customer!.BusinessName.replace(" (new)", "");
			}
		}
		return responsePutOrder;
	};

	const createMutation = useMutation(CreateQuery);

	const doQueryPurchaseOrder = () => {

		const customerCopy = Object.assign( {}, cartContent.purchaseOrder.Customer! );
		cartContent.purchaseOrder.Customer = customerCopy;

		if (cartContent.purchaseOrder.Customer!.IdCustomer === undefined) {
			if(!cartContent.purchaseOrder.Customer!.BusinessName.endsWith(" (new)")) {
				cartContent.purchaseOrder.Customer!.BusinessName = cartContent.purchaseOrder.Customer!.BusinessName + " (new)"
			}
		}
		//cartContent.purchaseOrder.Customer!.IdCustomer = undefined;

		const insertImagePayload : IInsertImageRequest | undefined =
			shootBase64 ?
				{
					"OriginalName": selectedFile?.name || (new Date).toISOString(),
				} : undefined;

		console.log(cartContent.purchaseOrder, insertImagePayload);
		createMutation.mutate({payload: cartContent.purchaseOrder, payloadInsertImage: insertImagePayload});
	};


	//shot a picture in browser or selected a file
	const [shootBase64, setShootBase64] = useState<string|undefined>(cartContent.businessCardBase64);
	const [selectedFile, setSelectedFile] = useState<File|null>(null);
	const onDrop = useCallback( (acceptedFiles: File[]) => {
		if(acceptedFiles.length > 0) {
			setSelectedFile(acceptedFiles[0])
			console.log("Selected new file: " + selectedFile?.name);
		}
	}, [])

	const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({
		maxFiles: 1,
		multiple: false,
		onDrop: onDrop,
		accept:
			{'image/jpeg': [".jpeg", ".jpg"]},
		disabled: createMutation.isLoading
	});

	//show only
	const [businessCardUrl, setBusinessCardUrl] = useState<string>("");
	useEffect( () => {
		if(selectedFile) {
			getBase64(selectedFile).then(base64STR => {
				setShootBase64(base64STR)
			});
		}
	},[selectedFile])
	useEffect( () => {
		if(shootBase64) {
			//console.log("kk", shootBase64)
			setSelectedFile(null);
		}

		setBusinessCardUrl(shootBase64 || "");

		cartContent.businessCardBase64 = shootBase64 ?? undefined;
		setCartContent( {...cartContent});
	},[shootBase64])


	const [retryImageRequest, setRetryImageRequest] = useState<IInsertImageRequest | null>(null);




	return (
		<Wrapper>
			<Header onSearchValueChange={setSearchValue} /*crumbs={crumbs}*/ searchValue={searchValue} showSearch />
				<MainHorizontalWrapper>
					<LeftMainColumn>
						<Button backgroundColor={"Gray3"} color={"Gray0"} leftIcon={"back"} label={t("BackToCatalog")} caps={false} size={"Compact"}
								onClick={() => {/*history.back()*/ navigate("/categories")}}
						></Button>
						<InnerMainColumn>
							<ColumnTitle>{t("OrderDetails")}</ColumnTitle>
							<Row>
								<ColumnLabel style={{width: "100%"}}>{t("OrderPriority")}</ColumnLabel>
								<ComboBox
									keyValue={orderPriority.toString()}
									onChange={(value: string) => {
										setOrderPriority(Number(value));
										cartContent.purchaseOrder.Priority = Number(value);
										setCartContent({...cartContent});
									}}
									keyOptions={["0", "1", "3", "5", "7"]}
									displayOptions={["-", "1", "3", "5", "7"]}
									style={{width: "180px"}}
								/>
							</Row>
							<Line/>
							<ColumnTitle>{t("Customer")}</ColumnTitle>
							{
								selectedCustomer === undefined ?
									<>
										<Row>
											<ColumnLabel style={{width: "20%"}}>{t("SearchCustomer")}</ColumnLabel>
											<SearchComboBox
												keyValue={""}
												onSelectionChange={(value: string) => {
													const customer: ICustomer = customers!.find(customer => customer.IdCustomer === value)!
													setSelectedCustomer(customer);
													cartContent.purchaseOrder.Customer = customer;
													setCartContent({...cartContent});

												}}
												onFilterValueChange={(value: string) => {
													setClientSearchValue(value);
												}}
												minSearchCharacter={3}
												keyOptions={customers?.map(customer => customer.IdCustomer!) || []}
												displayOptions={customers?.map(customer => customer.BusinessName) || []}
												style={{width: "100%"}}
												enabled={!!customers /*&& customers?.length > 0*/}
											/>
										</Row>
										<Button backgroundColor={"PositiveSelected"} color={"Gray0"} label={t("NewCustomer")} caps={false} size={"Compact"}
										onClick={() => {setShowCustomerDialog(true)}}
										></Button>
									</>
								:
									<CustomerColumn>
										<ColumnTitleDark style={{paddingTop: "10px"}}>{selectedCustomer.BusinessName ?? ""}</ColumnTitleDark>
										<Row>
											<CustomerInnerColumn>
												<ColumnLabel style={{width: "100%"}}>{
													`${selectedCustomer.Address ?? ""} ${selectedCustomer.StreetNumber ?? ""}, ${selectedCustomer.City ?? ""} ${selectedCustomer.PostalCode ?? ""} ${selectedCustomer.City ?? ""}`
												}</ColumnLabel>
												<ColumnLabel style={{width: "100%", fontWeight: 500, position: "relative", top: "-1px"}}>{
													`${CountryCodeToLocalizedName(selectedCustomer.Country, i18n.language)}`
												}</ColumnLabel>
											</CustomerInnerColumn>
											<img style={{cursor: "pointer"}} src={"/assets/edit.svg"} width="18px" onClick={() => {
												setShowCustomerDialog(true);
											}}/>
											<img style={{cursor: "pointer"}} src={"/assets/bin.svg"} width="18px" onClick={() => {
												setSelectedCustomer(undefined);
												cartContent.purchaseOrder.Customer = undefined;
												setCartContent({...cartContent});
											}}/>
											</Row>
									</CustomerColumn>

							}
							<Line/>
							<InputField
								size="Compact"
								width="100%"
								backgroundColor={theme.Gray1}
								value={overrideEmail}
								onChange={value => {
									setOverrideEmail(value);
									setOverrideEmail(value);
									setIsOverrideEmailValid(emailValidation(value));
									if(value.length > 0) {
										setSendEmail(true);
										cartContent.purchaseOrder.SendEmail = true;
										setCartContent({...cartContent});
									}

									cartContent.purchaseOrder.Email = value;
									setCartContent({...cartContent});
								}}
								placeholder={t("OverrideEmailPlaceholder")}
								borderRadius={"4px"}
							/>
							{ !isOverrideEmailValid && sendEmail &&
								<ErrorMessage className="text-s">{t("MailError")}</ErrorMessage>
							}
							<Row>
								<ColumnLabel style={{width: "100%", textAlign: "right"}}>{t("SendCopyEmailToCustomer")}</ColumnLabel>
								<Toggle checked={sendEmail} onChange={(value) => {
									setSendEmail(value);
									cartContent.purchaseOrder.SendEmail = value;
									setCartContent({...cartContent});
								}}/>
							</Row>
							<Line/>
							<ColumnLabel style={{width: "100%"}}>{t("Note")}</ColumnLabel>
							<InputField
								size="Default"
								width="100%"
								multiline={true}
								backgroundColor={theme.Gray1}
								value={note}
								onChange={ value => {
										setNote(value)
										cartContent.purchaseOrder.Notes = value;
										setCartContent({...cartContent});
									}}
								placeholder={t("NotePlaceholder")}
								borderRadius={"4px"}
								style={{height: "100px", alignItems: "flex-start"}}
							/>
							<Line/>

							{ shootBase64 === undefined ?
								<>
									<CollapsableDiv {...getRootProps({className: 'dropzone'})} /*isDragging={isDragActive}*/ style={{width: "100%"}} >
										<Button backgroundColor={"Gray3"} color={"Gray0"}
												rightIconSrcOverride={"/assets/camera.svg"} iconWidth={"20px"}
												label={t("AddBusinessCard")} caps={false} size={"Compact"}
												overrideWidth={"100%"}
												onClick={() => {
												}}
										></Button>
									</CollapsableDiv>
									<Button backgroundColor={"Gray3"} color={"Gray0"} rightIconSrcOverride={"/assets/camera.svg"} iconWidth={"20px"}
									label={t("CaptureBusinessCard")} caps={false} size={"Compact"}
									onClick={() => {setShowImageCaptureDialog(true)}}
									></Button>
								</>
								: <>
									<BusinessCardImage imageSrc={businessCardUrl} ></BusinessCardImage>
									<Button backgroundColor={"NegativeSelected"} color={"Gray0"} label={t("RemoveBusinessCard")} caps={false} size={"Compact"}
											onClick={()=>{setShootBase64(undefined)}}
											disabled={createMutation.isLoading}
									></Button>
								</>
							}

							<ColumnOrderCollapsible>
								<OrderSummary></OrderSummary>
							</ColumnOrderCollapsible>
						</InnerMainColumn>

						<Row>
							<Button backgroundColor={"NegativeSelected"} color={"Gray0"} label={t("EmptyCart")} caps={false} size={"Compact"} style={{flex: "1 1 auto"}}
									onClick={emptyCart}
							></Button>
							<Button backgroundColor={"NegativeSelected"} color={"Gray0"} label={t("CancelOrder")} caps={false} size={"Compact"} style={{flex: "1 1 auto"}}
									onClick={resetCart}
							></Button>
						</Row>
						<Button backgroundColor={"PositiveSelected"} color={"Gray0"} label={t("SendOrder")} caps={false} size={"Compact"}
								onClick={doQueryPurchaseOrder}
								disabled={cartContent.purchaseOrder.Items.length === 0 || selectedCustomer === undefined || (!isOverrideEmailValid && sendEmail) || orderPriority === 0}
								isLoading={createMutation.isLoading}
						></Button>
						<MobileSpacer></MobileSpacer>

					</LeftMainColumn>
					<CollapsibleRightTableWrapper>
						<OrderSummary border={true}></OrderSummary>
					</CollapsibleRightTableWrapper>
				</MainHorizontalWrapper>

				{showCustomerDialog &&
					<DialogEditCustomer customer={selectedCustomer} onCancelEdit={() => setShowCustomerDialog(false)} onNewValue={ (value) => {
						setSelectedCustomer(value);
						cartContent.purchaseOrder.Customer = value;
						setCartContent({...cartContent});
						setShowCustomerDialog(false);
					}}/>
				}
				{retryImageRequest &&
					<DialogRetryUploadCard imageRequest={retryImageRequest} onClose={ (result) => {
						setRetryImageRequest(null);
						resetCart();
						if(result) {
							CustomToast.success({
								title: t('OrderCreationQueryTitle'),
								msgText: t('OrderCreationQuerySuccessMessage')
							});
						}
						else  {
							CustomToast.info({
								title: t('OrderCreationQueryTitle'),
								msgText: t('OrderCreationQueryWarningMessage')
							});
						}
					}}/>
				}
				{showImageCaptureDialog &&
					<ImageCaptureDialog
						onClose={() => {setShowImageCaptureDialog(false)}}
						onImageCaptured={base64str => {
							//console.log(base64str)
							setShootBase64(base64str);
							setShowImageCaptureDialog(false);
						}}
						maxResolutions={{width: 4096, height: 4096}}
					/>
				}
				{
					createMutation.isLoading &&
						<FullScreenBackground />
				}
			<Footer marginTop={"0px"} />
		</Wrapper >
	);
};