/* eslint-disable */

import React, {Suspense, useEffect, useRef, useState} from "react";
import {Canvas, RootState, useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {Box3, PerspectiveCamera, Sphere, TextureLoader, Vector2, Vector3} from "three";
import styled from "styled-components";
import {Environment, EnvironmentProps, Loader, Stage, useGLTF} from "@react-three/drei";

import {object3DIsMesh} from "utils/threeUtils";

import CameraControls from "../../components/3dComponents/cameraControls";
import {IProductSimulation, TileMode} from "../../types";
import {MeshStandardMaterial} from "three/src/Three";
import {ErrorBoundary} from "react-error-boundary";
import {NoToneMapping} from "three/src/constants";

const Wrapper = styled.div<any>`
	position: relative;
	//height: 50px;
	flex: 1 1 auto;
	
	@property --color1 {
		syntax: "<color>";
		inherits: false;
		initial-value: #c4c4c4;
	}

	@property --color2 {
		syntax: "<color>";
		inherits: false;
		initial-value: #828692;
	}

	--color1: #FFFFFF;
	--color2: #A7A7A7;
	
	background: radial-gradient(var(--color1), var(--color2));
`;

interface IModelProps {
	modelUrl: string,
	onModelLoaded: (boundingBox: Box3) => void,
	simulation: IProductSimulation,
}

function Model ({ modelUrl, onModelLoaded, simulation}: IModelProps) {

	const { scene } = useGLTF(modelUrl);
	const albedoMap = simulation.AlbedoMap ? useLoader(TextureLoader, simulation.AlbedoMap) : null;
	if(!simulation.AlbedoMap) {
		console.error("MISSING ALBEDO MAP");
	}

	const normalMap = simulation.NormalMap ? useLoader(TextureLoader, simulation.NormalMap) : null;
	const metallicMap = simulation.MetallicMap ? useLoader(TextureLoader, simulation.MetallicMap) : null;
	const roughnessMap = simulation.SmoothnessMap ? useLoader(TextureLoader, simulation.SmoothnessMap) : null;
	const opacityMap = simulation.OpacityMap ? useLoader(TextureLoader, simulation.OpacityMap) : null;
	//const occlusionMap = simulation.OcclusionMap ? useLoader(TextureLoader, simulation.OcclusionMap) : null;
	const reverseMap = simulation.ReverseMap ? useLoader(TextureLoader, simulation.ReverseMap) : null;

	//const hdrMap = useLoader(RGBELoader, "/assets/SL.hdr");
	//hdrMap.flipY = false;

	useEffect(() => {

		const modelSize = new Box3();

		scene.traverse(obj => {

			if (obj.name.startsWith("support")) {
				obj.visible = false;
			}
			else if (object3DIsMesh(obj) && obj.name.toLowerCase().includes("variable")) {
				modelSize.expandByObject(obj);

				let material = obj.material as MeshStandardMaterial;

				//const repeat = new Vector2(1, 1);
				const repeat = new Vector2(1/simulation.TileWidth, 1/simulation.TileHeight).multiplyScalar(200);
				console.log("Repeat for current material: ", repeat.x, repeat.y);

				//if(hdrMap) {
				//	material.envMap = null;
				material.envMapIntensity = 0.2;
				//}

				material.toneMapped = true;

				if(albedoMap) {
					albedoMap.wrapS = THREE.RepeatWrapping;
					albedoMap.wrapT = THREE.RepeatWrapping;
					albedoMap.repeat = repeat;

					if(simulation.TileMode === TileMode.AlbedoOnly) {
						const altRepeat = new Vector2(1/simulation.AltTileWidth, 1/simulation.AltTileHeight).multiplyScalar(200);//.multiply(repeat);
						const shaderRepeat = new Vector2(altRepeat.x/repeat.x, altRepeat.y/repeat.y);

						material = material.clone();
						obj.material = material;

						material.userData.shaderRepeat = { value: 1 }; //this will be our input, the system will just reference it
						material.onBeforeCompile = shader => {
							shader.uniforms.shaderRepeat = material.userData.shaderRepeat; //pass this input by reference

							//prepend the input to the shader
							shader.fragmentShader = "uniform vec2 shaderRepeat;\n" + shader.fragmentShader;
							//the rest is the same
							shader.fragmentShader =
								shader.fragmentShader.replace(
									"#include <map_fragment>",
									`#ifdef USE_MAP
											diffuseColor *= texture2D( map, vUv * shaderRepeat );
											//vec2 scale = vec2(${altRepeat.x/repeat.x}, ${altRepeat.y/repeat.y});
											//diffuseColor *= texture2D( map, vUv * scale );
											//diffuseColor *= texture2D( map, vMapUv * 20. );
										#endif`
								);
						};

						material.userData.shaderRepeat.value = shaderRepeat;
						material.needsUpdate = true;
						console.log("Albedo only size: ", simulation.AltTileWidth, simulation.AltTileHeight);
						console.log("Albedo only repeat for current material: ", altRepeat.x, altRepeat.y);
					}
					/*else {
						albedoMap.repeat = repeat;
					}*/

					material.map = albedoMap;
				}
				if(normalMap) {
					normalMap.repeat = repeat;
					normalMap.wrapS = THREE.RepeatWrapping;
					normalMap.wrapT = THREE.RepeatWrapping;
					material.normalMap = normalMap;
				}
				if(metallicMap) {
					metallicMap.repeat = repeat;
					metallicMap.wrapS = THREE.RepeatWrapping;
					metallicMap.wrapT = THREE.RepeatWrapping;
					material.metalnessMap = metallicMap;
				}
				if(roughnessMap) {
					roughnessMap.repeat = repeat;
					roughnessMap.wrapS = THREE.RepeatWrapping;
					roughnessMap.wrapT = THREE.RepeatWrapping;
					material.roughnessMap = roughnessMap;
				}
				if(opacityMap) {
					opacityMap.repeat = repeat;
					opacityMap.wrapS = THREE.RepeatWrapping;
					opacityMap.wrapT = THREE.RepeatWrapping;
					material.alphaMap = opacityMap;
					material.transparent = true;
				}
				/*if(occlusionMap) {
					material.aoMap = occlusionMap;
				}*/
			}
			else if (object3DIsMesh(obj) && obj.name.toLowerCase().includes("reverse")) {
				let material = obj.material as MeshStandardMaterial;
				const repeat = new Vector2(1 / simulation.TileWidth, 1 / simulation.TileHeight).multiplyScalar(200);

				material.envMapIntensity = 0.2;
				material.toneMapped = true;

				console.log("Found reverse mesh");

				if (reverseMap) {
					//reverseMap.repeat = repeat;
					reverseMap.wrapS = THREE.RepeatWrapping;
					reverseMap.wrapT = THREE.RepeatWrapping;
					reverseMap.repeat = repeat;

					material.map = reverseMap;
					console.log("Applied reverse albedo texture");
				}
				else if (albedoMap) {

					albedoMap.wrapS = THREE.RepeatWrapping;
					albedoMap.wrapT = THREE.RepeatWrapping;
					albedoMap.repeat = repeat;

					material.map = albedoMap;
					console.log("Applied standard albedo as reverse map");

					if(simulation.TileMode === TileMode.AlbedoOnly) {
						const altRepeat = new Vector2(1/simulation.AltTileWidth, 1/simulation.AltTileHeight).multiplyScalar(200);//.multiply(repeat);

						const shaderRepeat = new Vector2(altRepeat.x/repeat.x, altRepeat.y/repeat.y);

						material = material.clone();
						obj.material = material;

						material.userData.shaderRepeat = { value: 1 }; //this will be our input, the system will just reference it
						material.onBeforeCompile = shader => {
							shader.uniforms.shaderRepeat = material.userData.shaderRepeat; //pass this input by reference

							//prepend the input to the shader
							shader.fragmentShader = "uniform vec2 shaderRepeat;\n" + shader.fragmentShader;
							//the rest is the same
							shader.fragmentShader =
								shader.fragmentShader.replace(
									"#include <map_fragment>",
									`#ifdef USE_MAP
											diffuseColor *= texture2D( map, vUv * shaderRepeat );
											//vec2 scale = vec2(${altRepeat.x/repeat.x}, ${altRepeat.y/repeat.y});
											//diffuseColor *= texture2D( map, vUv * scale );
											//diffuseColor *= texture2D( map, vMapUv * 20. );
										#endif`
								);
						};

						material.userData.shaderRepeat.value = shaderRepeat;
						material.needsUpdate = true;
						console.log("Reverse Albedo only size: ", simulation.AltTileWidth, simulation.AltTileHeight);
						console.log("Reverse Albedo only repeat for current material: ", altRepeat.x, altRepeat.y);
					}

					if(normalMap) {
						normalMap.repeat = repeat;
						normalMap.wrapS = THREE.RepeatWrapping;
						normalMap.wrapT = THREE.RepeatWrapping;
						material.normalMap = normalMap;
					}
				}

			}
		});

		onModelLoaded(modelSize);
	}, [scene, albedoMap]);

	return (
		<Stage intensity={0.45} shadows="contact" environment={null as unknown as EnvironmentProps} adjustCamera={false}>
			{/*<Environment files="/assets/SL.hdr"/>*/}
			{/*<Environment files="/testAssets/brown_photostudio_07_1k.hdr"/>*/}
			{<Environment files="/assets/SL_contrast.hdr"/>}
			<primitive key={scene.id} object={scene}>
			</primitive>
		</Stage>
	);
}

/*function Loader() {
	const { active, progress, errors, item, loaded, total } = useProgress();
	return <Html center><p className={"text-l"}>{progress} % loaded</p></Html>;
}*/

function ProductView ({ modelUrl, simulation }: IProps) {
	const sceneGroupRef = useRef<THREE.Group>(new THREE.Group());

	const [modelSize, setModelSize] = useState<Box3 | undefined>();
	const [cameras, setCameras] = useState<PerspectiveCamera[]>([]);
	const [currentCamera, setCurrentCamera] = useState("camera_default");

	function getSelectedCamera (name: string, position: Vector3, target: Vector3) {
		const newCamera = new THREE.PerspectiveCamera(50, 1, 0.01, 50);
		newCamera.name = name;
		newCamera.position.copy(position);
		newCamera.up.set(0, 1, 0);
		newCamera.lookAt(target);
		newCamera.userData = {
			gltfExtensions: {
				EXT_APPEAL: {
					target: target.toArray()
				}
			}
		};
		newCamera.updateProjectionMatrix();

		return newCamera;
	}

	useEffect(() => {
		if (modelSize) {
			const bSphere = new Sphere();

			modelSize.getBoundingSphere(bSphere);
			const multiplier = bSphere.radius * 0.5;

			const camera_default = getSelectedCamera(
				"camera_default",
				new Vector3(1, 3, 4).multiplyScalar(multiplier),
				bSphere.center
			);
			const camera_top = getSelectedCamera(
				"camera_top",
				new Vector3(0, 5, 0.001).multiplyScalar(multiplier),
				bSphere.center
			);
			const camera_front = getSelectedCamera(
				"camera_front",
				new Vector3(0, 0, 5).multiplyScalar(multiplier),
				bSphere.center
			);
			const camera_left = getSelectedCamera(
				"camera_left",
				new Vector3(5, 0, 0).multiplyScalar(multiplier),
				bSphere.center
			);

			setCameras([camera_default, camera_top, camera_front, camera_left]);
		}
	}, [modelSize]);

	function onCanvasCreated ({ gl, scene, camera }: RootState) {
		gl.toneMapping = THREE.LinearToneMapping;
		gl.toneMappingExposure = 0.5;
		gl.compile(scene, camera);
	}

	function ErrorFallback ({ error }: any) {
		console.error(error);
		return <CameraControls
			cursorId={"___"}
			intersectGroup={sceneGroupRef.current!}
			controlId={"ProdView_"}
			enableZoom={true}
			nearPlane={0.001}
			farPlane={5}
			minDistance={0.15}
			maxDistance={3.5}
			zoomValue={1}
			cameras={cameras}
			selectedCameraName={currentCamera}
		/>;
	}

	return (
		<Wrapper>
			<Canvas onCreated={onCanvasCreated}>
				<ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[modelUrl]}>
					<Suspense fallback={/*<Spinner3D size={[0.2, 0.2, 0.2]} position={[0, -0.3, 0]} />*//*<Loader/>*/null}>
						<group ref={sceneGroupRef}>
							<Model modelUrl={modelUrl} onModelLoaded={setModelSize} simulation={simulation} />
						</group>
					</Suspense>
					<CameraControls
						cursorId={"___"}
						intersectGroup={sceneGroupRef.current!}
						controlId={"ProdView_"}
						enableZoom={true}
						nearPlane={0.001}
						farPlane={5}
						minDistance={0.15}
						maxDistance={3.5}
						zoomValue={1}
						cameras={cameras}
						selectedCameraName={currentCamera}
					/>
				</ErrorBoundary>
			</Canvas>
			<Loader containerStyles={{ backgroundColor: "rgba(0, 0, 0, 0)" }} dataStyles={{color: "#000000"}}/>
			{/*<ProductCameraControls
				cameras={cameras}
				currentCameraName={currentCamera}
				onChangeCamera={setCurrentCamera}
				isFullScreen={isFullScreen}
			/>*/}
		</Wrapper>
	);
}

interface IProps {
	modelUrl: string,
	simulation: IProductSimulation,
}

export default ProductView;